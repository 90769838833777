import { useState, useCallback } from 'react';
import { UsersListFiltersContextType } from '../contexts/UsersListFiltersContext';

export const useUsersListFilters = (): UsersListFiltersContextType => {
  const [filters, setFilters] = useState({ page: 1 });

  const setCurrentFilters = useCallback((currentFilters: any): void => {
    setFilters(currentFilters);
  }, []);

  const goToPage = useCallback(
    (page: number): void => {
      setFilters({ ...filters, page });
    },
    [filters],
  );

  return {
    filters,
    setCurrentFilters,
    goToPage,
  };
};
