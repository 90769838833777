import { withStyles, createStyles, Theme, Tabs, Tab } from '@material-ui/core';

export interface StyledTabProps {
  label: string;
}

export const AntTabs = withStyles(
  {
    root: {
      borderBottom: '1px solid #EBEBEB',
    },
  },
  { name: 'AntTabs' },
)(Tabs);

export const AntTab = withStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        color: '#575757',
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&$selected': {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      selected: {},
    }),
  { name: 'AntTab' },
)(Tab);
