import React from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { ListLightIcon } from '../icons/ListLightIcon';
import { RightCircleIcon } from '../icons/RightCircleIcon';
import { IncompleteCircleIcon } from '../icons/IncompleteCircleIcon';
import { ErrorCircleIcon } from '../icons/ErrorCircleIcon';
import { HorizontalGrid as Grid } from '../HorizontalGrid';

const validationColumns = ['Estado', 'Kpi', 'Valor', 'Observación'];

export type KpiValidationErrorType = 'success' | 'warning' | 'error';

export interface KpiValidationError {
  type: KpiValidationErrorType;
  kpi_name: string;
  value: string;
  message: string;
  code: string;
}

export interface LoadKpiErrorsProps {
  validations: KpiValidationError[];
  onSuccess: () => void;
  onCancel: () => void;
}

export interface SummaryBuild {
  icon: JSX.Element;
  type: KpiValidationErrorType;
  message: string;
}

const useStyles = makeStyles({
  previewTableContainer: {
    maxHeight: 340,
    overflowY: 'auto',
    overflowX: 'auto',
    '& .MuiTableCell-root': {
      fontSize: 14,
    },
  },
  columnName: {
    width: 250,
  },
  columnId: {
    width: 40,
  },
  rowName: {
    maxWidth: 250,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rowId: {
    width: 40,
  },
  label: {
    fontSize: 16,
    lineHeight: '25px',
  },
  button: {
    flex: 1,
  },
});

const Icons = {
  Error: <ErrorCircleIcon color="error" />,
  Warning: <IncompleteCircleIcon style={{ fill: '#ffd908' }} />,
  Success: <RightCircleIcon style={{ fill: '#0ba005' }} />,
};

export default function LoadKpiErrors({
  validations,
  onSuccess,
  onCancel,
}: LoadKpiErrorsProps) {
  const classes = useStyles();

  const summaries: SummaryBuild[] = [
    { icon: Icons.Error, type: 'error', message: `KPI's con error` },
    { icon: Icons.Warning, type: 'warning', message: `KPI's con advertencia` },
    { icon: Icons.Success, type: 'success', message: `KPI's correctos` },
  ];

  const renderSummary = (summary: SummaryBuild) => {
    return (
      <Grid
        item
        container
        direction="row"
        xs={12}
        md={4}
        alignItems="flex-start"
        justify="center"
        key={summary.type}
      >
        <Grid item>{summary.icon}</Grid>
        &nbsp;
        <Grid item xs>
          ({validations.filter((d) => d.type === summary.type).length}){' '}
          {summary.message}
        </Grid>
      </Grid>
    );
  };

  const someError = validations.some((s) => s.type === 'error');

  return (
    <>
      <DialogTitle disableTypography>
        <ListLightIcon color="primary" />
        Revisa tu carga de KPI's
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="h2">Tu archivo contiene</DialogContentText>
        <Grid container spacing={1} alignItems="center">
          {summaries.map(renderSummary)}
        </Grid>
      </DialogContent>
      <DialogContent>
        <DialogContentText variant="h2">Detalle del archivo</DialogContentText>
        <TableContainer className={classes.previewTableContainer}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {validationColumns.map((column) => (
                  <TableCell key={`load-kpi-validation-column-${column}`}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {validations.map((row, i) => {
                return (
                  <TableRow key={`load-kpi-validation-row-${i}`}>
                    <TableCell key={`load-kpi-validation-cell-${i}-type`}>
                      {row.type === 'error' && Icons.Error}
                      {row.type === 'warning' && Icons.Warning}
                      {row.type === 'success' && Icons.Success}
                    </TableCell>
                    <TableCell key={`load-kpi-validation-cell-${i}-kpi_name`}>
                      {row.kpi_name}
                    </TableCell>
                    <TableCell key={`load-kpi-validation-cell-${i}-value`}>
                      {row.value}
                    </TableCell>
                    <TableCell key={`load-kpi-validation-cell-${i}-message`}>
                      {row.message}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {someError ? (
        <DialogContent>
          <DialogContentText>
            Debes corregir los errores y cargar un nuevo archivo.
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions className="big-top-space">
        <Button
          id="btn-load-kpi-preview-cancel"
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={onCancel}
        >
          cargar nuevo archivo
        </Button>
        <Button
          id="btn-load-kpi-preview-confirm"
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={onSuccess}
          disabled={someError}
        >
          confirmar
        </Button>
      </DialogActions>
    </>
  );
}
