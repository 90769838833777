import { KpisStatus } from '../../../types/entities/kpis-status';

export const LIST_KPIS_STATUS = 'LIST_KPIS_STATUS';
export const LIST_KPIS_STATUS_SUCCEEDED = 'LIST_KPIS_STATUS_SUCCEEDED';
export const LIST_KPIS_STATUS_FAILED = 'LIST_KPIS_STATUS_FAILED';

export interface ListKpisStatusAction {
  type: typeof LIST_KPIS_STATUS;
  payload?: {
    id_country?: number;
    id_brand?: number;
    trimester: number;
    year: number;
  };
}

export interface ListKpisStatusSuccededAction {
  type: typeof LIST_KPIS_STATUS_SUCCEEDED;
  payload: KpisStatus[];
}

export interface ListKpisStatusFailedAction {
  type: typeof LIST_KPIS_STATUS_FAILED;
  payload: { status: number; message: string };
}

export type ListKpisStatusResponseAction =
  | ListKpisStatusSuccededAction
  | ListKpisStatusFailedAction;

export const listKpisStatusAction = (
  payload?: ListKpisStatusAction['payload'],
): ListKpisStatusAction => ({
  type: LIST_KPIS_STATUS,
  payload,
});

export const notifyListKpisStatusSucceeded = (
  kpisLoaded: KpisStatus[],
): ListKpisStatusSuccededAction => ({
  type: LIST_KPIS_STATUS_SUCCEEDED,
  payload: kpisLoaded,
});

export const notifyListKpisStatusFailed = (
  status: number,
  message: string,
): ListKpisStatusFailedAction => ({
  type: LIST_KPIS_STATUS_FAILED,
  payload: {
    status,
    message,
  },
});
