import { useCallback } from 'react';

export interface HasTargetAndValue {
  target: { value: unknown };
}

export function useOnChangeEventToSet<
  T = string,
  E extends HasTargetAndValue = HasTargetAndValue
>(setter: (value: React.SetStateAction<T>) => void) {
  return useCallback((event: E) => setter(event.target.value as T), [setter]);
}
