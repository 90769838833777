import React from 'react';
import {
  makeStyles,
  Theme,
  FormControl,
  FormLabel,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import BootstrapSelect from '../../../components/BootstrapSelect';
import { Formik } from 'formik';
import { Brand } from '../../../types/entities/brand';
import { listBrandsAction } from '../../../store/actions/brands';
import { useLoader } from '../../../hooks/loaders/useLoader';
import { listCountriesAction } from '../../../store/actions/countries';
import { Country } from '../../../types/entities/country';
import { TRIMESTERS } from '../../../services/constants';
import clsx from 'clsx';

export interface FormValues {
  id_country: string;
  id_brand: string;
  trimester: string;
  year: string;
}

export interface KpisStatusFilterValues {
  id_country?: number;
  id_brand?: number;
  trimester: number;
  year: number;
}

export interface KpisStatusFilterProps {
  onChange: (values: KpisStatusFilterValues) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    '& label + .MuiInput-formControl': {
      marginTop: 8,
      [theme.breakpoints.up('xl')]: {
        marginTop: 12,
      },
    },
  },
  label: {
    color: '#1D1D1B',
    marginLeft: 8,
    fontSize: 12,
    [theme.breakpoints.up('xl')]: {
      fontSize: 14,
    },
  },
  button: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    lineHeight: '16px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 11,
      paddingBottom: 11,
      fontSize: 18,
      lineHeight: '21px',
    },
    textTransform: 'none',
    width: '100%',
    borderWidth: 2,
  },
  buttonSubmit: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  buttonClean: {
    borderColor: '#303D72',
    color: '#303D72',
    '&:hover': {
      borderWidth: 2,
    },
  },
}));

function getBrandsFromStore(store: any) {
  return {
    options: store.brands.data as Brand[],
    loading: store.brands.loading as boolean,
  };
}

function getCountriesFromStore(store: any) {
  return {
    options: store.countries.data as Country[],
    loading: store.countries.loading as boolean,
  };
}

export const KpisStatusFilter = ({ onChange }: KpisStatusFilterProps) => {
  const classes = useStyles();
  const now = new Date();
  const currentYear = now.getFullYear();
  const initialYear = 2019;
  const years = [...new Array(currentYear - initialYear + 1)].map(
    (v, i) => `${initialYear + i}`,
  );

  const { options: countries, loading: loadingCountries } = useLoader(
    getCountriesFromStore,
    listCountriesAction,
  );

  const { options: brands, loading: loadingBrands } = useLoader(
    getBrandsFromStore,
    listBrandsAction,
  );

  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <>
      <Formik<FormValues>
        initialValues={{
          id_country: '',
          id_brand: '',
          trimester: Math.floor(now.getMonth() / 3).toString(),
          year: now.getFullYear().toString(),
        }}
        onSubmit={({ id_country, id_brand, trimester, year }) => {
          onChange({
            id_country: id_country ? parseInt(id_country, 10) : undefined,
            id_brand: id_brand ? parseInt(id_brand, 10) : undefined,
            trimester: parseInt(trimester, 10),
            year: parseInt(year, 10),
          });
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
            style={{ overflow: 'hidden' }}
          >
            <Grid
              container
              spacing={isXl ? 2 : 1}
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={12} lg={5}>
                <Grid
                  container
                  spacing={isXl ? 2 : 1}
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl className={classes.formControl}>
                      <FormLabel
                        component="label"
                        htmlFor="id_country"
                        className={classes.label}
                      >
                        País
                      </FormLabel>
                      <BootstrapSelect
                        native
                        id="id_country"
                        {...props.getFieldProps('id_country')}
                        disabled={loadingBrands}
                      >
                        {!loadingCountries ? (
                          <>
                            <option value="" aria-label="None">
                              Todos
                            </option>
                            {countries.map((b) => (
                              <option key={b.id_country} value={b.id_country}>
                                {b.name_country}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="" aria-label="None" disabled>
                            Cargando
                          </option>
                        )}
                      </BootstrapSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl className={classes.formControl}>
                      <FormLabel htmlFor="id_brand" className={classes.label}>
                        Marca
                      </FormLabel>
                      <BootstrapSelect
                        native
                        id="id_brand"
                        {...props.getFieldProps('id_brand')}
                        disabled={loadingBrands}
                      >
                        {!loadingBrands ? (
                          <>
                            <option value="" aria-label="None">
                              Todas
                            </option>
                            {brands.map((b) => (
                              <option key={b.id_brand} value={b.id_brand}>
                                {b.name_brand}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="" aria-label="None" disabled>
                            Cargando
                          </option>
                        )}
                      </BootstrapSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Grid
                  container
                  spacing={isXl ? 2 : 1}
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl className={classes.formControl} required>
                      <FormLabel htmlFor="trimester" className={classes.label}>
                        Trimestre
                      </FormLabel>
                      <BootstrapSelect
                        native
                        id="trimester"
                        {...props.getFieldProps('trimester')}
                      >
                        {TRIMESTERS.map((t) => (
                          <option
                            value={t.value}
                            key={t.value}
                            aria-label={t.value}
                          >
                            {t.label}
                          </option>
                        ))}
                      </BootstrapSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl className={classes.formControl} required>
                      <FormLabel htmlFor="year" className={classes.label}>
                        Año
                      </FormLabel>
                      <BootstrapSelect
                        native
                        id="year"
                        {...props.getFieldProps('year')}
                      >
                        {years.map((y) => (
                          <option key={y} value={y} aria-label={y}>
                            {y}
                          </option>
                        ))}
                      </BootstrapSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Grid
                  container
                  spacing={isXl ? 2 : 1}
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      type="reset"
                      className={clsx(classes.button, classes.buttonClean)}
                    >
                      Limpiar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={clsx(classes.button, classes.buttonSubmit)}
                    >
                      Aplicar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default KpisStatusFilter;
