import { produce, Draft } from 'immer';
import {
  CountriesAction,
  CountriesFailedAction,
  CountriesSuccededAction,
  LIST_COUNTRIES,
  LIST_COUNTRIES_SUCCEEDED,
  LIST_COUNTRIES_FAILED,
} from '../actions/countries';
import { Country } from '../../types/entities/country';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import { ResourceState } from './types';

const initial: ResourceState<Country> = {
  data: [],
  loading: false,
};

const applyChanges = produce(
  (
    draftState: Draft<ResourceState<Country>>,
    action:
      | CountriesAction
      | CountriesSuccededAction
      | CountriesFailedAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_COUNTRIES:
        draftState.loading = true;
        break;
      case LIST_COUNTRIES_SUCCEEDED:
        draftState.loading = false;
        draftState.data = action.payload;
        break;
      case LIST_COUNTRIES_FAILED:
        draftState.loading = false;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: ResourceState<Country> = initial,
  action:
    | CountriesAction
    | CountriesSuccededAction
    | CountriesFailedAction
    | SessionExpiredAction
    | { type?: string },
): ResourceState<Country> => {
  return applyChanges(state, action);
};
