import { Action, ListAction, FailedAction } from './types';
import { Brand } from '../../types/entities/brand';

export const LIST_BRANDS = 'LIST_BRANDS';
export const LIST_BRANDS_SUCCEEDED = 'LIST_BRANDS_SUCCEEDED';
export const LIST_BRANDS_FAILED = 'LIST_BRANDS_FAILED';

export type BrandsAction = Action<'LIST_BRANDS'>;
export type BrandsSuccededAction = ListAction<'LIST_BRANDS_SUCCEEDED', Brand>;
export type BrandsFailedAction = FailedAction<'LIST_BRANDS_FAILED'>;

export const listBrandsAction = (): BrandsAction => ({
  type: LIST_BRANDS,
});

export const notifyListBrandsSucceeded = (
  countries: Brand[],
): BrandsSuccededAction => ({
  type: LIST_BRANDS_SUCCEEDED,
  payload: countries,
});

export const notifyListBrandsFailed = (
  status: number,
  message: string,
): BrandsFailedAction => ({
  type: LIST_BRANDS_FAILED,
  payload: {
    status,
    message,
  },
});
