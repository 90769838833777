import React, { useReducer, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { EventEmitter } from 'events';
import produce, { current } from 'immer';
import {
  Table as MuTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditableText } from '../../../../components/EditableText';
import { StatusIndicator } from '../../../../components/feedback/StatusIndicator';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-head:first-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '& .MuiTableCell-head:last-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& .MuiTableRow-root > .MuiTableCell-root:first-child': {
      paddingLeft: 40,
    },
  },
}));

export interface KpiEditableTableRow {
  status: 'success' | 'warning' | 'error';
  id_kpi: number;
  name_kpi: string;
  value: string;
  validation_regex: string | null;
  validation_error_msg: string | null;
  observation: string;
  editable: boolean;
}

export interface KpiRow {
  id_kpi: number;
  name_kpi: string;
  value: string;
  validation_regex: string | null;
  validation_error_msg: string | null;
  observation: string;
}

interface KpiEditableTableProps {
  data: KpiRow[];
  onEdit: (data: KpiEditableTableRow[]) => void;
  onEditionEnabled: () => void;
  onEditionDisabled: () => void;
}

const EDIT_KPI = 'EDIT_KPI';
const ENABLE_EDITION = 'ENABLE_EDITION';
const DISABLE_EDITION = 'DISABLE_EDITION';
const KPI_VALUE_UPDATED = 'KPI_VALUE_UPDATED';
const RESET_KPI_VALUE = 'RESET_KPI_VALUE';
const OVERRIDE_KPIS = 'OVERRIDE_KPIS';

const successState = 'success';
const headers = [
  { name: 'Estado', dataKey: 'status' },
  { name: 'KPI', dataKey: 'name_kpi' },
  { name: 'Valor', dataKey: 'value' },
  { name: 'Observación', dataKey: 'observation' },
];

const eventEmitter = new EventEmitter();

const extendData = (
  kpis: KpiRow[],
  extraColumns: any,
): KpiEditableTableRow[] => {
  return kpis.map((row: KpiRow) => ({ ...row, ...extraColumns }));
};

const editKpiAction = (index: number, value: string) => ({
  type: EDIT_KPI,
  payload: { index, value },
});

const enableEditionAction = (index: number) => ({
  type: ENABLE_EDITION,
  payload: { index },
});

const disableEditionAction = (index: number) => ({
  type: DISABLE_EDITION,
  payload: { index },
});

const overrideStateAction = (data: KpiEditableTableRow[]) => ({
  type: OVERRIDE_KPIS,
  payload: {
    value: data,
  },
});

const resetKpiValueAction = (index: number, value: string) => ({
  type: RESET_KPI_VALUE,
  payload: { index, value },
});

const validate = (
  value: string,
  validationExpression: string | null,
  errorMessage: string | null,
): { status: 'success' | 'warning' | 'error'; observation: string } => {
  let status: 'success' | 'warning' | 'error' = successState;
  let observation = 'OK';

  if (!value) {
    status = 'warning';
    observation = 'Vacío';
  } else {
    try {
      const regex = new RegExp(validationExpression!);

      if (!regex.test(value)) {
        status = 'error';
        observation = errorMessage ?? 'Valor inválido';
      }
    } catch (e) {}
  }

  return { status, observation };
};

const reducer = produce((draft, action) => {
  const { index, value } = action.payload;

  switch (action.type) {
    case EDIT_KPI:
      const { status, observation } = validate(
        value,
        draft[index].validation_regex,
        draft[index].validation_error_msg,
      );

      draft[index].value = value;
      draft[index].status = status;
      draft[index].observation = observation;
      draft[index].editable = false;

      eventEmitter.emit(KPI_VALUE_UPDATED, current(draft));
      break;
    case ENABLE_EDITION:
      draft[index].editable = true;
      break;
    case DISABLE_EDITION:
      draft[index].editable = false;
      break;
    case RESET_KPI_VALUE:
      draft[index].value = value;
      draft[index].editable = false;
      draft[index].observation = 'OK';
      draft[index].status = successState;

      eventEmitter.emit(KPI_VALUE_UPDATED, current(draft));
      break;
    case OVERRIDE_KPIS:
      return [...value];
  }
});

const KpiEditableTable = ({
  data,
  onEdit,
  onEditionEnabled,
  onEditionDisabled,
}: KpiEditableTableProps) => {
  const classes = useStyles();

  const initialData = useMemo(() => {
    const additionalColumns = {
      status: successState,
      editable: false,
      observation: 'OK',
    };
    return extendData(data, additionalColumns);
  }, [data]);

  const [state, dispatch] = useReducer(reducer, initialData);

  useEffect(() => {
    dispatch(overrideStateAction(initialData));
  }, [initialData]);

  const kpiUpdatedListener = useCallback(
    (kpis: KpiEditableTableRow[]) => {
      onEdit(kpis);
    },
    [onEdit],
  );

  useEffect(() => {
    eventEmitter.on(KPI_VALUE_UPDATED, kpiUpdatedListener);

    return function cleanUp() {
      eventEmitter.removeListener(KPI_VALUE_UPDATED, kpiUpdatedListener);
    };
  }, [kpiUpdatedListener]);

  const enableRowEdition = (index: number) => () => {
    if (!state[index].editable) {
      onEditionEnabled();
      dispatch(enableEditionAction(index));
    }
  };

  const disableRowEdition = (index: number) => {
    dispatch(disableEditionAction(index));
  };

  const resetKpiValue = (index: number) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(resetKpiValueAction(index, data[index].value));
  };

  const saveKpiValue = (index: number) => (value: string) => {
    onEditionDisabled();
    if (value !== data[index].value) {
      dispatch(editKpiAction(index, value));
    } else {
      disableRowEdition(index);
    }
  };

  const renderCell = (index: number, key: string, row: KpiEditableTableRow) => {
    switch (key) {
      case 'value': {
        return (
          <TableCell key={`table-cell-${key}`} style={{ width: '20%' }}>
            <EditableText
              value={row.value ?? ''}
              editable={row.editable}
              onSave={saveKpiValue(index)}
            />
          </TableCell>
        );
      }
      case 'status': {
        return (
          <TableCell key={`table-cell-${key}`} style={{ width: '10%' }}>
            <StatusIndicator status={row.status} />
          </TableCell>
        );
      }
      default:
        return (
          <TableCell key={`table-cell-${key}`} style={{ width: '30%' }}>
            {row[key as 'name_kpi' | 'observation']}
          </TableCell>
        );
    }
  };

  return (
    <MuTable stickyHeader size="small" className={classes.root}>
      <TableHead>
        <TableRow>
          {headers.map(({ name }) => (
            <TableCell role="columnheader" key={`table-header-${name}`}>
              {name}
            </TableCell>
          ))}
          <TableCell role="columnheader" key="table-cell-actions"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.map((row: KpiEditableTableRow, rowIndex: number) => {
          return (
            <TableRow
              key={`table-row-${rowIndex}`}
              hover={true}
              onClick={enableRowEdition(rowIndex)}
            >
              {headers.map(({ dataKey }) => {
                return renderCell(rowIndex, dataKey, row);
              })}
              <TableCell key={`table-cell-resetKpiValue`}>
                {row.value !== data[rowIndex].value && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={resetKpiValue(rowIndex)}
                  >
                    deshacer
                  </Button>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </MuTable>
  );
};

KpiEditableTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      id_kpi: PropTypes.number,
      name_kpi: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export { KpiEditableTable };
