import React, { useState } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import UsersTableHeadPopover from './UsersTableHeadPopover';
import PopoverContentRadio from '../../../components/PopoverContent/PopoverContentRadio';
import PopoverContentCheckbox from '../../../components/PopoverContent/PopoverContentCheckbox';
import PopoverContentSearch from '../../../components/PopoverContent/PopoverContentSearch';
import { FeedbackDense } from '../../../components/feedback/FeedbackDense';
import { AlertIcon } from '../../../components/icons/AlertIcon';

const useStyles = makeStyles(({ palette }) => ({
  headWithFilter: {
    backgroundColor: `${palette.primary.main}!important`,
    color: `${palette.primary.contrastText}!important`,
  },
}));

export type HeaderWithType =
  | {
      label: string;
      type: 'checkbox';
      options: { label: string; value: any }[];
    }
  | {
      label: string;
      type: 'radio';
      options: { label: string; value: any }[];
    }
  | {
      label: string;
      type: 'checkbox';
      options?: { label: string; value: any }[];
      loader: () => Promise<{ label: string; value: any }[]>;
    }
  | {
      label: string;
      type: 'search';
    };

export type Header = { label: string } | HeaderWithType;

export interface UsersTableHeadCellProps {
  header: Header;
  defaultValue?: any;
  onChange: (value: any) => void;
}

export default function UsersTableHeadCell({
  header,
  defaultValue,
  onChange,
}: UsersTableHeadCellProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<
    HTMLTableHeaderCellElement | undefined
  >();
  const [type, setType] = useState<HeaderWithType['type'] | undefined>();
  const [error, setError] = useState<any>();
  const [options, setOptions] = useState<
    { label: string; value: any }[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = (
    event: React.MouseEvent<HTMLTableHeaderCellElement>,
    header: HeaderWithType,
  ) => {
    setType(header.type);
    setAnchorEl(event.currentTarget);
    if (header.type === 'checkbox' || header.type === 'radio') {
      if (!header.options && 'loader' in header && header.loader) {
        setError(undefined);
        setLoading(true);
        header
          .loader()
          .then((data) => {
            setOptions(data);
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false));
      } else {
        setOptions(header.options);
      }
    }
  };

  const handleCancel = () => {
    setAnchorEl(undefined);
  };

  const handleApply = (value?: any[] | string | boolean) => {
    if (value === null || value === undefined) {
      onChange(undefined);
    } else if (
      (typeof value === 'string' || Array.isArray(value)) &&
      value.length
    ) {
      onChange(value);
    } else {
      onChange(undefined);
    }
    setAnchorEl(undefined);
  };
  return (
    <>
      <TableCell
        style={{ cursor: 'pointer' }}
        className={defaultValue ? classes.headWithFilter : undefined}
        onClick={
          'type' in header ? (event) => handleClick(event, header) : undefined
        }
      >
        {header.label}
      </TableCell>

      {anchorEl && (
        <UsersTableHeadPopover
          anchorEl={anchorEl}
          loading={loading}
          onCancel={handleCancel}
        >
          {error && (
            <FeedbackDense
              title="Problema al cargar"
              startIcon={() => <AlertIcon fontSize="large" color="error" />}
              subtitle="Ha ocurrido un problema al cargar las opciones"
            />
          )}
          {type === 'checkbox' && options && (
            <PopoverContentCheckbox
              value={defaultValue}
              options={options}
              onApply={handleApply}
            />
          )}
          {type === 'search' && (
            <PopoverContentSearch value={defaultValue} onApply={handleApply} />
          )}
          {type === 'radio' && options && (
            <PopoverContentRadio
              value={defaultValue}
              options={options}
              onApply={handleApply}
            />
          )}
        </UsersTableHeadPopover>
      )}
    </>
  );
}
