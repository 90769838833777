import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { FeedbackDense } from '../../../components/feedback/FeedbackDense';
import { MailIcon } from '../../../components/icons/MailIcon';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 35,
    maxWidth: 446,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 510,
    },
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 4,
    fontFamily: 'Helvetica Neue',
    boxShadow: `0px 1px 9px ${theme.palette.primary.light}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export function PasswordForgotSuccess() {
  const classes = useStyles();
  return (
    <Dialog
      open
      disableBackdropClick
      className="featured"
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle disableTypography>
        <div className="Signin-logo"></div>
      </DialogTitle>
      <DialogContent>
        <FeedbackDense
          title="Listo"
          startIcon={() => <MailIcon fontSize="large" color="primary" />}
          subtitle="Revisa tu email, te hemos enviado un link."
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          color="primary"
          component={Link}
          to="/singin"
          id="btn-signin"
          data-testid="forgot-password-btn-local-signin"
        >
          Volver a Iniciar Sesión
        </Button>
      </DialogActions>
    </Dialog>
  );
}
