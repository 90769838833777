import { createContext } from 'react';
import { UsersListFilter } from '../../../types/entities/user';

export interface UsersListFiltersContextType {
  filters: UsersListFilter;
  setCurrentFilters: (currentFilters: any) => void;
  goToPage: (page: number) => void;
}

export const USERS_LIST_FILTERS_DEFAULT_VALUE = {
  filters: { page: 1 },
  setCurrentFilters: () => {},
  goToPage: (page: number) => {},
};

export const UsersListFiltersContext = createContext<
  UsersListFiltersContextType
>(USERS_LIST_FILTERS_DEFAULT_VALUE);
