import React from 'react';
import { Feedback } from './Feedback';
import { LikeIcon } from '../icons/LikeIcon';

interface SuccessFeedbackProps {
  title: string;
  subtitle?: string;
}

export const SuccessFeedback = ({ title, subtitle }: SuccessFeedbackProps) => (
  <Feedback
    title={title}
    startIcon={() => <LikeIcon fontSize="large" color="primary" />}
    subtitle={subtitle}
  />
);
