import React from 'react';
import { createStyles, fade, withStyles } from '@material-ui/core';
import Select, { SelectProps } from '@material-ui/core/Select';

export const BootstrapSelect = withStyles(
  ({ palette, breakpoints }) =>
    createStyles({
      root: {
        fontSize: 14,
        lineHeight: '16px',
        [breakpoints.up('xl')]: {
          fontSize: 18,
          lineHeight: '21px',
        },
      },
      select: {
        backgroundColor: palette.common.white,
        color: palette.grey[700],
        borderColor: '#2C66F3',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: 4,
        paddingLeft: 10,
        paddingTop: 8,
        paddingBottom: 8,
        '&:focus': {
          borderRadius: 4,
          borderColor: fade(palette.primary.main, 0.56),
        },
        [breakpoints.up('xl')]: {
          paddingLeft: 14,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    }),
  { name: 'BootstrapSelect' },
)(Select);

export default (props: SelectProps) => {
  return <BootstrapSelect disableUnderline {...props} />;
};
