import React, { useCallback, useEffect, useReducer, Reducer } from 'react';
import { SuccessFeedback } from '../../components/feedback/SuccessFeedback';
import { FailFeedback } from '../../components/feedback/FailFeedback';
import api from '../../services/api';
import { AxiosError } from 'axios';
import LoadKpiInit, {
  LoadKpiInitData,
} from '../../components/Kpis/LoadKpiInit';
import LoadKpiPreview from '../../components/Kpis/LoadKpiPreview';
import LoadKpiErrors, {
  KpiValidationError,
} from '../../components/Kpis/LoadKpiErrors';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Box,
} from '@material-ui/core';
import { useModalTimeout } from '../../hooks/useModalTimeout';
import { Country } from '../../types/entities/country';

interface KpiModalProps {
  open: boolean;
  countries: Country[];
  onClose: () => void;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

enum LoadKpiStep {
  LOAD = 'load',
  PREVIEW = 'preview',
  ERRORS = 'errors',
  FAILED = 'failed',
  SUCCESS = 'success',
  LOADING = 'loading',
}

interface LoadKpiModalState {
  data?: LoadKpiInitData;
  step: LoadKpiStep;
  validations: KpiValidationError[];
}

type LoadKpiModalAction =
  | {
      type: 'RESET';
    }
  | {
      type: 'SET_LOAD_DATA';
      data: LoadKpiInitData;
    }
  | {
      type: 'RESET_WITH_DATA';
    }
  | {
      type: 'SHOW_VALIDATION_ERRORS';
      validations: KpiValidationError[];
    }
  | {
      type: 'SET_SUCCESS';
    }
  | {
      type: 'SET_FAILED';
    }
  | {
      type: 'SET_LOADING';
    };

function init(data?: LoadKpiInitData): LoadKpiModalState {
  return {
    data: data ?? undefined,
    step: LoadKpiStep.LOAD,
    validations: [],
  };
}

const reducer: Reducer<LoadKpiModalState, LoadKpiModalAction> = function (
  state,
  action,
) {
  switch (action.type) {
    case 'SET_LOAD_DATA':
      return {
        data: action.data,
        step: LoadKpiStep.PREVIEW,
        validations: [],
      };
    case 'RESET_WITH_DATA':
      const { data } = state;
      return init(data ? { ...data, kpis: [] } : undefined);
    case 'SHOW_VALIDATION_ERRORS':
      return {
        ...state,
        step: LoadKpiStep.ERRORS,
        validations: action.validations,
      };
    case 'RESET':
      return init();
    case 'SET_SUCCESS':
      return {
        ...state,
        step: LoadKpiStep.SUCCESS,
      };
    case 'SET_FAILED':
      return {
        ...state,
        step: LoadKpiStep.FAILED,
      };
    case 'SET_LOADING':
      return {
        ...state,
        step: LoadKpiStep.LOADING,
      };
    default:
      throw new Error();
  }
};

export const LoadKpiModal = ({ open, countries, onClose }: KpiModalProps) => {
  const [{ data, step, validations }, dispatch] = useReducer(
    reducer,
    undefined,
    init,
  );

  const startTimeout = useModalTimeout(open, onClose);

  const sendKpis = useCallback(
    async (confirmed?: boolean) => {
      try {
        if (!data) {
          return;
        }
        dispatch({ type: 'SET_LOADING' });
        await api.loadKpisByUser({
          month: parseInt(data.month, 10),
          year: parseInt(data.year, 10),
          id_country: parseInt(data.country, 10),
          id_brand: parseInt(data.brand, 10),
          kpis: data.kpis,
          confirmed,
        });
        dispatch({ type: 'SET_SUCCESS' });
        startTimeout(5000);
      } catch (error) {
        console.error(error);
        const { response } = error as AxiosError;
        if (
          response &&
          response.status === 400 &&
          response.data.code === 'REGISTRY_VALIDATION'
        ) {
          dispatch({
            type: 'SHOW_VALIDATION_ERRORS',
            validations: response.data.data,
          });
        } else {
          dispatch({ type: 'SET_FAILED' });
          startTimeout(5000);
        }
      }
    },
    [data, startTimeout],
  );

  useEffect(() => {
    return function cleanUp() {
      if (!open) {
        dispatch({ type: 'RESET' });
      }
    };
  }, [open]);

  return (
    <Dialog onClose={onClose} aria-labelledby="generate-template" open={open}>
      {/* Load data step */}
      {step === LoadKpiStep.LOAD && (
        <LoadKpiInit
          initialData={data}
          countries={countries}
          onSuccess={(loadData: LoadKpiInitData) => {
            dispatch({ type: 'SET_LOAD_DATA', data: loadData });
          }}
          onCancel={onClose}
        />
      )}

      {/* File Preview step */}
      {step === LoadKpiStep.PREVIEW && (
        <LoadKpiPreview
          kpis={data?.kpis || []}
          onSuccess={() => {
            sendKpis();
          }}
          onCancel={() => {
            dispatch({ type: 'RESET_WITH_DATA' });
          }}
        />
      )}

      {/* Display Validation errors step */}
      {step === LoadKpiStep.ERRORS && (
        <LoadKpiErrors
          validations={validations || []}
          onSuccess={() => {
            sendKpis(true);
          }}
          onCancel={() => {
            dispatch({ type: 'RESET_WITH_DATA' });
          }}
        />
      )}
      {LoadKpiStep.SUCCESS === step && (
        <DialogContent>
          <SuccessFeedback
            title="¡Carga de KPI's exitosa!"
            subtitle="Ya puedes ir al listado y revisar los detalles"
          />
        </DialogContent>
      )}
      {LoadKpiStep.FAILED === step && (
        <DialogContent>
          <FailFeedback
            title="Ups, ha ocurrido un problema inesperado"
            subtitle="Por favor intentalo de nuevo"
            retry={true}
            onRetry={() => {
              sendKpis();
            }}
          />
        </DialogContent>
      )}
      {LoadKpiStep.LOADING === step && (
        <DialogContent>
          <Box textAlign="center">
            <CircularProgress style={{ margin: 'auto' }} />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};
