import { Action, ListAction, FailedAction } from './types';
import { Category } from '../../types/entities/category';

export const LIST_CATEGORIES = 'LIST_CATEGORIES';
export const LIST_CATEGORIES_SUCCEEDED = 'LIST_CATEGORIES_SUCCEEDED';
export const LIST_CATEGORIES_FAILED = 'LIST_CATEGORIES_FAILED';

export type CategoriesAction = Action<'LIST_CATEGORIES'>
export type CategoriesSuccededAction = ListAction<'LIST_CATEGORIES_SUCCEEDED', Category>;
export type CategoriesFailedAction = FailedAction<'LIST_CATEGORIES_FAILED'>;

export const listCategoriesAction = (): CategoriesAction => ({
  type: LIST_CATEGORIES,
});

export const notifyListCategoriesSucceeded = (
  categories: Category[],
): CategoriesSuccededAction => ({
  type: LIST_CATEGORIES_SUCCEEDED,
  payload: categories,
});

export const notifyListCategoriesFailed = (
  status: number,
  message: string,
): CategoriesFailedAction => ({
  type: LIST_CATEGORIES_FAILED,
  payload: {
    status,
    message,
  },
});
