import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as pbi from 'powerbi-client';
import api from '../../services/api';
import { useDispatch } from 'react-redux';
import { sessionExpired } from '../../store/actions/auth/session';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FailFeedback } from '../../components/feedback/FailFeedback';
import { useTimeout } from '../../hooks/useTimeout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 24,
    [theme.breakpoints.up('xl')]: {
      paddingTop: 28,
    },
    height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
    display: 'flex',
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  contentContainer: {},
  dashboard: {
    flex: 1,
    minHeight: 0,
    height: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(9),
    },
    '& > iframe': {
      borderWidth: 0,
      borderRadius: 20,
    },
  },
  header: {
    paddingBottom: '30px !important',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}));

export const Dashboards = () => {
  const classes = useStyles();
  const powerbiContainer = useRef<HTMLDivElement | null>(null);
  const powerbi = useMemo(
    () =>
      new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory,
      ),
    [],
  );

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [expiration, setExpiration] = useState<number>(3599);
  const [embedToken, setEmbedToken] = useState<string | null>(null);
  const [mainVisualization, setMainVisualization] = useState<any>({});
  const [reportInfo, setReportInfo] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();

  // Get the visualization that should be displayed for the current user
  useEffect(() => {
    async function listVisualizations() {
      try {
        const visualizations = await api.listVisualizationByUser();
        const mainVisualization =
          visualizations.find((visualization) => visualization.default) || {};
        setMainVisualization(mainVisualization);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          dispatch(sessionExpired());
        }
      }
    }

    listVisualizations();
  }, [dispatch]);

  // Get powerbi access token
  useEffect(() => {
    async function fetchAccessToken() {
      let {
        access_token,
        expires_in,
      }: any = await api.getVisualizationAccessToken();
      setExpiration(parseInt(expires_in));
      setAccessToken(access_token);
    }

    if (!accessToken) {
      fetchAccessToken();
    }
  }, [accessToken]);

  const [setTimeout, cancelTimeout] = useTimeout(() => {
    dispatch(sessionExpired());
  });

  useEffect(() => {
    if (accessToken) {
      setTimeout(expiration * 1000);
    }

    return cancelTimeout;
  }, [accessToken, cancelTimeout, setTimeout, expiration]);

  // Get powerbi report info
  useEffect(() => {
    async function fetchReportInfo() {
      try {
        let data: any = await api.getVisualizationInfo(
          accessToken as string,
          mainVisualization.report_id,
        );
        setReportInfo(data);
      } catch (response) {
        if (response?.status === 404) {
          setErrorMsg(`No hemos podido encontrar un reporte para mostrar.`);
        } else {
          setErrorMsg(
            `No hemos podido conectarnos a Power BI.
            Por favor intente más tarde.`,
          );
        }
      }
    }

    if (accessToken && mainVisualization.report_id) {
      fetchReportInfo();
    }
  }, [accessToken, mainVisualization]);

  // Get powerbi embed token
  useEffect(() => {
    async function fetchEmbedToken() {
      let { token }: any = await api.getVisualizationEmbedToken(
        accessToken as string,
        reportInfo.id,
        reportInfo.datasetId,
      );
      setEmbedToken(token);
    }

    if (accessToken && reportInfo.id) {
      fetchEmbedToken();
    }
  }, [accessToken, reportInfo]);

  // Embed powerbi report into HTML
  useEffect(() => {
    const element = powerbiContainer.current as HTMLDivElement;
    if (embedToken && reportInfo.id) {
      powerbi.reset(element);
      powerbi.embed(element, {
        type: 'report',
        // @ts-ignore
        viewMode: pbi.models.ViewMode.View,
        id: reportInfo.id,
        permissions: pbi.models.Permissions.All,
        tokenType: pbi.models.TokenType.Embed,
        accessToken: embedToken as string,
        embedUrl: reportInfo.embedUrl,
        settings: {
          // @ts-ignore
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
        },
      });
    }
    return function cleanUp() {
      if (element) {
        powerbi.reset(element);
      }
    };
  }, [powerbi, embedToken, reportInfo]);

  return (
    <Container className={classes.root} maxWidth="xl">
      <Box display="flex" flexDirection="column" flex={1} width="100%">
        <Box className={classes.titleContainer}>
          <Typography variant="h1">Dashboard</Typography>
        </Box>
        {!errorMsg ? (
          <div ref={powerbiContainer} className={classes.dashboard}></div>
        ) : (
          <div className={classes.centerContainer}>
            <div>
              <FailFeedback
                title="Ups, ha ocurrido un problema inesperado"
                subtitle={errorMsg}
              />
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
};
