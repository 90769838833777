import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  LIST_BRANDS,
  notifyListBrandsFailed,
  notifyListBrandsSucceeded,
} from '../actions/brands';
import api from '../../services/api';
import { ProfileState } from '../reducers/auth';
import { sessionExpired } from '../actions/auth/session';

export function* listBrandsSaga() {
  const { id }: ProfileState = yield select((state: any) => state.auth.profile);
  try {
    const { data } = yield call(api.listBrandsByUser, id!);
    yield put(notifyListBrandsSucceeded(data));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      notifyListBrandsFailed(e.response.status, e.response.data.message),
    );
  }
}

export default function* authRootSaga() {
  yield takeLatest(LIST_BRANDS, listBrandsSaga);
}
