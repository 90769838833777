import {
    SignoutRequestAction,
    SignoutFailedAction,
    SignoutSuccededAction,
} from "./types";

export const USER_SIGNOUT = "USER_SIGNOUT";
export const SIGNOUT_SUCCEEDED = "SIGNOUT_SUCCEEDED";
export const SIGNOUT_FAILED = "SIGNOUT_FAILED";

export const userSignout = (): SignoutRequestAction => ({
    type: USER_SIGNOUT,
});

export const notifySignoutSuccess = (): SignoutSuccededAction => ({
    type: SIGNOUT_SUCCEEDED,
});

export const notifySignoutFailure = (): SignoutFailedAction => ({
    type: SIGNOUT_FAILED,
});
