import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  LIST_COUNTRIES,
  notifyListCountriesFailed,
  notifyListCountriesSucceeded,
} from '../actions/countries';
import api from '../../services/api';
import { ProfileState } from '../reducers/auth';
import { sessionExpired } from '../actions/auth/session';

export function* listCountriesSaga() {
  const { id }: ProfileState = yield select((state: any) => state.auth.profile);
  try {
    const { data } = yield call(api.listCountriesByUser, id!);
    yield put(notifyListCountriesSucceeded(data));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      notifyListCountriesFailed(e.response.status, e.response.data.message),
    );
  }
}

export default function* authRootSaga() {
  yield takeLatest(LIST_COUNTRIES, listCountriesSaga);
}
