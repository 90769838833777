import {
  KpiWithAssignable,
  ListAssignmentsKpisAction,
  ListAssignmentsKpisFailedAction,
  ListAssignmentsKpisSuccededAction,
} from './types';

export const LIST_ASSIGNMENTS_KPIS = 'LIST_ASSIGNMENTS_KPIS';
export const LIST_ASSIGNMENTS_KPIS_SUCCEEDED =
  'LIST_ASSIGNMENTS_KPIS_SUCCEEDED';
export const LIST_ASSIGNMENTS_KPIS_FAILED = 'LIST_ASSIGNMENTS_KPIS_FAILED';

export const listKpisAction = (
  countryId: number,
  brandId: number,
  userId: number | null
): ListAssignmentsKpisAction => ({
  type: LIST_ASSIGNMENTS_KPIS,
  payload: { countryId, brandId, userId },
});

export const notifyListAssignmentsKpisSucceeded = (
  brands: KpiWithAssignable[],
): ListAssignmentsKpisSuccededAction => ({
  type: LIST_ASSIGNMENTS_KPIS_SUCCEEDED,
  payload: brands,
});

export const notifyListAssignmentsKpisFailed = (
  status: number,
  message: string,
): ListAssignmentsKpisFailedAction => ({
  type: LIST_ASSIGNMENTS_KPIS_FAILED,
  payload: {
    status,
    message,
  },
});
