import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
import { Loader, GetFromStore } from './types';

/**
 * Hook that trigger an action and load data from store.
 * It also listen changes on a parent value to trigger the action again.
 */
export function useDependentLoader<T, P>(
  parent: P | null,
  getFromStore: GetFromStore<T>,
  listAction: (parent: P) => any,
): Loader<T> {
  const dispatch = useDispatch();
  const { options, loading, error } = useSelector(getFromStore, shallowEqual);

  useEffect(() => {
    if (parent) {
      dispatch(listAction(parent!));
    }
  }, [dispatch, parent, listAction]);

  return { options, loading, error };
}
