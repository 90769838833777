import { produce, Draft } from 'immer';
import {
  CategoriesAction,
  CategoriesFailedAction,
  CategoriesSuccededAction,
  LIST_CATEGORIES,
  LIST_CATEGORIES_SUCCEEDED,
  LIST_CATEGORIES_FAILED,
} from '../actions/categories';
import { Category } from '../../types/entities/category';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import { ResourceState } from './types';

const initial: ResourceState<Category> = {
  data: [],
  loading: false,
};

const applyChanges = produce(
  (
    draftState: Draft<ResourceState<Category>>,
    action:
      | CategoriesAction
      | CategoriesSuccededAction
      | CategoriesFailedAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_CATEGORIES:
        draftState.loading = true;
        break;
      case LIST_CATEGORIES_SUCCEEDED:
        draftState.loading = false;
        draftState.data = action.payload;
        break;
      case LIST_CATEGORIES_FAILED:
        draftState.loading = false;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: ResourceState<Category> = initial,
  action:
    | CategoriesAction
    | CategoriesSuccededAction
    | CategoriesFailedAction
    | SessionExpiredAction
    | { type?: string },
): ResourceState<Category> => {
  return applyChanges(state, action);
};
