import { Action, ListAction, FailedAction } from './types';
import { User, UsersListFilter } from '../../types/entities/user';

export const LIST_USERS = 'LIST_USERS';
export const LIST_USERS_SUCCEEDED = 'LIST_USERS_SUCCEEDED';
export const LIST_USERS_FAILED = 'LIST_USERS_FAILED';

export type UsersAction = Action<typeof LIST_USERS> & {
  payload: UsersListFilter;
};
export type UsersSuccededAction = ListAction<typeof LIST_USERS_SUCCEEDED, User>;
export type UsersFailedAction = FailedAction<typeof LIST_USERS_FAILED>;

export const listUsersAction = (
  payload: UsersAction['payload'],
): UsersAction => ({
  type: LIST_USERS,
  payload,
});

export const notifyListUsersSucceeded = (
  users: User[],
): UsersSuccededAction => ({
  type: LIST_USERS_SUCCEEDED,
  payload: users,
});

export const notifyListUsersFailed = (
  status: number,
  message: string,
): UsersFailedAction => ({
  type: LIST_USERS_FAILED,
  payload: {
    status,
    message,
  },
});
