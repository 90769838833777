import React, { useState, useCallback } from 'react';
import { HorizontalGrid as Grid } from '../../../components/HorizontalGrid';
import { CountryWithOptionals, Country } from '../../../types/entities/country';
import {
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  FormHelperText,
} from '@material-ui/core';
import { BrandWithAssignable } from '../../../store/actions/user-assignments/types';
import { getFromUserAssignmentsKpisStore } from './UserAssignedKpis/utils';
import { listBrandsAction } from '../../../store/actions/user-assignments/brands';
import { useDependentLoader } from '../../../hooks/loaders/useDependentLoader';
import { ViewerAssignment } from '../../../types/entities/viewer-assignments';

const getBrandsFromStore = getFromUserAssignmentsKpisStore<
  BrandWithAssignable[]
>('brands');

interface BrandAndCountryIds {
  id_country: number;
  id_brand: number;
}

interface BrandAndCountrySelected {
  country: string;
  brand: string;
}

interface UserVisualizationAssignerProps {
  open: boolean;
  countries: CountryWithOptionals[];
  assignments: BrandAndCountryIds[];
  onConfirm: (value: ViewerAssignment) => void;
  onCancel: () => void;
}

const UserVisualizationAssigner = ({
  open,
  assignments,
  countries,
  onConfirm,
  onCancel,
}: UserVisualizationAssignerProps) => {
  const selectValues = {
    country: '',
    brand: '',
  };
  const [selected, setSelected] = useState({ ...selectValues });
  const [errorMsg, setErrorMsg] = useState('');
  const { options: brands, loading: loadingBrands } = useDependentLoader(
    selected.country,
    getBrandsFromStore,
    listBrandsAction,
  );

  const isValidAssignment = (
    selected: BrandAndCountrySelected,
    data: BrandAndCountryIds[],
  ): boolean => {
    return !Boolean(
      data.find(
        ({ id_brand, id_country }: BrandAndCountryIds) =>
          parseInt(selected.brand) === id_brand &&
          parseInt(selected.country) === id_country,
      ),
    );
  };

  const handleSelection = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode,
  ) => {
    const newValues = {
      ...selected,
      [e.target.name as string]: e.target.value,
    };

    setSelected(newValues);

    if (isValidAssignment(newValues, assignments)) {
      setErrorMsg('');
    } else {
      setErrorMsg('Esta combinación ya ha sido asignada');
    }
  };

  const reset = useCallback(() => {
    setSelected({ ...selectValues });
    setErrorMsg('');
  }, [selectValues]);

  const innerCancel = useCallback(() => {
    onCancel();
    reset();
  }, [onCancel, reset]);

  const innerConfirm = useCallback(() => {
    const country = (countries.find(
      (country) => country.id_country === parseInt(selected.country),
    ) as unknown) as Country;
    const brand = (brands.find(
      (brand) => brand.id_brand === parseInt(selected.brand),
    ) as unknown) as BrandWithAssignable;

    onConfirm({ ...country, ...brand });
    reset();
  }, [onConfirm, selected, reset, countries, brands]);

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>ingreso nueva visualizacion</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6}>
            <FormControl required style={{ flex: 1 }}>
              <InputLabel id="select-country-label">Pais</InputLabel>
              <Select
                native
                labelId="select-country-label"
                name="country"
                value={selected.country}
                onChange={handleSelection}
              >
                <option value="" aria-label="None" />
                {countries.map((country) => (
                  <option key={country.id_country} value={country.id_country}>
                    {country.name_country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6}>
            <FormControl required style={{ flex: 1 }}>
              <InputLabel id="select-brand-label">Marca</InputLabel>
              <Select
                native
                labelId="select-brand-label"
                name="brand"
                value={selected.brand}
                onChange={handleSelection}
                disabled={loadingBrands}
              >
                <option value="" aria-label="None" />
                {brands.map((brand) => (
                  <option key={brand.id_brand} value={brand.id_brand}>
                    {brand.name_brand}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormHelperText error={!!errorMsg} required>
          {errorMsg}
        </FormHelperText>
      </DialogContent>
      <DialogActions className="big-top-space">
        <Button
          aria-label="cancel"
          color="secondary"
          variant="contained"
          onClick={innerCancel}
        >
          Cancelar
        </Button>
        <Button
          aria-label="confirm"
          color="primary"
          variant="contained"
          onClick={innerConfirm}
          disabled={!selected.country || !selected.brand || !!errorMsg}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { UserVisualizationAssigner };
