import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core';
import SwitchCore, {
  SwitchClassKey,
  SwitchProps,
} from '@material-ui/core/Switch';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const Switch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 38,
      height: 20,
      padding: 0,
    },
    switchBase: {
      color: theme.palette.common.white,
      padding: 1,
      '&$checked': {
        color: theme.palette.common.white,
        transform: 'translateX(18px)',
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
        borderWidth: 6,
        borderStyle: 'solid',
        borderColor: theme.palette.common.white,
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 20 / 2,
      backgroundColor: '#CBCBCB',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <SwitchCore
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default Switch;
