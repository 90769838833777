import React, { useMemo, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { AntTab, AntTabs } from '../../../../components/AntTabs';
import { KpiWithAssignable } from '../../../../store/actions/user-assignments/types';
import { Chip, Box } from '@material-ui/core';
import { UserAssignedKpisModifyTableProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 190,
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export function UserAssignedKpisModifyTable({
  allKpis,
  selectedKpis,
  dimensions,
  onChange,
}: UserAssignedKpisModifyTableProps) {
  const classes = useStyles();

  const kpisByDimension = useMemo(
    function groupKpisByDimension() {
      const byDimension = new Map<number, KpiWithAssignable[]>(
        dimensions.map((d) => [d.id_dimension, []]),
      );
      for (const kpi of allKpis) {
        const kpis = byDimension.get(kpi.id_dimension);
        if (kpis) {
          kpis.push(kpi);
        }
      }
      return byDimension;
    },
    [allKpis, dimensions],
  );

  const [checked, setChecked] = useState<number[]>(selectedKpis);

  useEffect(() => {
    onChange(checked.filter((c) => allKpis.find((k) => k.id_kpi === c)));
  }, [checked, onChange, allKpis]);

  const countByDimension = useMemo<Map<number, number>>(
    function countAssignedKpisByDimension() {
      return new Map(
        Array.from(kpisByDimension.entries()).map(([id_dimension, kpis]) => {
          const count = kpis.filter((kpi) =>
            checked.some((c) => c === kpi.id_kpi),
          ).length;
          return [id_dimension, count];
        }),
      );
    },
    [kpisByDimension, checked],
  );

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [selectedDimension, setSelectedDimension] = useState<number | null>(
    null,
  );
  useEffect(() => {
    if (dimensions?.length > 0) {
      setSelectedDimension(dimensions[0].id_dimension);
    } else {
      setSelectedDimension(null);
    }
  }, [dimensions]);

  const handleDimensionChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ) => {
    setSelectedDimension(newValue);
  };

  return (
    <>
      {selectedDimension !== null ? (
        <AntTabs
          value={selectedDimension}
          onChange={handleDimensionChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          aria-label="Funnel tabs"
        >
          {dimensions.map((d) => (
            <AntTab
              value={d.id_dimension}
              label={
                <Box display="inline-flex" alignItems="baseline">
                  {d.name_dimension}
                  <Chip
                    color="primary"
                    size="small"
                    label={countByDimension.get(d.id_dimension) ?? 0}
                    style={{ marginLeft: 9 }}
                  />
                </Box>
              }
              key={d.id_dimension}
            />
          ))}
        </AntTabs>
      ) : null}
      {selectedDimension && kpisByDimension.get(selectedDimension)?.length ? (
        <List className={classes.root}>
          {(kpisByDimension.get(selectedDimension) ?? []).map((kpi) => {
            const labelId = `checkbox-list-label-${kpi}`;

            return (
              <ListItem
                key={kpi.id_kpi}
                role={undefined}
                dense
                button
                onClick={handleToggle(kpi.id_kpi)}
                disabled={!kpi.assignable}
              >
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={checked.indexOf(kpi.id_kpi) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={kpi.name_kpi} />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <List className={classes.root}>
          <ListItem role={undefined} dense>
            <ListItemText primary="No hay KPIs para está dimensión" />
          </ListItem>
        </List>
      )}
    </>
  );
}
