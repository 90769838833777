const UPPERCASE_RE = /([A-Z])/g;
const NUMBER_RE = /([\d])/g;
const SPECIAL_CHAR_RE = /([ @$¡!%*¿?&_.-])/g;

export const DEFAULT_PASSWORD_STRONG_OPTIONS = {
  maxLength: 12,
  minLength: 9,
  uppercaseMinCount: 1,
  numberMinCount: 1,
  specialMinCount: 1,
};

export function isPasswordStrong(password: string, ignoreMaxLength = true) {
  const options = DEFAULT_PASSWORD_STRONG_OPTIONS;
  const uc = password.match(UPPERCASE_RE);
  const n = password.match(NUMBER_RE);
  const sc = password.match(SPECIAL_CHAR_RE);
  return (
    password.length >= options.minLength &&
    (ignoreMaxLength || password.length <= options.maxLength) &&
    uc &&
    uc.length >= options.uppercaseMinCount &&
    n &&
    n.length >= options.numberMinCount &&
    sc &&
    sc.length >= options.specialMinCount
  );
}
