import { Action, ListAction, FailedAction } from './types';
import {Country} from '../../types/entities/country';

export const LIST_COUNTRIES = 'LIST_COUNTRIES';
export const LIST_COUNTRIES_SUCCEEDED = 'LIST_COUNTRIES_SUCCEEDED';
export const LIST_COUNTRIES_FAILED = 'LIST_COUNTRIES_FAILED';

export type CountriesAction = Action<'LIST_COUNTRIES'>
export type CountriesSuccededAction = ListAction<'LIST_COUNTRIES_SUCCEEDED', Country>;
export type CountriesFailedAction = FailedAction<'LIST_COUNTRIES_FAILED'>;

export const listCountriesAction = (): CountriesAction => ({
  type: LIST_COUNTRIES,
});

export const notifyListCountriesSucceeded = (
  countries: Country[],
): CountriesSuccededAction => ({
  type: LIST_COUNTRIES_SUCCEEDED,
  payload: countries,
});

export const notifyListCountriesFailed = (
  status: number,
  message: string,
): CountriesFailedAction => ({
  type: LIST_COUNTRIES_FAILED,
  payload: {
    status,
    message,
  },
});
