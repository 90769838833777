// Countries

import { Country } from '../../../types/entities/country';

export const LIST_COUNTRIES = 'LIST_KPIS_COUNTRIES';
export const LIST_COUNTRIES_SUCCEEDED = 'LIST_KPIS_COUNTRIES_SUCCEEDED';
export const LIST_COUNTRIES_FAILED = 'LIST_KPIS_COUNTRIES_FAILED';

export interface ListCountriesAction {
  type: typeof LIST_COUNTRIES;
}

export interface ListCountriesSuccededAction {
  type: typeof LIST_COUNTRIES_SUCCEEDED;
  payload: Country[];
}

export interface ListCountriesFailedAction {
  type: typeof LIST_COUNTRIES_FAILED;
  payload: { status: number; message: string };
}

export type ListCountriesResponseAction =
  | ListCountriesSuccededAction
  | ListCountriesFailedAction;

export const listCountriesAction = (): ListCountriesAction => ({
  type: LIST_COUNTRIES,
});

export const notifyListCountriesSucceeded = (
  countries: Country[],
): ListCountriesSuccededAction => ({
  type: LIST_COUNTRIES_SUCCEEDED,
  payload: countries,
});

export const notifyListCountriesFailed = (
  status: number,
  message: string,
): ListCountriesFailedAction => ({
  type: LIST_COUNTRIES_FAILED,
  payload: {
    status,
    message,
  },
});
