import React from 'react';
import {
  Typography,
  Box,
  makeStyles,
  Theme,
  TableContainer,
} from '@material-ui/core';
import KpisStatusTable from './KpisStatusTable';
import { KpisStatusGroup as KpisStatusGroupType } from '../../../types/entities/kpis-status';

export interface KpisStatusGroupProps {
  group: KpisStatusGroupType;
  hasDetails: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 25,
    lineHeight: '30px',
    color: '#A2A1A1',
    marginBottom: theme.spacing(1),
  },
}));

function KpisStatusGroup({ group, hasDetails }: KpisStatusGroupProps) {
  const classes = useStyles();
  return (
    <Box mb={4}>
      <Typography variant="h2" className={classes.title}>
        {group.name_country}
      </Typography>
      <TableContainer>
        <KpisStatusTable data={group.data} hasDetails={hasDetails} />
      </TableContainer>
    </Box>
  );
}
export default React.memo(KpisStatusGroup);
