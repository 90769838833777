import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './index.css';
import App from './App';
import { store, persistor } from './store';
import * as serviceWorker from './serviceWorker';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';

const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
  shadows: [
    'none',
    '0px 2px 10px #00000027',
    '0px 3px 1px -2px #00000027',
    '0px 3px 3px -2px #00000027',
    '0px 2px 4px -1px #00000027',
    '0px 3px 5px -1px #00000027',
    '0px 3px 5px -1px #00000027',
    '0px 4px 5px -2px #00000027',
    '0px 5px 5px -3px #00000027',
    '0px 5px 6px -3px #00000027',
    '0px 6px 6px -3px #00000027',
    '0px 6px 7px -4px #00000027',
    '0px 7px 8px -4px #00000027',
    '0px 7px 8px -4px #00000027',
    '0px 7px 9px -4px #00000027',
    '0px 8px 9px -5px #00000027',
    '0px 8px 10px -5px #00000027',
    '0px 8px 11px -5px #00000027',
    '0px 9px 11px -5px #00000027',
    '0px 9px 12px -6px #00000027',
    '0px 10px 13px -6px #00000027',
    '0px 10px 13px -6px #00000027',
    '0px 10px 14px -6px #00000027',
    '0px 11px 14px -7px #00000027',
    '0px 11px 15px -7px #00000027',
  ],
  props: {
    MuiDialog: {
      scroll: 'body',
      maxWidth: 'md',
    },
    MuiPaper: {
      elevation: 1,
    },
  },
  mixins: {
    toolbar: { height: 46 },
  },
  typography: {
    fontFamily: 'Helvetica Neue',
    h1: {
      fontSize: 24,
      fontWeight: 'bolder',
    },
    h2: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 15,
    },
    button: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      main: '#2D67F6',
    },
    secondary: {
      main: '#898C8D',
    },
    error: {
      main: '#CD0905',
    },
    warning: {
      main: '#ffd908',
    },
    success: {
      main: '#0BA005',
    },
    action: {
      disabled: '#CBCBCB',
      selected: '#2D67F6',
      selectedOpacity: 0.8,
    },
    background: {
      default: '#F6F7FC',
    },
  },
  overrides: {
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '4rem',
      },
    },
    MuiToolbar: {
      root: {
        height: 70,
      },
    },
    MuiButton: {
      root: {
        padding: '10px 20px',
      },
      text: {
        color: 'white',
      },
      outlined: {
        padding: '12px 24px',
      },
      outlinedPrimary: {
        borderColor: '#2D67F6',
      },
      containedPrimary: {
        '&$disabled': {
          color: '#FFFFFF',
          background: '#CBCBCB',
        },
      },
    },
    MuiChip: {
      sizeSmall: {
        height: 19,
      },
      labelSmall: {
        fontSize: 10,
        paddingLeft: 7,
        paddingRight: 7,
      },
    },
    MuiDialog: {
      paperWidthMd: {
        width: 800,
      },
      root: {
        '&.featured': {
          backgroundImage: "url('/assets/wave_background@2x.png')",
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'unset',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          '& > .MuiBackdrop-root': {
            background: 'unset',
          },
          '& .MuiDialogTitle-root': {
            flexDirection: 'column',
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: 50,
        paddingBottom: 28,
        paddingRight: 20,
        paddingLeft: 20,
        fontSize: 18,
        fontWeight: 'bold',
        '& > .MuiSvgIcon-root:first-child': {
          fontSize: 18,
          marginRight: 15,
        },
        [breakpoints.up('md')]: {
          paddingRight: 65,
          paddingLeft: 65,
        },
      },
    },
    MuiDialogContent: {
      root: {
        paddingRight: 20,
        paddingLeft: 20,
        '&:first-child': {
          paddingTop: 80,
        },
        '&:last-child': {
          paddingBottom: 44,
        },
        [breakpoints.up('xl')]: {
          '&:last-child': {
            paddingBottom: 54,
          },
        },
        '& .MuiFormControl-root.MuiTextField-root': {
          flex: 1,
        },
        '& .MuiFormControl-root.MuiTextField-root .MuiInputBase-root': {
          marginBottom: 23,
        },
        '& .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.Mui-error': {
          marginBottom: 0,
        },
        '& .MuiFormControl-root.MuiTextField-root .MuiHelperText-root.Mui-error': {
          marginTop: 3,
          height: 20,
        },
        [breakpoints.up('md')]: {
          paddingRight: 65,
          paddingLeft: 65,
        },
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: 14,
        paddingBottom: 36,
        paddingRight: 20,
        paddingLeft: 20,
        [breakpoints.up('md')]: {
          paddingRight: 65,
          paddingLeft: 65,
        },
        [breakpoints.up('xl')]: {
          paddingBottom: 45,
        },
        '& .MuiButton-root': {
          flex: 1,
        },
        '&.big-top-space .MuiButton-root': {
          fontSize: 18,
        },
        '&.big-top-space': {
          paddingTop: 32,
          paddingBottom: 50,
          [breakpoints.up('xl')]: {
            paddingTop: 72,
          },
        },
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 24,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        '&.MuiTypography-h2': {
          fontSize: 14,
          fontWeight: 'bold',
          color: '#1D1D1B',
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#2D67F6',
        '&$checked': {
          color: '#2D67F6',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#F1F1F3',
      },
      head: {
        color: '#A2A1A1',
        backgroundColor: '#EBEBEB',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        borderBottom: 'none',
        '&:first-child': {
          paddingLeft: 22,
          borderTopLeftRadius: 26,
          borderBottomLeftRadius: 26,
        },
        '&:last-child': {
          paddingRight: 22,
          borderTopRightRadius: 26,
          borderBottomRightRadius: 26,
        },
        '&$sizeSmall': {
          fontSize: 10,
          lineHeight: '12px',
          '&:first-child': {
            borderTopLeftRadius: 17,
            borderBottomLeftRadius: 17,
          },
          '&:last-child': {
            borderTopRightRadius: 17,
            borderBottomRightRadius: 17,
          },
        },
      },
      body: {
        color: '#1D1D1B',
      },
      sizeSmall: {},
      stickyHeader: {
        '&$head': { color: '#A2A1A1', backgroundColor: '#EBEBEB' },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: '#FFFFFF',
        },
        '&$focusVisible': {
          color: '#FFFFFF',
        },
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: 'flex-end',
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#898C8D',
        backgroundColor: '#FFFFFF',
      },
      outlined: {
        border: `1px solid #E8E9EC`,
      },
      sizeLarge: {
        fontSize: 14,
        lineHeight: '17px',
      },
      page: {
        '&$selected': {
          color: '#FFFFFF',
        },
      },
      icon: {
        color: '#5B7ADA',
      },
    },
  } as CoreOverrides,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
