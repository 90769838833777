import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { HorizontalGrid as Grid } from '../HorizontalGrid';

const useStyles = makeStyles({
  button: {
    width: '100%',
  },
});
export interface PopoverContentCheckboxProps<T> {
  value: T[];
  options: { label: string; value: T }[];
  onApply: (options: T[]) => void;
}

export default function PopoverContentCheckbox<T>({
  value,
  options,
  onApply,
}: PopoverContentCheckboxProps<T>) {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState<T[]>(value ?? []);

  const toggleOption = useCallback(
    (value: T) => {
      if (selectedValues.includes(value)) {
        setSelectedValues(selectedValues.filter((o) => o !== value));
      } else {
        setSelectedValues([...selectedValues, value]);
      }
    },
    [selectedValues],
  );

  const handleClean = useCallback(() => {
    setSelectedValues([]);
  }, []);

  const handleApply = useCallback(() => {
    onApply(selectedValues);
  }, [onApply, selectedValues]);

  const countOptionsSelected = selectedValues.length;

  const onSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedValues(options.map((o) => o.value));
      } else {
        setSelectedValues([]);
      }
    },
    [options],
  );

  const indeterminate =
    countOptionsSelected > 0 && countOptionsSelected < options.length;
  return (
    <Grid container spacing={2}>
      {options.map((option, index) => {
        const checked = selectedValues.includes(option.value);
        return (
          <Grid item xs={12} sm={6} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => toggleOption(option.value)}
                />
              }
              label={option.label}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Divider />
        <FormControlLabel
          control={
            <Checkbox
              color={!indeterminate ? 'primary' : undefined}
              indeterminate={indeterminate}
              checked={
                options.length > 0 && countOptionsSelected === options.length
              }
              onChange={onSelectAllClick}
            />
          }
          label="Seleccionar todo"
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={handleClean}
          className={classes.button}
        >
          Limpiar
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleApply}
          className={classes.button}
        >
          Aplicar
        </Button>
      </Grid>
    </Grid>
  );
}
