import React, { useState, useCallback } from 'react';
import { IconButton, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import xlsx, { WorkBook } from 'xlsx';
import { useDropzone } from 'react-dropzone';
import { ExcelIcon } from './icons/ExcelIcon';
import { PlusIcon } from './icons/PlusIcon';
import { GarbageIcon } from './icons/GarbageIcon';
import { HorizontalGrid as Grid } from './HorizontalGrid';

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    textAlign: 'center',
    border: '1px dashed #CACACA',
    borderRadius: 4,
    '& svg': {
      marginRight: theme.spacing(),
    },
  },
  fileItem: {
    display: 'flex',
    color: theme.palette.primary.main,
    backgroundColor: '#EBEBEB',
    padding: theme.spacing(2),
  },
  filename: {
    flex: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

interface DropExceptBoxProps {
  message: string;
  onDrop: (data: unknown[]) => void;
  onRemove: Function;
}

export const DropExcelBox = ({
  message,
  onDrop,
  onRemove,
}: DropExceptBoxProps) => {
  const classes = useStyles();
  const [filename, setFilename] = useState<string | undefined>();

  const trimAndLowerCaseColumns = (rows: any[]) => {
    return rows.map((column: any) => {
      return Object.entries(column).reduce((obj: any,[columnName, value]: [string,  any]) => {
        obj[columnName.trim().toLowerCase()] =
          typeof value === 'string' ? value.trim() : value;
          return obj;
      }, {});
    });
  };

  const onInnerDrop = useCallback(
    (files: File[]) => {
      if (files.length && /\.xlsx$/.test(files[0].name)) {
        const reader = new FileReader();

        reader.onload = () => {
          const data = new Uint8Array(reader.result as Buffer);
          const wb: WorkBook = xlsx.read(data, { type: 'array' });
          const jsonData = xlsx.utils.sheet_to_json(
            wb.Sheets[wb.SheetNames[0]],
          );

          setFilename(files[0].name);
          onDrop(trimAndLowerCaseColumns(jsonData));
        };

        reader.readAsArrayBuffer(files[0]);
      }
    },
    [onDrop],
  );

  const onInnerRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onRemove(e);
      setFilename(undefined);
    },
    [onRemove],
  );

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: onInnerDrop,
  });
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <div
          aria-label="drop file"
          className={classes.inputContainer}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <PlusIcon color="primary" />
          <p>{message}</p>
        </div>
      </Grid>
      {filename && (
        <Grid item style={{ paddingTop: 10 }}>
          <div className={classes.fileItem}>
            <Box fontSize={18}>
              <ExcelIcon fontSize="inherit" color="primary" />
            </Box>
            <div className={classes.filename}>{filename}</div>
            <IconButton
              aria-label="remove file"
              onClick={onInnerRemove}
              size="small"
            >
              <GarbageIcon fontSize="inherit" color="primary" />
            </IconButton>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
