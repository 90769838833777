import React, { useCallback, FormEvent } from 'react';
import { Dialog, DialogProps, PaperProps } from '@material-ui/core';

export type FunctionOnSubmit = (
  e?: React.FormEvent<HTMLFormElement> | undefined,
) => void;

export type FormDialogProps = Omit<DialogProps, 'onSubmit'> & {
  onSubmit: FunctionOnSubmit;
};

export function FormDialog({
  onSubmit,
  PaperProps: PaperPropsParent,
  ...props
}: FormDialogProps) {
  const paperProps = PaperPropsParent ?? {};
  const newPaperProps = {
    component: 'form',
    onSubmit: useCallback(
      (evt: FormEvent<HTMLFormElement>) => {
        if (evt) {
          evt.preventDefault();
        }
        return onSubmit(evt);
      },
      [onSubmit],
    ),
  } as PaperProps & { onSubmit: FunctionOnSubmit };
  return <Dialog {...props} PaperProps={{ ...paperProps, ...newPaperProps }} />;
}
