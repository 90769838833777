import React, { useState, useCallback } from 'react';
import {
  default as MUIAppBar,
  AppBarProps as MUIAppBarProps,
} from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ToolBar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { UserIcon } from './icons/UserIcon';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { Box } from '@material-ui/core';

type AppBarProps = MUIAppBarProps & {
  userName: string;
  onLogout: (e: React.MouseEvent) => void;
  onMenuClick: (e: any) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: '#FFFFFF',
    color: `${theme.palette.primary.main}`,
  },
  buttonBar: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonUser: {
    color: 'inherit',
    textTransform: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  menuItem: { padding: '16px 8px', justifyContent: 'center' },
}));

const AppBar: React.FunctionComponent<AppBarProps> = ({
  userName,
  onLogout,
  onMenuClick,
  ...rest
}: AppBarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const onChangePassword = useCallback(() => {
    setOpenChangePassword(true);
    setAnchorEl(null);
  }, []);
  const onChangePasswordClose = useCallback(() => {
    setOpenChangePassword(false);
  }, []);

  return (
    <>
      <MUIAppBar className={classes.appBar} {...rest}>
        <ToolBar>
          <IconButton
            edge="start"
            className={classes.buttonBar}
            onClick={onMenuClick}
          >
            <MenuIcon titleAccess="menu-button" />
          </IconButton>
          <div className={classes.grow}></div>
          <Box color="primary.main" fontSize={28}>
            |
          </Box>
          <div data-testid="btn-logout">
            <Button
              id="dropdown-menu-user"
              className={classes.buttonUser}
              startIcon={<ExpandMore />}
              endIcon={<UserIcon />}
              onClick={onClick}
            >
              {userName}
            </Button>
            <Menu
              data-testid="dropdown-menu-user"
              open={open}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              PaperProps={{
                elevation: 1,
                style: {
                  paddingLeft: 24,
                  paddingRight: 24,
                },
              }}
              onClose={onClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                id="btn-change-password"
                onClick={onChangePassword}
                className={classes.menuItem}
                divider
              >
                Cambiar Contraseña
              </MenuItem>
              <MenuItem
                id="btn-logout"
                onClick={onLogout}
                className={classes.menuItem}
              >
                Cerrar Sesión
              </MenuItem>
            </Menu>
          </div>
        </ToolBar>
      </MUIAppBar>
      <ChangePasswordDialog
        open={openChangePassword}
        onClose={onChangePasswordClose}
      />
    </>
  );
};

export default AppBar;
