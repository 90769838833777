import { takeLatest, call, put } from 'redux-saga/effects';
import {
  LIST_CATEGORIES,
  notifyListCategoriesFailed,
  notifyListCategoriesSucceeded,
} from '../actions/categories';
import api from '../../services/api';
import { sessionExpired } from '../actions/auth/session';

export function* listCategoriesSaga() {
  try {
    const { data } = yield call(api.listCategories);
    yield put(notifyListCategoriesSucceeded(data));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      notifyListCategoriesFailed(e.response.status, e.response.data.message),
    );
  }
}

export default function* authRootSaga() {
  yield takeLatest(LIST_CATEGORIES, listCategoriesSaga);
}
