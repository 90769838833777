import {
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React, { useMemo } from 'react';

export interface KpisStatusTableLoadingProps {
  expectedRows: number;
}

const StyledTableCell = withStyles(
  ({ spacing }) =>
    createStyles({
      root: {
        '&:first-child': {
          paddingLeft: spacing(5),
        },
      },
    }),
  {
    name: 'StyledTableCell',
  },
)(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: '100%',
  },
});

export default function KpisStatusTableLoading({
  expectedRows = 5,
}: KpisStatusTableLoadingProps) {
  const classes = useStyles();

  const loadingRows = useMemo(() => [...Array(expectedRows)].map(() => null), [
    expectedRows,
  ]);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableFooter>
        {loadingRows.map((v, i) => (
          <TableRow key={`loading_row_${i}`}>
            <StyledTableCell>
              <Skeleton />
            </StyledTableCell>
          </TableRow>
        ))}
      </TableFooter>
    </Table>
  );
}
