import axios, { AxiosResponse } from 'axios';
import { API_URL } from './constants';
import {
  UserData,
  User,
  UserListItem,
  UsersListFilter,
  UserWithAssignations,
} from '../types/entities/user';
import { Category } from '../types/entities/category';
import { Dimension } from '../types/dimensions';
import {
  KpisStatusDetail,
  KpisStatusGroup,
  KpisStatusDetailUser,
} from '../types/entities/kpis-status';
import { RegistryViewModel } from '../types/entities/registry';

const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const signin = async (
  username: string,
  password: string,
): Promise<AxiosResponse> => {
  return axiosApi.post(`auth/signin`, { username, password });
};

const me = async (): Promise<AxiosResponse> => {
  return axiosApi.get(`auth/me`);
};

const signout = async (): Promise<AxiosResponse> => {
  return axiosApi.get(`auth/signout`);
};

const checkSetUpAccount = async (checkData: {
  token: string;
}): Promise<AxiosResponse> => {
  return axiosApi.post(`users/check`, checkData);
};

const setUpAccount = async (setUpAccountData: {
  username: string;
  password: string;
  token: string;
}): Promise<AxiosResponse> => {
  return axiosApi.post(`users/accept`, setUpAccountData);
};

const changePassword = async (data: {
  old_password: string;
  password: string;
}): Promise<AxiosResponse> => {
  return axiosApi.post(`users/password`, data);
};

const getKpiTemplate = async (
  userId: number,
  brandId: number,
  countryId: number,
): Promise<AxiosResponse> => {
  return axiosApi.get(`registries/${userId}/templates/load-kpi`, {
    params: { brand_id: brandId, country_id: countryId },
    responseType: 'blob',
    headers: {
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });
};

const listBrandsByUserAndCountry = async (
  userId: number,
  countryId: number,
): Promise<AxiosResponse> => {
  return axiosApi.get(`countries/${countryId}/brands`);
};

const listCountriesByUser = async (userId?: number): Promise<AxiosResponse> => {
  return axiosApi.get(`countries`);
};

const listBrandsByUser = async (userId?: number): Promise<AxiosResponse> => {
  return axiosApi.get(`brands`);
};

const listKpisStatus = async (params?: {
  id_country?: number;
  id_brand?: number;
  year: number;
  trimester: number;
}): Promise<AxiosResponse> => {
  return axiosApi.get<KpisStatusGroup[]>(
    `${API_URL as string}/registries/status`,
    { params },
  );
};

const listKpisStatusDetails = async (params: {
  id_country: number;
  id_brand: number;
  year: number;
  month: number;
}): Promise<AxiosResponse<KpisStatusDetail[]>> => {
  return axiosApi.get<KpisStatusDetail[]>(
    `${API_URL as string}/registries/status/details`,
    {
      params,
    },
  );
};

const listKpisStatusDetailsUser = async (params: {
  id_country: number;
  id_brand: number;
  year: number;
  month: number;
  id_user: number;
}): Promise<AxiosResponse<KpisStatusDetailUser[]>> => {
  return axiosApi.get<KpisStatusDetailUser[]>(
    `${API_URL as string}/registries/status/details-user`,
    {
      params,
    },
  );
};

const loadKpisByUser = async (data: {
  month: number;
  year: number;
  id_brand: number;
  id_country: number;
  kpis: { id_kpi: string; value: string }[];
  confirmed?: boolean;
}): Promise<AxiosResponse> => {
  return axiosApi.post(`registries`, data);
};

const getVisualizationAccessToken = async (): Promise<any> => {
  return axiosApi
    .post(`visualizations/auth/token`)
    .then((res: AxiosResponse) => res.data);
};

const getVisualizationEmbedToken = async (
  accessToken: string,
  visualizationId: string,
  datasetId: string,
): Promise<any> => {
  return axiosApi
    .post(
      `visualizations/${visualizationId}/generatetoken`,
      {
        dataset_id: datasetId,
        token: accessToken,
      },
      { withCredentials: true },
    )
    .then((res: AxiosResponse) => res.data);
};

const listVisualizationByUser = async (): Promise<any[]> => {
  return axiosApi
    .get(`visualizations`, { withCredentials: true })
    .then((res: AxiosResponse) => res.data);
};

const getVisualizationInfo = async (
  accessToken: string,
  visualizationId: string,
): Promise<any> => {
  return axiosApi
    .post(`visualizations/${visualizationId}`, { token: accessToken })
    .then((res: AxiosResponse) => res.data)
    .catch((error) => Promise.reject(error.response));
};

const createUser = async (data: UserData): Promise<AxiosResponse<User>> => {
  return axiosApi
    .post('users', data)
    .catch((error) => Promise.reject(error.response));
};

const updateUser = async (
  data: UserData,
  userId: number | null,
): Promise<AxiosResponse<User>> => {
  return axiosApi
    .patch(`users/${userId}`, data)
    .catch((error) => Promise.reject(error.response));
};

const saveUser = async (
  data: UserData,
  userId: number | null,
): Promise<AxiosResponse<User>> => {
  if (userId) {
    return updateUser(data, userId);
  }
  return createUser(data);
};

const listUsers = async (
  params: UsersListFilter,
): Promise<AxiosResponse<{ data: UserListItem[]; pages: number }>> => {
  return axiosApi.get('users', { params });
};

const updateUserActive = async (
  active: boolean,
  id_user: number,
): Promise<AxiosResponse<User>> => {
  return axiosApi.post('users/active', { active, id_user });
};

const listCategories = async (): Promise<AxiosResponse<Category[]>> => {
  return axiosApi.get('categories');
};

const listDimensions = async (): Promise<AxiosResponse<Dimension[]>> => {
  return axiosApi.get('dimensions');
};

const listKpis = async ({
  country,
  brand,
  month,
  year,
}: {
  country: number;
  brand: number;
  month: number;
  year: number;
}): Promise<AxiosResponse<RegistryViewModel[]>> => {
  return axiosApi.get(
    `/registries?country_id=${country}&brand_id=${brand}&month=${month}&year=${year}`,
  );
};

const getUser = async (
  id: number,
): Promise<AxiosResponse<UserWithAssignations>> => {
  return axiosApi.get(`/users/${id}`);
};

export default {
  signin,
  signout,
  me,
  saveUser,
  listUsers,
  updateUserActive,
  checkSetUpAccount,
  setUpAccount,
  changePassword,
  getKpiTemplate,
  listBrandsByUserAndCountry,
  listCountriesByUser,
  listBrandsByUser,
  listKpisStatus,
  listKpisStatusDetails,
  listKpisStatusDetailsUser,
  loadKpisByUser,
  getVisualizationAccessToken,
  getVisualizationEmbedToken,
  listVisualizationByUser,
  getVisualizationInfo,
  listCategories,
  listDimensions,
  listKpis,
  getUser,
};
