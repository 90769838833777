import { useState, useEffect } from 'react';
import { useDependentLoader } from './useDependentLoader';
import { LoaderSelector, GetFromStore, IsValueInOptions } from './types';

/**
 * Hook to handle select's options loaded from store.
 * It also listen changes on a parent value to trigger the action again.
 *
 */
export function useDependentLoaderSelector<T, P>(
  parent: P | null,
  getFromStore: GetFromStore<T>,
  listAction: (parent: P) => any,
  isValueInOptions: IsValueInOptions<T>,
  initialValue?: string,
): LoaderSelector<T> {
  const [value, setValue] = useState<string>(initialValue ?? '');
  const [disable, setDisable] = useState(!parent);

  const { options, loading } = useDependentLoader<T, P>(
    parent,
    getFromStore,
    listAction,
  );

  useEffect(() => {
    if (parent) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [parent]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!options) {
      setValue('');
    } else if (!isValueInOptions(value, options)) {
      setValue('');
    }
  }, [options, loading, value, isValueInOptions]);

  return { options, value, setValue, loading, disable };
}
