import React, { useState, useCallback, useEffect, useContext } from 'react';

import _ from 'lodash';
import {
  DialogContent,
  TextField,
  DialogTitle,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  FormHelperText,
  Box,
  CircularProgress,
  Select,
  InputLabel,
  TableContainer,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { UserDetailIcon } from '../../components/icons/UserDetailIcon';
import api from '../../services/api';
import { UserData, UserAssignedKpi } from '../../types/entities/user';
import { SuccessFeedback } from '../../components/feedback/SuccessFeedback';
import { FailFeedback } from '../../components/feedback/FailFeedback';
import { useTimeout } from '../../hooks/useTimeout';
import { HorizontalGrid as Grid } from '../../components/HorizontalGrid';
import { FormDialog } from '../../components/FormDialog';
import { useSelector } from 'react-redux';
import { Country } from '../../types/entities/country';
import { Category } from '../../types/entities/category';
import { useLoader } from '../../hooks/loaders/useLoader';
import { UserAssignedKpisTable } from './components/UserAssignedKpis/UserAssignedKpisTable';
import { UserAssignedKpisModify } from './components/UserAssignedKpis/UserAssignedKpisModify';
import { Dimension } from '../../types/dimensions';
import { listDimensionsAction } from '../../store/actions/dimensions';
import { UserVisualizationAssigner } from './components/UserVisualizationAssigner';
import { ViewerAssignment } from '../../types/entities/viewer-assignments';
import { RolesEnum } from '../../types/enums/roles';
import { UserVisualizationTable } from './components/UserVisualizationTable';
import { isEmail } from '../../services/email-validation';
import { TextSkeleton } from '../../components/feedback/TextSkeleton';
import { ConfirmationDialog } from '../../components/feedback/ConfirmationDialog';
import { UsersListFiltersContext } from './contexts/UsersListFiltersContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 800,
  },
  checkboxes: {
    '& .MuiFormHelperText-root': {
      height: 20,
    },
  },
  formControl: {
    flex: 1,
  },
}));

const inputsBaseState = {
  first_name: '',
  last_name: '',
  email: '',
  category: '',
  country: '',
};
const inputsErrorsBaseState = {
  first_name: '',
  last_name: '',
  email: '',
  category: '',
  country: '',
};
const allCheckboxesDisabled = {
  adminFCA: false,
  adminDYU: false,
  editor: false,
  viewer: false,
};

const allCheckboxesAvailable = {
  adminFCA: true,
  adminDYU: true,
  editor: true,
  viewer: true,
};

enum UserModalSteps {
  CREATE = 'create',
  SUCCESS = 'success',
  LOADING = 'loading',
  FAIL = 'fail',
}

interface UserInputs {
  first_name: string;
  last_name: string;
  email: string;
  category: string;
  country: string;
}

interface RolesCheckboxes {
  adminFCA: boolean;
  adminDYU: boolean;
  editor: boolean;
  viewer: boolean;
}

interface UserModalProps {
  open: boolean;
  userId: number | null;
  enableEdition: boolean;
  onClose: () => void;
}

const UserModal = ({
  open,
  userId,
  onClose,
  enableEdition = false,
}: UserModalProps) => {
  const classes = useStyles();

  const defaultErrorMessage = 'Ups, ha ocurrido un problema inesperado';
  const [modalErrorMessage, setModalErrorMessage] = useState(
    defaultErrorMessage,
  );
  const [inputs, setInputs] = useState(inputsBaseState);
  const [inputsErrors, setInputsErrors] = useState(inputsErrorsBaseState);
  const [checkboxes, setCheckboxes] = useState(allCheckboxesDisabled);
  const [checkboxesAvailable, setCheckboxesAvailability] = useState(
    allCheckboxesAvailable,
  );
  const [roles, setRoles] = useState<(number | undefined)[]>(new Array(4));
  const [isCheckboxDirty, setCheckboxDirty] = useState(false);
  const [step, setStep] = useState(UserModalSteps.CREATE);

  const [openVisualizations, setOpenVisualizations] = useState(false);

  const { countries, categories } = useSelector((state: any) => ({
    countries: state.countries.data as Country[],
    categories: state.categories.data as Category[],
  }));

  const [assignedKpis, setAssignedKpis] = useState<UserAssignedKpi[]>([]);
  const [openAssignementsKpis, setOpenAssignementsKpis] = useState(false);
  const { options: dimensions, loading: loadingDimensions } = useLoader(
    (store: any) => ({
      options: store.dimensions.data as Dimension[],
      loading: store.dimensions.loading,
    }),
    useCallback(() => listDimensionsAction(), []),
  );

  const [visualizations, setVisualizations] = useState<ViewerAssignment[]>([]);
  const visualizationsTableHeaders = [
    { name: 'Pais', dataKey: 'name_country' },
    { name: 'Marca', dataKey: 'name_brand' },
  ];

  const resetStates = useCallback(() => {
    setInputs({ ...inputsBaseState });
    setInputsErrors({ ...inputsErrorsBaseState });
    setCheckboxes({ ...allCheckboxesDisabled });
    setCheckboxDirty(false);
    setModalErrorMessage(defaultErrorMessage);
    setCheckboxesAvailability(allCheckboxesAvailable);
    setRoles(new Array(4));
    setAssignedKpis([]);
    setStep(UserModalSteps.CREATE);
    setVisualizations([]);
  }, []);

  const cancel = useCallback(() => {
    onClose();
    setConfirmDialog(false);
    resetStates();
  }, [onClose, resetStates]);

  const updateInputs = (key: string, value: unknown) => {
    setInputs({ ...inputs, [key]: value });
    let errors = {
      ...inputsErrors,
      [key]: Boolean(value) ? '' : 'Requerido',
    };
    if (key === 'email' && value) {
      errors.email = isEmail(value as string) ? '' : 'No es un email válido';
    }
    setInputsErrors(errors);
  };

  const disableIncompatibleRoles = (key: string, checked: boolean) => {
    if (['adminFCA', 'adminDYU'].includes(key)) {
      setCheckboxesAvailability(
        checked
          ? { ...allCheckboxesDisabled, [key]: true }
          : { ...allCheckboxesAvailable },
      );
    } else {
      if (checked) {
        setCheckboxesAvailability({
          ...checkboxesAvailable,
          adminFCA: false,
          adminDYU: false,
        });
      } else if (!checked && !(checkboxes.editor && checkboxes.viewer)) {
        setCheckboxesAvailability({ ...allCheckboxesAvailable });
      }
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
  ) => {
    updateInputs(e.target.name as string, e.target.value);
  };

  const handleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const newRoles = roles.slice();
    const parseValue = parseInt(e.target.value);
    newRoles[parseValue - 1] = checked ? parseValue : undefined;

    setCheckboxes({ ...checkboxes, [e.target.name]: checked });
    disableIncompatibleRoles(e.target.name, checked);
    setRoles(newRoles);
    setCheckboxDirty(true);
  };

  const roleError = !Object.values(checkboxes).reduce(
    (accumulate: boolean, prevValue: boolean) => accumulate || prevValue,
  );

  const formError =
    roleError || Object.values(inputsErrors).some((i) => Boolean(i));

  const backToCreate = () => {
    setStep(UserModalSteps.CREATE);
  };

  const [startRetryTimeout] = useTimeout(backToCreate);
  const [startCloseTimeout] = useTimeout(cancel);

  const { goToPage } = useContext(UsersListFiltersContext);

  const saveUser = () => {
    const data: UserData = buildUserData(
      inputs,
      roles,
      assignedKpis,
      visualizations,
    );

    setStep(UserModalSteps.LOADING);

    api
      .saveUser(data, userId)
      .then(() => {
        goToPage(1);
        setStep(UserModalSteps.SUCCESS);
        startCloseTimeout(2000);
      })
      .catch((response) => {
        if (!response) {
          setModalErrorMessage('No hay conexión a internet');
        } else if (response.status === 409) {
          if (response.message === 'KPIs already assigned') {
            setModalErrorMessage(
              'Ya existe un usuario con alguno de los KPIs assignados',
            );
          } else {
            setModalErrorMessage('Ya existe un usuario con el mismo correo');
          }
        }

        setStep(UserModalSteps.FAIL);
        startRetryTimeout(2000);
      });
  };

  const [defaultCountryId, setDefaultCountryId] = useState<number | undefined>(
    undefined,
  );
  const [defaultBrandId, setDefaultBrandId] = useState<number | undefined>(
    undefined,
  );
  const openAssignedKpisDialog = useCallback(
    (id_country?: number, id_brand?: number) => {
      setDefaultCountryId(id_country);
      setDefaultBrandId(id_brand);
      setOpenAssignementsKpis(true);
    },
    [],
  );
  const removeAssignedCountryAndBrand = useCallback(
    (id_country: number, id_brand: number) => {
      const filteredAssignedKpis = assignedKpis.filter(
        (a) => a.id_country !== id_country || a.id_brand !== id_brand,
      );
      setAssignedKpis(filteredAssignedKpis);
    },
    [assignedKpis],
  );

  const onConfirmVisualization = (value: ViewerAssignment) => {
    setVisualizations([...visualizations, value]);
    setOpenVisualizations(false);
  };

  const onDeleteVisualization = (value: ViewerAssignment) => {
    setVisualizations(
      visualizations.filter(
        (visualization) =>
          visualization.id_country !== value.id_country &&
          visualization.id_brand !== value.id_brand,
      ),
    );
  };

  const [loadingUser, setLoadingUser] = useState(false);
  const [originalAssignedKpis, setOriginalAssignedKpis] = useState<
    UserAssignedKpi[]
  >([]);
  const [kpiNamesDiscarded, setKpiNamesDiscarded] = useState<string[]>([]);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    if (enableEdition && userId) {
      setLoadingUser(true);

      api
        .getUser(userId)
        .then((response) => {
          const {
            roles,
            assigned_kpis,
            assigned_visualizations,
            ...rest
          } = _.omit(response.data, ['created_at', 'modified_at', 'id']);
          const checkboxes = mapRolesToCheckboxes(roles);

          setInputs(parseInputs(rest));
          setInputsErrors(calculateInputsErrors(rest));
          setRoles(parseRoles(roles));
          setCheckboxes(checkboxes);
          setCheckboxesAvailability(calculateAvailability(checkboxes));
          setCheckboxDirty(true);

          setAssignedKpis(assigned_kpis);
          setOriginalAssignedKpis(assigned_kpis);
          setVisualizations(assigned_visualizations);
        })
        .finally(() => {
          setLoadingUser(false);
        });
    }
  }, [userId, enableEdition]);

  const confirm = () => {
    if (!enableEdition) {
      saveUser();
    } else {
      if (!checkboxes.editor) {
        setKpiNamesDiscarded(originalAssignedKpis.map((kpi) => kpi.name_kpi));
      } else {
        const names = getKpiNamesDiscarded(originalAssignedKpis, assignedKpis);
        setKpiNamesDiscarded(names);
      }
      setConfirmDialog(true);
    }
  };

  return (
    <FormDialog
      open={open}
      onClose={cancel}
      onSubmit={confirm}
      PaperProps={{ className: classes.root }}
    >
      {step === UserModalSteps.CREATE && (
        <React.Fragment>
          <DialogTitle disableTypography>
            <UserDetailIcon color="primary" />
            {userId ? 'Modificar' : 'Ingreso nuevo'} usuario
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="h2">Datos personales</DialogContentText>
            <Grid container spacing={3}>
              <Grid container item xs={12} md={6}>
                {!loadingUser ? (
                  <TextField
                    label="Nombre"
                    inputProps={{
                      'aria-label': 'nombre',
                    }}
                    onChange={handleInputChange}
                    name="first_name"
                    value={inputs.first_name}
                    error={Boolean(inputsErrors.first_name)}
                    helperText={inputsErrors.first_name}
                    autoComplete="given-name"
                    required
                  />
                ) : (
                  <TextSkeleton />
                )}
              </Grid>
              <Grid container item xs={12} md={6}>
                {!loadingUser ? (
                  <TextField
                    label="Apellido"
                    inputProps={{
                      'aria-label': 'apellido',
                    }}
                    onChange={handleInputChange}
                    name="last_name"
                    value={inputs.last_name}
                    error={Boolean(inputsErrors.last_name)}
                    helperText={inputsErrors.last_name}
                    autoComplete="family-name"
                    required
                  />
                ) : (
                  <TextSkeleton />
                )}
              </Grid>
              <Grid container item xs={12}>
                {!loadingUser ? (
                  <TextField
                    label="Correo"
                    inputProps={{
                      'aria-label': 'correo',
                    }}
                    onChange={handleInputChange}
                    name="email"
                    value={inputs.email}
                    error={Boolean(inputsErrors.email)}
                    helperText={inputsErrors.email}
                    type="email"
                    disabled={enableEdition}
                    required
                  />
                ) : (
                  <TextSkeleton />
                )}
              </Grid>
              <Grid container item xs={12} md={6}>
                {!loadingUser ? (
                  <FormControl
                    required
                    className={classes.formControl}
                    error={Boolean(inputsErrors.category)}
                  >
                    <InputLabel id="create-user-category-label">
                      Categoría
                    </InputLabel>
                    <Select
                      native
                      labelId="create-user-category-label"
                      inputProps={{
                        'aria-label': 'categoria',
                      }}
                      name="category"
                      value={inputs.category}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" aria-label="None" />
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextSkeleton />
                )}
              </Grid>
              <Grid container item xs={12} md={6}>
                {!loadingUser ? (
                  <FormControl
                    required
                    className={classes.formControl}
                    error={Boolean(inputsErrors.country)}
                  >
                    <InputLabel id="create-user-country-label">País</InputLabel>
                    <Select
                      native
                      labelId="create-user-country-label"
                      inputProps={{
                        'aria-label': 'pais',
                      }}
                      name="country"
                      value={inputs.country}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" aria-label="None" />
                      {countries.map((country) => (
                        <option
                          key={country.id_country}
                          value={country.id_country}
                        >
                          {country.name_country}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextSkeleton />
                )}
              </Grid>
            </Grid>
          </DialogContent>

          {/* Role's checkboxes */}

          <DialogContent>
            <DialogContentText variant="h2">Tipo</DialogContentText>
            <FormControl
              className={classes.checkboxes}
              error={roleError}
              required
            >
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="adminFCA"
                      color="primary"
                      checked={checkboxes.adminFCA}
                      value="1"
                      onChange={handleCheckbox}
                      disabled={!checkboxesAvailable.adminFCA || loadingUser}
                    />
                  }
                  label="Administrador Stellantis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="adminDYU"
                      color="primary"
                      checked={checkboxes.adminDYU}
                      value="2"
                      onChange={handleCheckbox}
                      disabled={!checkboxesAvailable.adminDYU || loadingUser}
                    />
                  }
                  label="Administrador DYU"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="editor"
                      color="primary"
                      checked={checkboxes.editor}
                      value="3"
                      onChange={handleCheckbox}
                      disabled={!checkboxesAvailable.editor || loadingUser}
                    />
                  }
                  label="Editor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="viewer"
                      color="primary"
                      checked={checkboxes.viewer}
                      value="4"
                      onChange={handleCheckbox}
                      disabled={!checkboxesAvailable.viewer || loadingUser}
                    />
                  }
                  label="Visualizador"
                />
              </FormGroup>
              {roleError && isCheckboxDirty && (
                <FormHelperText>Al menos un tipo es requerido</FormHelperText>
              )}
            </FormControl>
          </DialogContent>

          {/* Admin message */}

          {(checkboxes.adminFCA || checkboxes.adminDYU) && (
            <DialogContent>
              <Typography variant="body2">
                Los administradores pueden cargar valores a cualquier KPI y ver
                la información de todos los países y marcas.
              </Typography>
            </DialogContent>
          )}

          {/* Editor's Assignments */}

          {checkboxes.editor && !loadingDimensions ? (
            <DialogContent>
              <Box display="flex" alignItems="baseline">
                <Box flexGrow={1}>
                  <DialogContentText variant="h2">
                    Permisos Editor
                  </DialogContentText>
                </Box>
                <Box mb={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => openAssignedKpisDialog()}
                  >
                    Asignar KPI
                  </Button>
                </Box>
              </Box>
              <TableContainer>
                <UserAssignedKpisTable
                  assignedKpis={assignedKpis}
                  dimensions={dimensions}
                  onEditClick={openAssignedKpisDialog}
                  onDeleteClick={removeAssignedCountryAndBrand}
                />
              </TableContainer>
              {openAssignementsKpis && (
                <UserAssignedKpisModify
                  open
                  defaultCountryId={defaultCountryId}
                  defaultBrandId={defaultBrandId}
                  assignedKpis={assignedKpis}
                  dimensions={dimensions}
                  userId={userId}
                  onCancel={() => {
                    setOpenAssignementsKpis(false);
                  }}
                  onConfirm={(a) => {
                    setAssignedKpis(a);
                    setOpenAssignementsKpis(false);
                  }}
                />
              )}
            </DialogContent>
          ) : checkboxes.editor ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : null}

          {/* Viewer's Permissions */}

          {checkboxes.viewer && (
            <DialogContent>
              <Box display="flex" alignItems="baseline">
                <Box flexGrow={1}>
                  <DialogContentText variant="h2">
                    Permisos Visualizador
                  </DialogContentText>
                </Box>
                <Box mb={3}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpenVisualizations(true)}
                  >
                    Agregar visualizacion
                  </Button>
                </Box>
              </Box>
              <UserVisualizationAssigner
                open={openVisualizations}
                assignments={visualizations}
                countries={countries}
                onConfirm={onConfirmVisualization}
                onCancel={() => {
                  setOpenVisualizations(false);
                }}
              />
              <UserVisualizationTable
                headers={visualizationsTableHeaders}
                data={visualizations}
                noDataMessage="No hay visualizaciones asignadas"
                onDelete={onDeleteVisualization}
              />
            </DialogContent>
          )}

          {/* Actions */}

          <DialogActions className="big-top-space">
            <Button
              name="cancel"
              variant="contained"
              color="secondary"
              aria-label="cancel user creation"
              onClick={cancel}
            >
              Cancelar
            </Button>
            <Button
              name="save"
              variant="contained"
              color="primary"
              aria-label="create user"
              type="submit"
              disabled={formError}
            >
              Guardar
            </Button>
          </DialogActions>

          {confirmDialog && (
            <ConfirmationDialog
              onConfirm={saveUser}
              onCancel={() => setConfirmDialog(false)}
            >
              {kpiNamesDiscarded.length > 0 ? (
                <>
                  <DialogContentText variant="h2">
                    Los siguientes KPIs serán liberados:
                  </DialogContentText>
                  <Box style={{ maxHeight: 200, overflowY: 'auto' }}>
                    {kpiNamesDiscarded.map((kpiName, index) => (
                      <Box key={`kpi-name-${index}`}>
                        {kpiName.toUpperCase()}
                      </Box>
                    ))}
                  </Box>
                </>
              ) : null}
            </ConfirmationDialog>
          )}
        </React.Fragment>
      )}

      {/* Feedback */}

      {step === UserModalSteps.LOADING && (
        <DialogContent>
          <Box textAlign="center">
            <CircularProgress style={{ margin: 'auto' }} />
          </Box>
        </DialogContent>
      )}
      {step === UserModalSteps.SUCCESS && (
        <DialogContent>
          <SuccessFeedback
            title={`¡Usuario ${
              enableEdition ? 'actualizado' : 'creado'
            } con éxito!`}
            subtitle="Puedes ir al listado de usuarios y revisar todos los detalles"
          />
        </DialogContent>
      )}
      {step === UserModalSteps.FAIL && (
        <DialogContent>
          <FailFeedback title={modalErrorMessage} />
        </DialogContent>
      )}
    </FormDialog>
  );
};

// Component utilities

function buildUserData(
  inputs: UserInputs,
  rawRoles: (number | undefined)[],
  rawAssignedKpis: UserAssignedKpi[],
  rawVisualizations: ViewerAssignment[],
): UserData {
  return {
    first_name: inputs.first_name.trim(),
    last_name: inputs.last_name.trim(),
    email: inputs.email.trim().toLowerCase(),
    country: parseInt(inputs.country),
    category: parseInt(inputs.category),
    roles: (rawRoles.filter((value: number | undefined) =>
      Boolean(value),
    ) as number[]).map((value: number) => ({
      id: value,
    })),
    assigned_kpis: rawAssignedKpis.map((value) => ({
      id_country: value.id_country,
      id_brand: value.id_brand,
      id_kpi: value.id_kpi,
    })),
    assigned_visualizations: rawRoles.includes(RolesEnum.VIEWER)
      ? rawVisualizations.map(({ id_country, id_brand }) => ({
          id_country,
          id_brand,
        }))
      : [],
  };
}

function parseKeyValues(
  src: any,
  parseKeyValue: (value: any) => string,
): UserInputs {
  const dest: any = {};

  Object.keys(src).forEach((key) => {
    dest[key] = parseKeyValue(src[key]);
  });

  return dest;
}

function parseInputs(obj: any) {
  return parseKeyValues(obj, (value) => (value ? value.toString() : ''));
}

function calculateInputsErrors(obj: any) {
  return parseKeyValues(obj, (value) => (value ? '' : 'Requerido'));
}

function parseRoles(src: { id: number }[]): Array<number | undefined> {
  const dest = [...new Array(4)];
  const ids = _.flatMap(src, (value) => value.id);

  ids.sort();
  dest.forEach((_, index) => {
    dest[index] = ids.includes(index + 1) ? index + 1 : undefined;
  });

  return dest;
}

function mapRolesToCheckboxes(roles: { id: number }[]): RolesCheckboxes {
  const map: {
    [key: number]: 'adminFCA' | 'adminDYU' | 'editor' | 'viewer';
  } = {
    1: 'adminFCA',
    2: 'adminDYU',
    3: 'editor',
    4: 'viewer',
  };

  const checkboxes = { ...allCheckboxesDisabled };

  roles.forEach((role: { id: number }) => {
    checkboxes[map[role.id]] = true;
  });

  return checkboxes;
}

function calculateAvailability(checkboxes: RolesCheckboxes): RolesCheckboxes {
  const availability = { ...allCheckboxesAvailable };
  if (checkboxes.adminFCA) {
    availability.editor = false;
    availability.viewer = false;
    availability.adminDYU = false;
  } else if (checkboxes.adminDYU) {
    availability.editor = false;
    availability.viewer = false;
    availability.adminFCA = false;
  } else if (checkboxes.editor || checkboxes.viewer) {
    availability.adminFCA = false;
    availability.adminDYU = false;
  }

  return availability;
}

/**
 *
 *  Revisa si existen KPIs originales que no esten contenidos en los KPIs actuales
 *  Para comparar si son iguales debe comparar los ids de country, brand y kpi
 *  Porque es la combinacion de ids lo que hace a una asignacion unica.
 *
 * @param original Son los kpis assignados en base de datos
 * @param current Son los kpis actuales producto de la creacion, edition o eliminacion en el modal
 *
 * @Return Una lista con los nombres de los KPIs que el usuario antes de la actualizacion
 *        tenia asignados y ahora seran desvinculados
 */
function getKpiNamesDiscarded(
  original: UserAssignedKpi[],
  current: UserAssignedKpi[],
): string[] {
  const newKpis = new Set(
    current.map((kpi) => `${kpi.id_country}${kpi.id_brand}${kpi.id_kpi}`),
  );
  const kpiNamesDiscarded: string[] = [];

  original.forEach((kpi) => {
    if (!newKpis.has(`${kpi.id_country}${kpi.id_brand}${kpi.id_kpi}`)) {
      kpiNamesDiscarded.push(kpi.name_kpi);
    }
  });

  return kpiNamesDiscarded;
}

export default UserModal;
