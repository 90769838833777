import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LoadingButton, { LoadingButtonProps } from './LoadingButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 4,
    fontFamily: 'Helvetica Neue',
    boxShadow: `0px 1px 9px ${theme.palette.primary.light}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  loading: {
    color: 'white',
    width: '20px !important',
    height: '20px !important',
  },
}));

const PrimaryButton: React.FunctionComponent<LoadingButtonProps> = (
  props: LoadingButtonProps,
) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      className={clsx(classes.root, props.className)}
      loadingClass={classes.loading}
      {...rest}
    ></LoadingButton>
  );
};

export default PrimaryButton;
