import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Signin from './screens/Signin/Signin';
import Home from './screens/Home';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { SetUpAccount } from './screens/SetUpAccount/SetUpAccount';
import { PasswordForgot } from './screens/Password/PasswordForgot';
import { PasswordReset } from './screens/Password/PasswordReset';

function App() {
  return (
    <>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/password/forgot">
            <PasswordForgot />
          </Route>
          <Route exact path="/password/reset">
            <PasswordReset />
          </Route>
          <Route exact path="/setup-account">
            <SetUpAccount />
          </Route>
          <Route exact path="/signin">
            <Signin />
          </Route>
          <PrivateRoute path="/" redirectTo="/signin" checkRoles={false}>
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
    </>
  );
}

export default App;
