import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import ConfirmationDialogActions from '../../../components/ConfirmationDialogActions';
import { FeedbackDense } from '../../../components/feedback/FeedbackDense';
import { InformationIcon } from '../../../components/icons/InformationIcon';
import api from '../../../services/api';
import { sessionExpired } from '../../../store/actions/auth/session';
import { UserListItem } from '../../../types/entities/user';
import { SuccessFeedback } from '../../../components/feedback/SuccessFeedback';
import { useTimeout } from '../../../hooks/useTimeout';
import { FailFeedback } from '../../../components/feedback/FailFeedback';
import { UsersListFiltersContext } from '../contexts/UsersListFiltersContext';

export interface UserUpdateActiveProps {
  user?: UserListItem;
  onReady: (complete: boolean, user?: UserListItem) => void;
}

enum UserActiveSteps {
  DEFAULT = 'default',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export default function UserUpdateActive({
  user,
  onReady,
}: UserUpdateActiveProps) {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [step, setStep] = useState(UserActiveSteps.DEFAULT);

  const { goToPage } = useContext(UsersListFiltersContext);

  const done = useCallback(() => {
    if (user && !user.active) {
      onReady(true, user);
    } else if (user && user.active) {
      onReady(true);
    }
  }, [onReady, user]);

  const [closeOn, cancel] = useTimeout(done);

  const earlyClose = () => {
    if (step !== UserActiveSteps.LOADING) {
      onReady(false);
      cancel();
    }
  };

  const updateActiveState = useCallback(
    (value: boolean) => {
      if (user && value) {
        setStep(UserActiveSteps.LOADING);
        api
          .updateUserActive(!user.active, user.id_user)
          .then(() => {
            goToPage(1);
            setStep(UserActiveSteps.SUCCESS);
            closeOn(2000);
          })
          .catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              dispatch(sessionExpired());
              onReady(false);
            } else {
              setError(
                error.response?.data?.message ??
                  'Ha ocurrido un problema inesperado',
              );
              setStep(UserActiveSteps.ERROR);
            }
          });
      } else if (user && !value) {
        onReady(false);
      }
    },
    [user, dispatch, onReady, closeOn, goToPage],
  );
  const confirm = useMemo(() => {
    if (user && user.active) {
      return {
        messages: {
          title: '¿Estas seguro de desactivar a este usuario?',
          subtitle:
            'Al desactivar un usuario todos los permisos y KPI’s asociados a él quedaran liberados para ser asociados a otro usuario.',
          successTitle: '¡Usuario desactivado con éxito!',
          successSubtitle:
            'Puedes ir al listado de usuarios y revisar todos los detalles',
        },
      };
    } else if (user && !user.active) {
      return {
        messages: {
          title: '¿Estas seguro de activar a este usuario?',
          subtitle:
            'Al activar un usuario podrá acceder a la plataforma pero no podrá hacer nada hasta que se le asigne un rol.',
          successTitle: '¡Usuario activado con éxito!',
          successSubtitle: 'Debes asignarle un rol y permisos',
        },
      };
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      setStep(UserActiveSteps.DEFAULT);
    }
  }, [user]);
  return (
    <>
      <Dialog
        onBackdropClick={earlyClose}
        onEscapeKeyDown={earlyClose}
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={Boolean(confirm)}
      >
        {confirm &&
          ({
            [UserActiveSteps.DEFAULT]: (
              <ConfirmationDialogActions onClose={updateActiveState}>
                <DialogContent>
                  <FeedbackDense
                    startIcon={() => (
                      <InformationIcon color="primary" fontSize="large" />
                    )}
                    title={confirm.messages.title}
                    subtitle={confirm.messages.subtitle}
                  />
                </DialogContent>
              </ConfirmationDialogActions>
            ),
            [UserActiveSteps.LOADING]: (
              <DialogContent>
                <Box textAlign="center">
                  <CircularProgress style={{ margin: 'auto' }} />
                </Box>
              </DialogContent>
            ),
            [UserActiveSteps.ERROR]: (
              <ConfirmationDialogActions onClose={earlyClose}>
                <DialogContent>
                  <FailFeedback
                    title="Ups, ha ocurrido un problema inesperado"
                    subtitle={error}
                  />
                </DialogContent>
                )
              </ConfirmationDialogActions>
            ),
            [UserActiveSteps.SUCCESS]: (
              <DialogContent>
                <SuccessFeedback
                  title={confirm?.messages.successTitle ?? ''}
                  subtitle={confirm?.messages.successSubtitle ?? ''}
                />
              </DialogContent>
            ),
          }[step] as any)}
      </Dialog>
    </>
  );
}
