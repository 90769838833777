import React from 'react';
import {
  GridProps,
  Grid,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      marginBottom: 0,
      '& > $item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    item: {},
  }),
);

export function HorizontalGrid({ className, ...props }: GridProps) {
  const classes = useStyles();
  return (
    <Grid className={clsx(className, classes.root, classes.item)} {...props} />
  );
}
