import { produce, Draft } from 'immer';
import {
  DimensionsAction,
  DimensionsFailedAction,
  DimensionsSuccededAction,
  LIST_DIMENSIONS,
  LIST_DIMENSIONS_SUCCEEDED,
  LIST_DIMENSIONS_FAILED,
} from '../actions/dimensions';
import { Dimension } from '../../types/entities/dimension';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import { ResourceState } from './types';

const initial: ResourceState<Dimension> = {
  data: [],
  loading: false,
};

const applyChanges = produce(
  (
    draftState: Draft<ResourceState<Dimension>>,
    action:
      | DimensionsAction
      | DimensionsSuccededAction
      | DimensionsFailedAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_DIMENSIONS:
        draftState.loading = true;
        break;
      case LIST_DIMENSIONS_SUCCEEDED:
        draftState.loading = false;
        draftState.data = action.payload;
        break;
      case LIST_DIMENSIONS_FAILED:
        draftState.loading = false;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: ResourceState<Dimension> = initial,
  action:
    | DimensionsAction
    | DimensionsSuccededAction
    | DimensionsFailedAction
    | SessionExpiredAction
    | { type?: string },
): ResourceState<Dimension> => {
  return applyChanges(state, action);
};
