import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Snackbar,
  makeStyles,
  Theme,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Box,
  Link,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useHistory, Link as RouterLink, useLocation } from 'react-router-dom';
import { userSignin } from '../../store/actions/auth/signin';
import { PrimaryButton } from '../../components/buttons';
import './Signin.css';
import { BootstrapInput } from '../../components/BootstrapInput';
import { FormDialog } from '../../components/FormDialog';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 35,
    maxWidth: 446,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 510,
    },
  },
  formControl: {
    width: '100%',
  },
  input: {
    marginBottom: 10,
  },
  label: {
    color: '#000000',
    transform: 'translate(0, 1.5px) scale(1)',
  },
  button: {
    margin: 'auto',
    flex: 'unset!important',
    width: 234,
  },
}));

const Signin = () => {
  const location = useLocation<{ badLink?: any }>();
  const isBadLink = Boolean(location.state?.badLink);

  const classes = useStyles();
  const [username, setUsername] = useState(process.env.REACT_APP_USER ?? '');
  const [password, setPassword] = useState(process.env.REACT_APP_PASS ?? '');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { logged, isFetching, httpError } = useSelector(
    (state: any) => state.auth,
    shallowEqual,
  );

  useEffect(() => {
    if (logged) {
      history.push('/');
    }
  }, [logged, history]);

  useEffect(() => {
    if (httpError) {
      setOpen(true);
      setUsername('');
      setPassword('');
    } else {
      setOpen(false);
    }
  }, [httpError]);

  const onClick = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch(userSignin(username, password));
    },
    [dispatch, username, password],
  );

  const onClose = () => setOpen(false);

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <FormDialog
      open
      disableBackdropClick
      className="featured"
      onSubmit={onClick}
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle disableTypography>
        <div className="Signin-logo"></div>
        <div className="Signin-welcome">¡Bienvenido!</div>
      </DialogTitle>
      {isBadLink ? (
        <DialogContent>
          <Alert severity="error">
            El vínculo para configurar la cuenta no es válido o ya se ha
            utilizado.
          </Alert>
        </DialogContent>
      ) : null}
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="input-username" className={classes.label}>
            Correo electrónico
          </InputLabel>
          <BootstrapInput
            className={classes.input}
            id="input-username"
            data-testid="signin-username"
            placeholder="ejemplo@stellantis.com"
            value={username}
            onChange={onUsernameChange}
            required
            autoFocus={true}
          />
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="input-password" className={classes.label}>
            Contraseña
          </InputLabel>
          <BootstrapInput
            className={classes.input}
            type="password"
            id="input-password"
            data-testid="signin-password"
            value={password}
            onChange={onPasswordChange}
            autoComplete="current-password"
            required
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          id="btn-login"
          className={classes.button}
          data-testid="signin-btn-local-account"
          loading={isFetching}
          type="submit"
        >
          INICIA SESIÓN
        </PrimaryButton>
      </DialogActions>
      <DialogContent>
        <Box textAlign="center">
          ¿Olvidaste tu contraseña?{' '}
          <Link
            component={RouterLink}
            to="/password/forgot"
            style={{ fontWeight: 'bold' }}
          >
            Restáurala aquí
          </Link>
        </Box>
      </DialogContent>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert id="alert-login-error" severity="error">
          Usuario o contraseña inválidos
        </Alert>
      </Snackbar>
    </FormDialog>
  );
};

Signin.propTypes = {
  options: PropTypes.shape({
    usernameType: PropTypes.oneOf(['username', 'email']),
    requestEmail: PropTypes.bool,
  }),
};

Signin.defaultProps = {
  options: {
    usernameType: 'username',
    requestEmail: false,
  },
};

export default Signin;
