import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
} from '@material-ui/core';
import { Feedback } from '../../../components/feedback/Feedback';
import { AlertIcon } from '../../../components/icons/AlertIcon';
import { ListLightIcon } from '../../../components/icons/ListLightIcon';
import api from '../../../services/api';
import { KpisStatusDetailUser } from '../../../types/entities/kpis-status';
import KpisStatusDetailsUserLoading from './KpisStatusDetailsUserLoading';
import KpisStatusDetailsUserTable from './KpisStatusDetailsUserTable';

export interface KpisStatusDetailsUserProps {
  onClose: () => void;
  id_country: number;
  id_brand: number;
  year: number;
  month: number;
  id_user: number;
  expectedRows?: number;
}

async function loadDetailsUser(params: {
  id_country: number;
  id_brand: number;
  year: number;
  month: number;
  id_user: number;
}) {
  return await api
    .listKpisStatusDetailsUser(params)
    .then((response) => response.data);
}

enum Steps {
  DATA,
  LOADING,
  ERROR,
}

const KpisStatusDetailsUser = ({
  onClose,
  id_country,
  id_brand,
  year,
  month,
  id_user,
  expectedRows,
}: KpisStatusDetailsUserProps) => {
  const [error, setError] = useState<
    { code: number; message: string } | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<KpisStatusDetailUser[] | undefined>();
  useEffect(() => {
    setLoading(true);
    loadDetailsUser({ id_country, id_brand, year, month, id_user })
      .then(setDetails)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [id_country, id_brand, year, month, id_user]);

  let step = error ? Steps.ERROR : loading ? Steps.LOADING : Steps.DATA;
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle disableTypography>
        <ListLightIcon color="primary" />
        Valores de los KPI
      </DialogTitle>
      <DialogContent>
        {
          {
            [Steps.DATA]: (
              <TableContainer style={{ maxHeight: 400 }}>
                <KpisStatusDetailsUserTable details={details} />
              </TableContainer>
            ),
            [Steps.LOADING]: (
              <TableContainer style={{ maxHeight: 400 }}>
                <KpisStatusDetailsUserLoading expectedRows={expectedRows} />
              </TableContainer>
            ),
            [Steps.ERROR]: (
              <Feedback
                startIcon={() => <AlertIcon fontSize="large" color="error" />}
                title="Ha ocurrido un error"
                subtitle={error?.message}
              />
            ),
          }[step]
        }
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(KpisStatusDetailsUser);
