import React, { SVGProps } from 'react';
import {
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 22,
      lineHeight: '26px',
      color: '#1D1D1B',
    },
    body: {
      fontSize: 20,
      lineHeight: '23px',
      color: '#898C8D',
    },
  }),
);

export interface FeedbackProps {
  title: string;
  startIcon: () => React.ReactElement<
    SVGProps<SVGSVGElement> & { title?: string }
  >;
  subtitle?: string;
}

export const Feedback = ({ title, startIcon, subtitle }: FeedbackProps) => {
  const classes = useStyles();
  return (
    <>
      <Box textAlign="center">{startIcon()}</Box>
      <Box mt={5}>
        <Typography variant="h2" align="center" className={classes.title}>
          {title}
        </Typography>
      </Box>

      {subtitle && (
        <Box mt={5}>
          <Typography variant="body2" align="center" className={classes.body}>
            {subtitle}
          </Typography>
        </Box>
      )}
    </>
  );
};
