import React, { useCallback, useState } from 'react';
import {
  Button,
  makeStyles,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { HorizontalGrid as Grid } from '../HorizontalGrid';
import { SearchIcon } from '../icons/SearchIcon';
import { useOnChangeEventToSet } from '../../hooks/useOnChangeEventToSet';

const useStyles = makeStyles({
  button: {
    width: '100%',
  },
});
export interface PopoverContentSearchProps<T> {
  value?: string;
  onApply: (value: string) => void;
}

export default function PopoverContentSearch<T>({
  value,
  onApply,
}: PopoverContentSearchProps<T>) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string>(value ?? '');

  const handleClean = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleApply = useCallback(() => {
    onApply(searchValue);
  }, [onApply, searchValue]);

  const handleChange = useOnChangeEventToSet(setSearchValue);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleApply();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <TextField
              id="outlined-search"
              type="search"
              variant="outlined"
              color="primary"
              style={{ width: '100%' }}
              size="small"
              placeholder="Busca un usuario"
              value={searchValue}
              onChange={handleChange}
              InputProps={{
                autoFocus: true,
                style: { borderRadius: 18 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleClean}
            className={classes.button}
          >
            Limpiar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleApply}
            className={classes.button}
            type="submit"
          >
            Aplicar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
