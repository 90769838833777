import { takeLatest, call, put } from 'redux-saga/effects';
import {
  LIST_USERS,
  notifyListUsersFailed,
  notifyListUsersSucceeded,
  UsersAction,
} from '../actions/users';
import api from '../../services/api';
import { sessionExpired } from '../actions/auth/session';

export function* listUsersSaga({ payload }: UsersAction) {
  try {
    const { data } = yield call(api.listUsers, payload);
    yield put(notifyListUsersSucceeded(data));
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      e.response
        ? notifyListUsersFailed(e.response.status, e.response.data.message)
        : notifyListUsersFailed(0, 'Problema de conexión'),
    );
  }
}

export default function* authRootSaga() {
  yield takeLatest(LIST_USERS, listUsersSaga);
}
