import React from 'react';
import { uniqueId } from 'lodash';
import {
  Table as MuTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditIcon } from '../../../components/icons/EditIcon';
import { GarbageIcon } from '../../../components/icons/GarbageIcon';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTableCell-root': {
      textAlign: 'left',
    },
  },
}));

interface TableProps<T> {
  headers: { name: string; dataKey: string }[];
  data: T[];
  noDataMessage: string;
  onEdit?: (value: T) => void;
  onDelete?: (value: T) => void;
}

const UserVisualizationTable = <T extends any>({
  headers,
  data,
  noDataMessage,
  onEdit,
  onDelete,
}: TableProps<T>) => {
  const classes = useStyles();
  const columns = headers.map((header) => ({
    key: uniqueId(),
    ...header,
  }));

  return (
    <MuTable size="small" className={classes.root}>
      <TableHead>
        <TableRow>
          {columns.map(({ key, name }) => (
            <TableCell key={`table-header-${key}`}>{name}</TableCell>
          ))}
          {(onEdit || onDelete) && (
            <TableCell key={`table-header-actions-${uniqueId()}`}></TableCell>
          )}
        </TableRow>
      </TableHead>
      {data.length ? (
        <TableBody>
          {data.map((row: any, i) => {
            return (
              <TableRow key={`table-row-${uniqueId()}`} hover={true}>
                {columns.map(({ dataKey }) => {
                  return (
                    <TableCell key={`table-cell-${uniqueId()}`}>
                      {row[dataKey] ?? ''}
                    </TableCell>
                  );
                })}
                <TableCell
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Box display="flex">
                    <Box>
                      {onEdit && (
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => onEdit(row)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                    <Box>
                      {onDelete && (
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => onDelete(row)}
                        >
                          <GarbageIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableFooter>
          <TableRow key={`table-row-empty-${uniqueId()}`}>
            <TableCell colSpan={columns.length} style={{textAlign: 'center'}}>{noDataMessage}</TableCell>
          </TableRow>
        </TableFooter>
      )}
    </MuTable>
  );
};

export { UserVisualizationTable };
