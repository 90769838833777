// Brands

import { Brand } from '../../../types/entities/brand';

export const LIST_KPIS_BRANDS = 'LIST_KPIS_BRANDS_BY_COUNTRY';
export const LIST_KPIS_BRANDS_SUCCEEDED =
  'LIST_KPIS_BRANDS_BY_COUNTRY_SUCCEEDED';
export const LIST_KPIS_BRANDS_FAILED = 'LIST_KPIS_BRANDS_BY_COUNTRY_FAILED';

export interface ListKpisBrandsAction {
  type: typeof LIST_KPIS_BRANDS;
  payload: {
    countryId: number;
  };
}

export interface ListKpisBrandsSuccededAction {
  type: typeof LIST_KPIS_BRANDS_SUCCEEDED;
  payload: Brand[];
}

export interface ListKpisBrandsFailedAction {
  type: typeof LIST_KPIS_BRANDS_FAILED;
  payload: { status: number; message: string };
}

export type ListKpisBrandsResponseAction =
  | ListKpisBrandsSuccededAction
  | ListKpisBrandsFailedAction;

export const listKpisBrandsAction = (
  countryId: number,
): ListKpisBrandsAction => ({
  type: LIST_KPIS_BRANDS,
  payload: {
    countryId,
  },
});

export const notifyListKpisBrandsSucceeded = (
  brands: Brand[],
): ListKpisBrandsSuccededAction => ({
  type: LIST_KPIS_BRANDS_SUCCEEDED,
  payload: brands,
});

export const notifyListKpisBrandsFailed = (
  status: number,
  message: string,
): ListKpisBrandsFailedAction => ({
  type: LIST_KPIS_BRANDS_FAILED,
  payload: {
    status,
    message,
  },
});
