import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialogContent-root:first-child': {
        paddingTop: 0,
      },
    },
    title: {
      fontSize: 22,
      lineHeight: '26px',
      color: '#1D1D1B',
    },
    body: {
      fontSize: 20,
      lineHeight: '23px',
      color: '#898C8D',
    },
  }),
);

interface ConfirmationDialogProps {
  children?: React.ReactElement | null;
  title?: string;
  subtitle?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog = ({
  children,
  onConfirm,
  onCancel,
  title = '¿Estas seguro de este cambio?',
  subtitle = '',
}: ConfirmationDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={true}
    >
      <DialogTitle>{title}</DialogTitle>
      {subtitle && (
        <DialogContent>
          <Box mt={5}>
            <Typography variant="body2" align="center" className={classes.body}>
              {subtitle}
            </Typography>
          </Box>
        </DialogContent>
      )}
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions className="big-top-space">
        <Button
          variant="contained"
          autoFocus
          onClick={onCancel}
          color="secondary"
        >
          cancelar
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary">
          confirmo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
