import Axios, { AxiosResponse } from 'axios';
import { API_URL } from './constants';

const axiosApi = Axios.create({
  baseURL: API_URL + '/user-assignments',
  withCredentials: true,
});

export default {
  kpis: {
    listCountries: async (userId?: number): Promise<AxiosResponse> => {
      return axiosApi.get(`kpis/countries`);
    },
    listBrands: async (userId?: number): Promise<AxiosResponse> => {
      return axiosApi.get(`kpis/brands`);
    },
    listKpis: async (
      countryId: number,
      brandId: number,
      userId: number | null,
    ): Promise<AxiosResponse> => {
      let params: any = { countryId, brandId };

      if (userId) {
        params = { ...params, userId };
      }
      return axiosApi.get(`kpis/kpis`, {
        params,
      });
    },
  },
};
