import React from 'react';
import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { KpisStatusDetailUser } from '../../../types/entities/kpis-status';
import { StatusIndicator } from '../../../components/feedback/StatusIndicator';

const StyledSubTableCell = withStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        '.MuiTableRow-root:last-child &': {
          borderBottom: 'none',
        },
        '&:first-child': {
          paddingLeft: 40,
        },
      },
      footer: {
        '.MuiTableRow-root:last-child &': {
          borderBottom: 'none',
        },
      },
    }),
  {
    name: 'StyledSubTableCell',
  },
)(TableCell);

export interface KpisStatusDetailsUserTableProps {
  details?: KpisStatusDetailUser[];
}

const KpisStatusDetailsUserTable = ({
  details,
}: KpisStatusDetailsUserTableProps) => {
  const subHeadersCount = 2;
  return (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Kpi</TableCell>
          <TableCell>Valor</TableCell>
        </TableRow>
      </TableHead>
      {details?.length ? (
        <TableBody>
          {details.map((detail) => {
            return (
              <TableRow key={`status_detail_user_${detail.id_kpi}`}>
                <StyledSubTableCell>
                  <StatusIndicator
                    status={
                      detail.value === null || typeof detail.value === undefined
                        ? 'error'
                        : 'success'
                    }
                  />
                </StyledSubTableCell>
                <StyledSubTableCell>{detail.name_kpi}</StyledSubTableCell>
                <StyledSubTableCell>{detail.value}</StyledSubTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableFooter>
          <TableRow>
            <StyledSubTableCell colSpan={subHeadersCount}>
              No hay datos
            </StyledSubTableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default React.memo(KpisStatusDetailsUserTable);
