import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { KpisStatus } from '../../../types/entities/kpis-status';
import KpisStatusRow from './KpisStatusRow';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: '100%',
  },
});

export interface KpisStatusTableProps {
  data: KpisStatus[];
  hasDetails: boolean;
}

export default function KpisStatusTable({
  data,
  hasDetails,
}: KpisStatusTableProps) {
  const classes = useStyles();
  const headers = ['Estado', 'Marca', 'Fecha', 'KPI', 'Fecha de Carga'];
  if (hasDetails) {
    headers.push('');
  }
  const columnsCount = headers.length;

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((h, i) => (
              <TableCell key={i}>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data?.length ? (
          <TableBody>
            {data.map((item) => (
              <KpisStatusRow
                row={item}
                key={`status_${item.id_brand}_${item.month}`}
                hasDetails={hasDetails}
              />
            ))}
          </TableBody>
        ) : (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columnsCount}>
                No se han encontrado registros
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );
}
