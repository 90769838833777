import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, InputBase } from '@material-ui/core';
import { withStyles, fade } from '@material-ui/core/styles';

interface EditableTextProps {
  value: string;
  editable?: boolean;
  onSave: (text: string) => void;
}

const ENTER_KEY = 13;

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const EditableText = ({
  value,
  onSave,
  editable = false,
}: EditableTextProps) => {
  const [text, setText] = useState(value);

  const save = useCallback(() => {
    onSave(text);
  }, [onSave, text]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.keyCode === ENTER_KEY) {
        save();
      }
    },
    [save],
  );

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <>
      {editable ? (
        <ClickAwayListener onClickAway={save}>
          <BootstrapInput
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={onKeyDown}
            autoFocus={true}
          />
        </ClickAwayListener>
      ) : (
        <p>{text}</p>
      )}
    </>
  );
};

EditableText.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export { EditableText };
