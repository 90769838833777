import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  FormControl,
  InputLabel,
  DialogActions,
  FormHelperText,
  DialogContentText,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import { PrimaryButton } from '../../components/buttons';
import { BootstrapInput } from '../../components/BootstrapInput';
import { useQuery } from '../../hooks/useQuery';
import api from '../../services/api';
import { HorizontalGrid as Grid } from '../../components/HorizontalGrid';
import { FormDialog } from '../../components/FormDialog';
import { useHistory } from 'react-router-dom';
import { PasswordInfo } from '../../components/PasswordInfo';
import { isPasswordStrong } from '../../services/password';
import { isEmail } from '../../services/email-validation';

interface FormValues {
  username: string;
  password: string;
  passwordConfirm: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 35,
    maxWidth: 446,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 510,
    },
  },
  formControl: {
    width: '100%',
  },
  input: {
    marginBottom: 10,
  },
  label: {
    color: '#000000',
    transform: 'translate(0, 1.5px) scale(1)',
  },
  button: {
    margin: 'auto',
    flex: 'unset!important',
    width: 234,
  },
}));

export function SetUpAccount() {
  const query = useQuery();
  const token = query.get('token');
  const history = useHistory();

  const [isFetchingCheck, setIsFetchingCheck] = useState(true);
  useEffect(() => {
    if (!token) {
      setIsFetchingCheck(false);
      history.replace('/signin', { badLink: true });
    } else {
      api
        .checkSetUpAccount({ token })
        .then(() => {
          setIsFetchingCheck(false);
        })
        .catch((error: any) => {
          history.replace('/signin', { badLink: true });
        });
    }
  }, [token, history]);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      username: '',
      password: '',
      passwordConfirm: '',
    },
    onSubmit: async ({ username, password }) => {
      try {
        setIsFetching(true);
        await api.setUpAccount({ username, password, token: token ?? '' });
        history.replace('/signin');
      } catch (error) {
        setOpen(true);
      } finally {
        setIsFetching(false);
      }
    },
    validate: (values) => {
      const errors: Partial<FormValues> = {};
      if (!values.username) {
        errors.username = 'Requerido';
      } else if (!isEmail(values.username)) {
        errors.username = 'No es un email válido';
      }
      if (!values.password) {
        errors.password = 'Requerido';
      } else if (!isPasswordStrong(values.password)) {
        errors.password = 'La contraseña no es segura, intenta con otra.';
      }
      if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = 'Deben ser iguales';
      }
      return errors;
    },
  });

  const onClose = () => setOpen(false);

  const { touched, errors } = formik;
  const visibleErrors = {
    username: touched.username ? errors.username : null,
    password: touched.password ? errors.password : null,
    passwordConfirm: touched.passwordConfirm ? errors.passwordConfirm : null,
  };

  if (isFetchingCheck) {
    return (
      <Dialog
        open
        disableBackdropClick
        className="featured"
        PaperProps={{ className: classes.paper }}
      >
        <DialogContent>
          <DialogContentText align="center">
            Verificando vínculo...
          </DialogContentText>
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <FormDialog
        open
        disableBackdropClick
        className="featured"
        onSubmit={formik.handleSubmit}
        PaperProps={{ className: classes.paper }}
      >
        <DialogTitle disableTypography>
          <div className="Signin-logo"></div>
          <div className="Signin-welcome">Configurar Cuenta</div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                required
                error={Boolean(visibleErrors.username)}
              >
                <InputLabel shrink htmlFor="username" className={classes.label}>
                  Correo Electrónico
                </InputLabel>
                <BootstrapInput
                  className={classes.input}
                  id="username"
                  data-testid="set-up-account-username"
                  type="email"
                  placeholder="ejemplo@stellantis.com"
                  {...formik.getFieldProps('username')}
                />
                {visibleErrors.username ? (
                  <FormHelperText error>
                    {visibleErrors.username}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                required
                error={Boolean(visibleErrors.password)}
              >
                <InputLabel shrink htmlFor="password" className={classes.label}>
                  Contraseña
                </InputLabel>
                <BootstrapInput
                  className={classes.input}
                  id="password"
                  data-testid="set-up-account-password"
                  type="password"
                  placeholder="Contraseña"
                  endAdornment={<PasswordInfo />}
                  autoComplete="new-password"
                  {...formik.getFieldProps('password')}
                />
                {visibleErrors.password ? (
                  <FormHelperText error>
                    {visibleErrors.password}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                required
                error={Boolean(visibleErrors.passwordConfirm)}
              >
                <InputLabel
                  shrink
                  htmlFor="passwordConfirm"
                  className={classes.label}
                >
                  Confirmar Contraseña
                </InputLabel>
                <BootstrapInput
                  className={classes.input}
                  id="passwordConfirm"
                  data-testid="set-up-account-passwordConfirm"
                  type="password"
                  placeholder="Confirmar Contraseña"
                  {...formik.getFieldProps('passwordConfirm')}
                />
                {visibleErrors.passwordConfirm ? (
                  <FormHelperText error>
                    {visibleErrors.passwordConfirm}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            id="btn-submit"
            data-testid="set-up-account-btn-local-account"
            loading={isFetching}
            type="submit"
            className={classes.button}
          >
            CONFIGURAR CUENTA
          </PrimaryButton>
        </DialogActions>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert id="alert-set-up-account-error" severity="error">
            Problema al configurar usuario
          </Alert>
        </Snackbar>
      </FormDialog>
    </>
  );
}
