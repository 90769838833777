import React from 'react';
import { Box, CircularProgress, Popover } from '@material-ui/core';

export interface UsersTableHeadPopoverProps {
  loading?: boolean;
  onCancel: () => void;
  anchorEl?: HTMLTableHeaderCellElement;
  children: React.ReactNode;
}

export default function UsersTableHeadPopover({
  children,
  loading,
  anchorEl,
  onCancel,
}: UsersTableHeadPopoverProps) {
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onCancel}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box padding={3} width={315}>
        {!loading ? (
          children
        ) : (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Popover>
  );
}
