import { useDependentLoader } from './useDependentLoader';
import { Loader, GetFromStore } from './types';

/**
 * Hook that trigger an action and load data from store
 */
export function useLoader<T>(
  getFromStore: GetFromStore<T>,
  listAction: () => any,
): Loader<T> {
  return useDependentLoader(true, getFromStore, listAction);
}
