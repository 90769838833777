import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

interface LoadingProps {
    loading?: boolean;
    loadingClass?: string;
}

export type LoadingButtonProps = LoadingProps & ButtonProps;

const LoadingButton: React.FunctionComponent<LoadingButtonProps> = (
    props: LoadingButtonProps
) => {
    const { loading = false, loadingClass, ...rest } = props;

    return (
        <Button
            {...rest}
            endIcon={loading && <CircularProgress className={loadingClass} />}
        ></Button>
    );
};

export default LoadingButton;
