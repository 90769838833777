export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
export const MONTHS = [
  { id: 1, value: 'Enero' },
  { id: 2, value: 'Febrero' },
  { id: 3, value: 'Marzo' },
  { id: 4, value: 'Abril' },
  { id: 5, value: 'Mayo' },
  { id: 6, value: 'Junio' },
  { id: 7, value: 'Julio' },
  { id: 8, value: 'Agosto' },
  { id: 9, value: 'Septiembre' },
  { id: 10, value: 'Octubre' },
  { id: 11, value: 'Noviembre' },
  { id: 12, value: 'Diciembre' },
];

const currentYear = new Date().getFullYear();
const MAX_PAST_YEARS = process.env.REACT_APP_MAX_PAST_YEARS
  ? parseInt(process.env.REACT_APP_MAX_PAST_YEARS)
  : 10;

export const YEARS = [...new Array(MAX_PAST_YEARS)].map(
  (_: any, index: number) => {
    const year = currentYear - index;
    return { id: year, value: year.toString() };
  },
);

export const TRIMESTERS = [
  { value: '0', label: 'ENE - FEB - MAR' },
  { value: '1', label: 'ABR - MAY - JUN' },
  { value: '2', label: 'JUL - AGO - SEP' },
  { value: '3', label: 'OCT - NOV - DIC' },
];

export const USERS_TABLE_DEFAULT_EXPECTED_ROWS = 25;
export const KPI_STATUS_DETAILS_USER_DEFAULT_ROWS = 5;
