import { produce, Draft } from 'immer';
import {
  UsersAction,
  UsersFailedAction,
  UsersSuccededAction,
  LIST_USERS,
  LIST_USERS_SUCCEEDED,
  LIST_USERS_FAILED,
} from '../actions/users';
import { User } from '../../types/entities/user';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import { ResourceState } from './types';

interface HasError {
  error?: { status: number; message: string };
}

type UserState = ResourceState<User> & HasError;
const initial: UserState = {
  data: [],
  loading: false,
  error: undefined,
};

const applyChanges = produce(
  (
    draftState: Draft<UserState>,
    action:
      | UsersAction
      | UsersSuccededAction
      | UsersFailedAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_USERS:
        draftState.loading = true;
        draftState.error = undefined;
        break;
      case LIST_USERS_SUCCEEDED:
        draftState.loading = false;
        draftState.data = action.payload;
        draftState.error = undefined;
        break;
      case LIST_USERS_FAILED:
        draftState.loading = false;
        draftState.error = action.payload;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: ResourceState<User> = initial,
  action:
    | UsersAction
    | UsersSuccededAction
    | UsersFailedAction
    | SessionExpiredAction
    | { type?: string },
): ResourceState<User> => {
  return applyChanges(state, action);
};
