import { produce, Draft } from 'immer';
import {
  BrandsAction,
  BrandsFailedAction,
  BrandsSuccededAction,
  LIST_BRANDS,
  LIST_BRANDS_SUCCEEDED,
  LIST_BRANDS_FAILED,
} from '../actions/brands';
import { Brand } from '../../types/entities/brand';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import { ResourceState } from './types';

const initial: ResourceState<Brand> = {
  data: [],
  loading: false,
};

const applyChanges = produce(
  (
    draftState: Draft<ResourceState<Brand>>,
    action:
      | BrandsAction
      | BrandsSuccededAction
      | BrandsFailedAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_BRANDS:
        draftState.loading = true;
        break;
      case LIST_BRANDS_SUCCEEDED:
        draftState.loading = false;
        draftState.data = action.payload;
        break;
      case LIST_BRANDS_FAILED:
        draftState.loading = false;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: ResourceState<Brand> = initial,
  action:
    | BrandsAction
    | BrandsSuccededAction
    | BrandsFailedAction
    | SessionExpiredAction
    | { type?: string },
): ResourceState<Brand> => {
  return applyChanges(state, action);
};
