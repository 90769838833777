import React from 'react';
import clsx from 'clsx';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { ListLightIcon } from '../icons/ListLightIcon';
import { KpiExcel } from './LoadKpiInit';

const previewColumns = ['Id', 'Nombre', 'Valor'];

export interface LoadKpiPreviewProps {
  kpis: KpiExcel[];
  onSuccess: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  previewTableContainer: {
    maxHeight: 340,
    overflowY: 'scroll',
    overflowX: 'auto',
    '& .MuiTableCell-root': {
      fontSize: 14,
    },
  },
  columnName: {
    width: 250,
  },
  columnId: {
    width: 40,
  },
  rowName: {
    maxWidth: 250,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rowId: {
    width: 40,
  },
  button: {
    flex: 1,
  },
});

export default function LoadKpiPreview({
  kpis,
  onSuccess,
  onCancel,
}: LoadKpiPreviewProps) {
  const classes = useStyles();
  return (
    <>
      <DialogTitle disableTypography>
        <ListLightIcon color="primary" />
        Revisa tu carga de KPI's
      </DialogTitle>
      <DialogContent>
        <TableContainer className={classes.previewTableContainer}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {previewColumns.map((column) => (
                  <TableCell
                    key={`load-kpi-preview-column-${column}`}
                    className={clsx(
                      column === 'Nombre' && classes.columnName,
                      column === 'Id' && classes.columnId,
                    )}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {kpis.map((row) => {
                return (
                  <TableRow key={`load-kpi-preview-row-${row.id_kpi}`}>
                    {Object.entries(row).map(([key, value], i) => (
                      <TableCell
                        key={`load-kpi-preview-cell-${row.id_kpi}${i}`}
                        className={clsx(
                          key === 'name_kpi' && classes.rowName,
                          key === 'id_kpi' && classes.rowId,
                        )}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className="big-top-space">
        <Button
          id="btn-load-kpi-preview-cancel"
          color="secondary"
          variant="contained"
          onClick={onCancel}
          className={classes.button}
        >
          cancelar
        </Button>
        <Button
          id="btn-load-kpi-preview-confirm"
          color="primary"
          variant="contained"
          onClick={onSuccess}
          className={classes.button}
        >
          confirmar
        </Button>
      </DialogActions>
    </>
  );
}
