import { useDependentLoaderSelector } from './useDependentLoaderSelector';
import { LoaderSelector, GetFromStore, IsValueInOptions } from './types';

/**
 * Hook to handle select's options loaded from store
 */
export function useLoaderSelector<T>(
  getFromStore: GetFromStore<T>,
  listAction: () => any,
  isValueInOptions: IsValueInOptions<T>,
  initialValue?: string,
): LoaderSelector<T> {
  return useDependentLoaderSelector(
    true,
    getFromStore,
    listAction,
    isValueInOptions,
    initialValue,
  );
}
