import { produce, Draft } from 'immer';
import {
  ListKpisBrandsSuccededAction,
  LIST_KPIS_BRANDS_SUCCEEDED,
  LIST_KPIS_BRANDS,
  LIST_KPIS_BRANDS_FAILED,
  ListKpisBrandsFailedAction,
} from '../actions/kpis/brands';
import {
  LIST_COUNTRIES_SUCCEEDED,
  ListCountriesSuccededAction,
} from '../actions/kpis/countries';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import { KpisStatus } from '../../types/entities/kpis-status';
import {
  LIST_KPIS_STATUS,
  LIST_KPIS_STATUS_SUCCEEDED,
  LIST_KPIS_STATUS_FAILED,
  ListKpisStatusSuccededAction,
} from '../actions/kpis/kpis-status';

interface KpiBrand {
  readonly id_brand: number;
  readonly name_brand: string;
}

interface KpiCountry {
  id_country: number;
  name_country: string;
}

interface KpiResource<T> {
  data: T[];
  expirationDate: Date | null;
}

interface HasLoading {
  loading: boolean;
}

interface KpiData {
  allowed: null;
  values: null;
}

interface HasError {
  error?: { status: number; message: string };
}

interface KpiState {
  readonly brand: KpiResource<KpiBrand> & HasLoading;
  readonly country: KpiResource<KpiCountry>;
  readonly kpisStatus: KpiResource<KpisStatus> & HasLoading & HasError;
  readonly data: KpiData;
}

const initial: KpiState = {
  brand: {
    loading: false,
    data: [],
    expirationDate: null,
  },
  country: {
    data: [],
    expirationDate: null,
  },
  kpisStatus: {
    loading: false,
    data: [],
    expirationDate: null,
  },
  data: {
    allowed: null,
    values: null,
  },
};

const applyChanges = produce(
  (
    draftState: Draft<KpiState>,
    action:
      | ListKpisStatusSuccededAction
      | ListCountriesSuccededAction
      | ListKpisBrandsSuccededAction
      | ListKpisBrandsFailedAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_KPIS_BRANDS:
        draftState.brand.loading = true;
        break;
      case LIST_KPIS_BRANDS_SUCCEEDED:
        draftState.brand.data = action.payload as Array<KpiBrand>;
        draftState.brand.loading = false;
        break;
      case LIST_KPIS_BRANDS_FAILED:
        draftState.brand.loading = false;
        break;
      case LIST_COUNTRIES_SUCCEEDED:
        draftState.country.data = action.payload as Array<KpiCountry>;
        break;
      case LIST_KPIS_STATUS:
        draftState.kpisStatus.loading = true;
        draftState.kpisStatus.error = undefined;
        break;
      case LIST_KPIS_STATUS_SUCCEEDED:
        draftState.kpisStatus.data = action.payload as Array<KpisStatus>;
        draftState.kpisStatus.loading = false;
        break;
      case LIST_KPIS_STATUS_FAILED:
        draftState.kpisStatus.loading = false;
        draftState.kpisStatus.error = action.payload;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: KpiState = initial,
  action:
    | ListCountriesSuccededAction
    | ListKpisBrandsSuccededAction
    | ListKpisStatusSuccededAction
    | SessionExpiredAction
    | { type?: string },
): KpiState => {
  return applyChanges(state, action);
};
