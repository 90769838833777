import { UserAssignedKpi } from '../../../../types/entities/user';

type UserCountKpisByCountry = Map<
  number,
  {
    name: string;
    brandsById: Map<
      number,
      { name: string; dimensionsById: Map<number, number> }
    >;
  }
>;

export interface AssignedKpisTableItem {
  id_country: number;
  name_country: string;
  id_brand: number;
  name_brand: string;
  dimensions: Map<number, number>;
}

export type AssignedKpisTableData = AssignedKpisTableItem[];

export function parseAssignedKpisToTableData(
  assignedKpis: UserAssignedKpi[],
): AssignedKpisTableData {
  const byCountry: UserCountKpisByCountry = new Map();
  for (const assignedKpi of assignedKpis) {
    if (!byCountry.has(assignedKpi.id_country)) {
      byCountry.set(assignedKpi.id_country, {
        name: assignedKpi.name_country,
        brandsById: new Map(),
      });
    }
    const byBrand = byCountry.get(assignedKpi.id_country)!.brandsById;
    if (!byBrand.has(assignedKpi.id_brand)) {
      byBrand.set(assignedKpi.id_brand, {
        name: assignedKpi.name_brand,
        dimensionsById: new Map(),
      });
    }
    const byDimension = byBrand.get(assignedKpi.id_brand)!.dimensionsById;
    if (!byDimension.has(assignedKpi.id_dimension)) {
      byDimension.set(assignedKpi.id_dimension, 0);
    }
    byDimension.set(
      assignedKpi.id_dimension,
      byDimension.get(assignedKpi.id_dimension)! + 1,
    );
  }
  const data: AssignedKpisTableData = [];
  for (const [id_country, { name: name_country, brandsById }] of Array.from(
    byCountry.entries(),
  )) {
    for (const [id_brand, { name: name_brand, dimensionsById }] of Array.from(
      brandsById.entries(),
    )) {
      data.push({
        id_country,
        name_country,
        id_brand,
        name_brand,
        dimensions: dimensionsById,
      });
    }
  }
  return data;
}

export function createIsValueInOptions<T>(key: keyof T) {
  return (value: string, options: T[]) =>
    options.some((o) => `${o[key]}` === value);
}

export function createFindInOptions<T>(
  key: keyof T,
): (value: string, options: T[]) => T | undefined {
  return (value, options) =>
    options ? options.find((o) => `${o[key]}` === value) : undefined;
}

export function getFromUserAssignmentsKpisStore<T>(
  key: 'countries' | 'brands' | 'kpis',
) {
  return (store: any) => ({
    options: store.userAssignmentsKpis[key].data as T,
    loading: store.userAssignmentsKpis[key].loading,
  });
}
