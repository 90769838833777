import {
  SigninRequestAction,
  SigninSuccededAction,
  SigninFailedAction,
} from './types';

export const USER_SIGNIN = 'USER_SIGNIN';
export const SIGNIN_SUCCEEDED = 'SIGNIN_SUCCEEDED';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';

export const userSignin = (
  username: string,
  password: string,
): SigninRequestAction => ({
  type: USER_SIGNIN,
  payload: {
    username,
    password,
  },
});

export const notifySigninSuccess = (): SigninSuccededAction => ({
  type: SIGNIN_SUCCEEDED,
});

export const notifySigninFailure = (
  status: number,
  message: string,
): SigninFailedAction => ({
  type: SIGNIN_FAILED,
  payload: {
    status,
    message,
  },
});
