import React, { useState, useCallback } from 'react';
import { Popover, Box, IconButton } from '@material-ui/core';
import { InformationIcon } from './icons/InformationIcon';

export function PasswordInfo() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePasswordPopClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const passwordPopOpen = Boolean(anchorEl);
  const passwordPopId = 'password-popover';

  return (
    <>
      <IconButton
        aria-label="toggle password help"
        onClick={handlePasswordPopClick}
        edge="end"
        size="small"
      >
        <InformationIcon fontSize="small" color="primary" />
      </IconButton>
      <Popover
        id={passwordPopId}
        open={passwordPopOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          La contraseña:
          <ul>
            <li>Debe tener mínimo de 9 caracteres</li>
            <li>Debe contener letras mayúsculas y minúsculas</li>
            <li>Debe contener números y letras</li>
            <li>
              Debe contener al menos un caracter especial: ({'!?¿¡@$%*&_-. '})
            </li>
          </ul>
        </Box>
      </Popover>
    </>
  );
}
