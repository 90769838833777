import {
  withStyles,
  InputBase,
  fade,
  Theme,
  createStyles,
} from '@material-ui/core';

export const BootstrapInput = withStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: fade('#575757', 0.56),
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        marginBottom: 23,
        'label + &': {
          color: '#1D1D1B',
          marginTop: theme.spacing(3),
        },
        '& + .MuiFormHelperText-root.Mui-error': {
          marginTop: 3,
          height: 20,
        },
        '&.Mui-focused': {
          borderColor: fade(theme.palette.primary.main, 0.56),
        },
        '&.MuiInputBase-adornedEnd': {
          paddingRight: 12,
        },
      },
      input: {
        position: 'relative',
        fontSize: 18,
        lineHeight: '21px',
        padding: '12px 14px',
        '&::placeholder': {
          color: 'rgba(137, 140, 141, 1)',
          opacity: 0.2,
        },
      },
      error: {
        borderColor: theme.palette.error.main,
        marginBottom: 0,
        '&.Mui-focused': {
          borderColor: theme.palette.error.main,
          boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        },
      },
    }),
  { name: 'BootstrapInput' },
)(InputBase);
