import {
  RequestProfileFailedAction,
  RequestProfileSuccededAction,
  RequestProfileAction,
  ProfileData,
} from './types';

export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const REQUEST_PROFILE_SUCCEEDED = 'REQUEST_PROFILE_SUCCEEDED';
export const REQUEST_PROFILE_FAILED = 'REQUEST_PROFILE_FAILED';

export const requestProfile = (): RequestProfileAction => ({
  type: REQUEST_PROFILE,
});

export const notifyProfileSuccess = ({
  id,
  id_user,
  first_name,
  last_name,
  roles,
}: ProfileData): RequestProfileSuccededAction => ({
  type: REQUEST_PROFILE_SUCCEEDED,
  payload: {
    id: (id || id_user)!,
    firstName: first_name,
    lastName: last_name,
    roles,
  },
});

export const notifyProfileFailure = (): RequestProfileFailedAction => ({
  type: REQUEST_PROFILE_FAILED,
});
