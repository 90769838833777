export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function isEmail(email: string) {
  if (!email) {
    return false;
  }
  if (typeof email !== 'string') {
    return false;
  }
  if (!EMAIL_REGEX.test(email)) {
    return false;
  }
  return true;
}
