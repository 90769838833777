import {
  Box,
  makeStyles,
  TableContainer,
  Theme,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import KpisStatusTableLoading from './KpisStatusTableLoading';

export interface KpisStatusGroupLoadingProps {
  width: number;
  expectedRows: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 25,
    lineHeight: '30px',
    color: '#A2A1A1',
    marginBottom: theme.spacing(1),
  },
}));

function KpisStatusGroupLoading({
  width,
  expectedRows,
}: KpisStatusGroupLoadingProps) {
  const classes = useStyles();
  return (
    <Box mb={4}>
      <Typography variant="h2" className={classes.title}>
        <Skeleton width={width} />
      </Typography>
      <TableContainer>
        <KpisStatusTableLoading expectedRows={expectedRows} />
      </TableContainer>
    </Box>
  );
}

export default React.memo(KpisStatusGroupLoading);
