import { takeLatest, call, put } from 'redux-saga/effects';
import {
  LIST_ASSIGNMENTS_COUNTRIES,
  notifyListAssignmentsCountriesSucceeded,
  notifyListAssignmentsCountriesFailed,
} from '../actions/user-assignments/countries';
import {
  LIST_ASSIGNMENTS_BRANDS,
  notifyListAssignmentsBrandsSucceeded,
  notifyListAssignmentsBrandsFailed,
} from '../actions/user-assignments/brands';
import {
  LIST_ASSIGNMENTS_KPIS,
  notifyListAssignmentsKpisSucceeded,
  notifyListAssignmentsKpisFailed,
} from '../actions/user-assignments/kpis';
import userAssignmentsService from '../../services/user-assignments';
import { sessionExpired } from '../actions/auth/session';
import { ListAssignmentsKpisAction } from '../actions/user-assignments/types';

export function* listCountriesSaga() {
  try {
    const { data } = yield call(userAssignmentsService.kpis.listCountries);
    yield put(notifyListAssignmentsCountriesSucceeded(data));
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      e.response
        ? notifyListAssignmentsCountriesFailed(
            e.response.status,
            e.response.data.message,
          )
        : notifyListAssignmentsCountriesFailed(0, 'Problema de conexión'),
    );
  }
}

export function* listBrandsSaga() {
  try {
    const { data } = yield call(userAssignmentsService.kpis.listBrands);
    yield put(notifyListAssignmentsBrandsSucceeded(data));
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      e.response
        ? notifyListAssignmentsBrandsFailed(
            e.response.status,
            e.response.data.message,
          )
        : notifyListAssignmentsBrandsFailed(0, 'Problema de conexión'),
    );
  }
}

export function* listKpisSaga({
  payload: { countryId, brandId, userId },
}: ListAssignmentsKpisAction) {
  try {
    const { data } = yield call(
      userAssignmentsService.kpis.listKpis,
      countryId,
      brandId,
      userId
    );
    yield put(notifyListAssignmentsKpisSucceeded(data));
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      e.response
        ? notifyListAssignmentsKpisFailed(
            e.response.status,
            e.response.data.message,
          )
        : notifyListAssignmentsKpisFailed(0, 'Problema de conexión'),
    );
  }
}

export default function* userAssignmentsKpisSaga() {
  yield takeLatest(LIST_ASSIGNMENTS_COUNTRIES, listCountriesSaga);
  yield takeLatest(LIST_ASSIGNMENTS_BRANDS, listBrandsSaga);
  yield takeLatest(LIST_ASSIGNMENTS_KPIS, listKpisSaga);
}
