import { useTimeout } from './useTimeout';
import { useEffect } from 'react';

/**
 * Hook that allow to hide a modal after a timeout
 * @param {boolean} open Modal's visibility state
 * @param {Function} close Modal's close function
 * @returns {Function} Function that start timeout
 */
export function useModalTimeout(
  open: boolean,
  close: () => void,
): (timer: number) => void {
  const [startTimeout, cancel] = useTimeout(close);

  useEffect(() => {
    if (!open) {
      cancel();
    }
  }, [open, cancel]);

  return startTimeout;
}
