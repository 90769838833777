import {
  CountryWithAssignable,
  ListAssignmentsCountriesAction,
  ListAssignmentsCountriesFailedAction,
  ListAssignmentsCountriesSuccededAction,
} from './types';

export const LIST_ASSIGNMENTS_COUNTRIES = 'LIST_ASSIGNMENTS_COUNTRIES';
export const LIST_ASSIGNMENTS_COUNTRIES_SUCCEEDED =
  'LIST_ASSIGNMENTS_COUNTRIES_SUCCEEDED';
export const LIST_ASSIGNMENTS_COUNTRIES_FAILED =
  'LIST_ASSIGNMENTS_COUNTRIES_FAILED';

export const listCountriesAction = (): ListAssignmentsCountriesAction => ({
  type: LIST_ASSIGNMENTS_COUNTRIES,
});

export const notifyListAssignmentsCountriesSucceeded = (
  countries: CountryWithAssignable[],
): ListAssignmentsCountriesSuccededAction => ({
  type: LIST_ASSIGNMENTS_COUNTRIES_SUCCEEDED,
  payload: countries,
});

export const notifyListAssignmentsCountriesFailed = (
  status: number,
  message: string,
): ListAssignmentsCountriesFailedAction => ({
  type: LIST_ASSIGNMENTS_COUNTRIES_FAILED,
  payload: {
    status,
    message,
  },
});
