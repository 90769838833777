import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Pagination } from '@material-ui/lab';
import { listUsersAction } from '../../../store/actions/users';
import UsersTable from './UsersTable';
import { UserListItem } from '../../../types/entities/user';
import { Paper, Box, TableContainer, makeStyles } from '@material-ui/core';
import UsersTableLoading from './UsersTableLoading';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { AlertIcon } from '../../../components/icons/AlertIcon';
import { Feedback } from '../../../components/feedback/Feedback';
import { UsersListFiltersContext } from '../contexts/UsersListFiltersContext';
import UserUpdateActive from './UserUpdateActive';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(9),
    },
  },
}));

function getUsersFromStore(store: any) {
  return {
    data: store.users.data.data as UserListItem[],
    pages: store.users.data.pages as number,
    loading: store.users.loading as boolean,
    error: store.users.error,
  };
}

interface UserListProps {
  onEdit: (id: number) => void;
}

export default function UsersList({ onEdit }: UserListProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const response = useSelector(getUsersFromStore, shallowEqual);
  const { data, pages, loading, error } = response;

  const { filters, goToPage } = useContext(UsersListFiltersContext);
  useEffect(() => {
    dispatch(listUsersAction(filters));
    setPage(filters.page ?? 1);
  }, [dispatch, filters]);

  const [page, setPage] = useState<number | undefined>(filters.page ?? 1);
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    goToPage(value);
  };

  const [updateActiveUser, setUpdateActiveUser] = useState<UserListItem>();
  const onUpdateActiveReady = useCallback(
    (complete: boolean, user?: UserListItem) => {
      if (complete) {
        if (user) {
          onEdit(user.id_user);
        }
      }
      setUpdateActiveUser(undefined);
    },
    [onEdit],
  );
  return (
    <>
      <Box minHeight={0} marginBottom={3}>
        <Paper style={{ maxHeight: '100%', display: 'flex' }}>
          <Box paddingX={3} paddingY={2} flex={1} width="100%">
            <TableContainer style={{ maxHeight: '100%', overflow: 'auto' }}>
              {!loading ? (
                !error ? (
                  <UsersTable
                    data={data}
                    onSwitchClick={(user) => setUpdateActiveUser(user)}
                    onEdit={onEdit}
                  />
                ) : (
                  <Box mt={8} mb={16}>
                    <Feedback
                      startIcon={() => (
                        <AlertIcon fontSize="large" color="error" />
                      )}
                      title="Ha ocurrido un error"
                      subtitle={error!.message}
                    />
                  </Box>
                )
              ) : (
                <UsersTableLoading />
              )}
            </TableContainer>
          </Box>
        </Paper>
      </Box>
      <Box alignItems="flex-end" className={classes.paginationContainer}>
        <Pagination
          size="large"
          count={pages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Box>
      <UserUpdateActive user={updateActiveUser} onReady={onUpdateActiveReady} />
    </>
  );
}
