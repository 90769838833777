import React, { useEffect, useMemo } from 'react';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListLightIcon } from '../../../../components/icons/ListLightIcon';
import { HorizontalGrid as Grid } from '../../../../components/HorizontalGrid';
import { useFormik } from 'formik';
import { CountryWithOptionals } from '../../../../types/entities/country';
import { BrandWithOptionals } from '../../../../types/entities/brand';
import { YEARS, MONTHS } from '../../../../services/constants';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
  },
  button: {
    flex: 1,
  },
}));

interface ManualLoadSelectionProps {
  countries: CountryWithOptionals[];
  brands: BrandWithOptionals[];
  onSelect: (data: Selections) => void;
  onCancel: () => void;
}

interface FormValues {
  country: string;
  brand: string;
  month: string;
  year: string;
}

export interface Selections {
  country: number;
  brand: number;
  month: number;
  year: number;
}

const parseIntValues = function (obj: FormValues): Selections {
  return {
    country: parseInt(obj.country),
    brand: parseInt(obj.brand),
    month: parseInt(obj.month),
    year: parseInt(obj.year),
  };
};

const ManualLoadSelection = ({
  countries,
  brands,
  onSelect,
  onCancel,
}: ManualLoadSelectionProps) => {
  const classes = useStyles();
  const formik = useFormik<FormValues>({
    initialValues: {
      country: '',
      brand: '',
      month: '',
      year: '',
    },
    onSubmit: (values: FormValues) => {
      onSelect(parseIntValues(values));
    },
    validate: (values: FormValues) => {
      const errors: { [key: string]: string | boolean } = {};

      Object.entries(values).forEach(([key, value]) => {
        if (!value) {
          errors[key] = 'Requerido';
        }
      });

      return errors;
    },
  });

  const resetForm = formik.resetForm;
  const { errors, dirty } = formik;
  const invalidForm = useMemo(
    () => !dirty || (dirty && Boolean(Object.values(errors).length)),
    [dirty, errors],
  );

  useEffect(() => {
    return resetForm;
  }, [resetForm]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        noValidate
      >
        <DialogTitle disableTypography>
          <ListLightIcon color="primary" />
          Carga manual de KPI's
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={6}>
              <FormControl className={classes.formControl} required>
                <InputLabel htmlFor="select-country-load-kpi">País</InputLabel>
                <Select
                  native
                  inputProps={{
                    id: 'select-country-load-kpi',
                    name: 'country',
                  }}
                  {...formik.getFieldProps('country')}
                >
                  <option value="" aria-label="None" />
                  {countries.map((value) => (
                    <option
                      key={`kpi-country-${value.id_country}`}
                      value={value.id_country}
                    >
                      {value.name_country}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                required
                disabled={false}
              >
                <InputLabel htmlFor="select-brand-load-kpi">Marca</InputLabel>
                <Select
                  native
                  inputProps={{
                    id: 'select-brand-load-kpi',
                    name: 'brand',
                  }}
                  {...formik.getFieldProps('brand')}
                >
                  <option value="" aria-label="None" />
                  {brands.map((value) => (
                    <option
                      key={`kpi-brand-${value.id_brand}`}
                      value={`${value.id_brand}`}
                    >
                      {value.name_brand}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={6}>
              <FormControl className={classes.formControl} required>
                <InputLabel htmlFor="input-month-load-kpi">Mes</InputLabel>
                <Select
                  native
                  inputProps={{
                    id: 'input-month-load-kpi',
                    name: 'month',
                  }}
                  {...formik.getFieldProps('month')}
                >
                  <option value="" aria-label="None" />
                  {MONTHS.map((value: any) => (
                    <option key={`kpi-month-${value.id}`} value={value.id}>
                      {value.value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} md={6}>
              <FormControl className={classes.formControl} required>
                <InputLabel htmlFor="input-year-load-kpi">Año</InputLabel>
                <Select
                  native
                  inputProps={{
                    id: 'input-year-load-kpi',
                    name: 'year',
                  }}
                  {...formik.getFieldProps('year')}
                >
                  <option value="" aria-label="None" />
                  {YEARS.map((value: any) => (
                    <option key={`kpi-year-${value.id}`} value={value.id}>
                      {value.value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="big-top-space">
          <Button variant="contained" color="secondary" onClick={onCancel}>
            cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={invalidForm}
          >
            cargar manualmente
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export { ManualLoadSelection };
