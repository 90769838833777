import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useModalTimeout } from '../hooks/useModalTimeout';
import api from '../services/api';
import { isPasswordStrong } from '../services/password';
import { PrimaryButton } from './buttons';
import { SuccessFeedback } from './feedback/SuccessFeedback';
import { FormDialog } from './FormDialog';
import { HorizontalGrid as Grid } from './HorizontalGrid';
import { PasswordInfo } from './PasswordInfo';

export interface ChangePasswordDialog {
  open: boolean;
  onClose: (success?: boolean) => void;
}

interface FormValues {
  old_password: string;
  password: string;
  password_confirm: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiGrid-item:last-child': {
      paddingTop: theme.spacing(4),
    },
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
  },
  button: {
    flex: 1,
  },
}));

export function ChangePasswordDialog({ open, onClose }: ChangePasswordDialog) {
  const classes = useStyles();
  const [onSuccess, setSuccessNotification] = useState(false);

  const startTimeout = useModalTimeout(open, onClose);
  const [error, setError] = useState('');

  const [isFetching, setIsFetching] = useState(false);
  const formik = useFormik<FormValues>({
    initialValues: {
      old_password: '',
      password: '',
      password_confirm: '',
    },
    onSubmit: async (data) => {
      try {
        setIsFetching(true);
        await api.changePassword(data);
        setSuccessNotification(true);
        startTimeout(3000);
      } catch (error) {
        setError('No se ha podido cambiar la contraseña');
      } finally {
        setIsFetching(false);
      }
    },
    validate: (values) => {
      const errors: Partial<FormValues> = {};
      if (!values.old_password) {
        errors.old_password = 'Requerido';
      }
      if (!values.password) {
        errors.password = 'Requerido';
      } else if (!isPasswordStrong(values.password)) {
        errors.password = 'La contraseña no es segura';
      }
      if (values.password !== values.password_confirm) {
        errors.password_confirm = 'Deben ser iguales';
      }
      return errors;
    },
  });

  const { touched, errors } = formik;
  const visibleErrors = {
    old_password: touched.old_password ? errors.old_password : null,
    password: touched.password ? errors.password : null,
    password_confirm: touched.password_confirm ? errors.password_confirm : null,
  };

  const resetForm = formik.resetForm;
  useEffect(() => {
    if (!open) {
      return () => resetForm();
    }
  }, [open, resetForm]);

  return (
    <FormDialog
      open={open}
      onClose={() => onClose(false)}
      onSubmit={formik.handleSubmit}
      maxWidth="lg"
    >
      {!onSuccess ? (
        <>
          <DialogTitle disableTypography>Cambiar Contraseña</DialogTitle>
          {error && (
            <DialogContent>
              <Alert severity="error">{error}</Alert>
            </DialogContent>
          )}
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex">
                  <TextField
                    error={Boolean(visibleErrors.old_password)}
                    helperText={visibleErrors.old_password}
                    label="Contraseña Antigua"
                    id="old_password"
                    data-testid="change-password-old_password"
                    type="password"
                    placeholder="Contraseña Antigua"
                    autoComplete="current-password"
                    {...formik.getFieldProps('old_password')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <TextField
                    error={Boolean(visibleErrors.password)}
                    helperText={visibleErrors.password}
                    label="Contraseña Nueva"
                    id="password"
                    data-testid="change-password-password"
                    type="password"
                    placeholder="Contraseña"
                    InputProps={{
                      endAdornment: <PasswordInfo />,
                    }}
                    autoComplete="new-password"
                    {...formik.getFieldProps('password')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <TextField
                    error={Boolean(visibleErrors.password_confirm)}
                    helperText={visibleErrors.password_confirm}
                    label="Confirmar Contraseña"
                    id="password_confirm"
                    data-testid="change-password-password_confirm"
                    type="password"
                    placeholder="Confirmar Contraseña"
                    {...formik.getFieldProps('password_confirm')}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="big-top-space">
            <Button
              id="btn-change-password-cancel"
              color="secondary"
              variant="contained"
              onClick={() => onClose(false)}
              className={classes.button}
            >
              cancelar
            </Button>
            <PrimaryButton
              id="btn-change-password-submit"
              data-testid="change-password-btn-submit"
              loading={isFetching}
              type="submit"
              className={classes.button}
            >
              Cambiar Contraseña
            </PrimaryButton>
          </DialogActions>
        </>
      ) : (
        <DialogContent>
          <SuccessFeedback
            title="Contraseña cambiada con éxito!"
            subtitle="La próxima vez que inicies sesión será con esta contraseña"
          />
        </DialogContent>
      )}
    </FormDialog>
  );
}
