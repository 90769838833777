import React, { useMemo } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  IconButton,
  Box,
} from '@material-ui/core';
import { EditIcon } from '../../../../components/icons/EditIcon';
import { GarbageIcon } from '../../../../components/icons/GarbageIcon';
import { parseAssignedKpisToTableData, AssignedKpisTableData } from './utils';
import { UserAssignedKpisTableProps } from './types';

export function UserAssignedKpisTable({
  dimensions,
  assignedKpis,
  onEditClick,
  onDeleteClick,
}: UserAssignedKpisTableProps) {
  const data: AssignedKpisTableData = useMemo(
    () => parseAssignedKpisToTableData(assignedKpis),
    [assignedKpis],
  );

  const columns = useMemo(
    function createTableColumns() {
      return [
        { key: 'user-assingment-kpis-column-country', value: 'País' },
        { key: 'user-assingment-kpis-column-brand', value: 'Marca' },
        ...dimensions.map((d) => ({
          key: `user-assingment-kpis-column-${d.id_dimension}`,
          value: d.name_dimension,
        })),
        { key: 'user-assingment-kpis-column-action', value: '' },
      ];
    },
    [dimensions],
  );
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map(({ key, value }) => (
            <TableCell key={key}>{value}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      {data?.length ? (
        <TableBody>
          {data.map((row, i) => {
            return (
              <TableRow key={`user-assingment-kpis-row-${i}`}>
                <TableCell>{row.name_country}</TableCell>
                <TableCell>{row.name_brand}</TableCell>
                {dimensions.map(({ id_dimension }) => {
                  return (
                    <TableCell
                      key={`user-assingment-kpis-cell-${id_dimension}-type`}
                    >
                      {row.dimensions.get(id_dimension) ?? 0}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Box display="flex">
                    <Box>
                      {onEditClick && (
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() =>
                            onEditClick(row.id_country, row.id_brand)
                          }
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                    <Box>
                      {onDeleteClick && (
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() =>
                            onDeleteClick(row.id_country, row.id_brand)
                          }
                        >
                          <GarbageIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableFooter>
          <TableRow key={`user-assingment-kpis-row-empty`}>
            <TableCell colSpan={columns.length}>
              No hay KPIs asignados
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
}
