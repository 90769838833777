import { combineReducers } from 'redux';
import authReducer from './auth';
import kpiReducer from './kpis';
import countriesReducer from './countries';
import brandsReducer from './brands';
import categoriesReducer from './categories';
import dimensionsReducer from './dimensions';
import userAssignmentsKpisReducer from './user-assignments-kpis';
import userReducers from './users';

export default combineReducers({
  auth: authReducer,
  kpis: kpiReducer,
  countries: countriesReducer,
  brands: brandsReducer,
  categories: categoriesReducer,
  dimensions: dimensionsReducer,
  userAssignmentsKpis: userAssignmentsKpisReducer,
  users: userReducers,
});
