import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { listKpisBrandsAction } from '../store/actions/kpis/brands';
import { Brand } from '../types/entities/brand';

export function useBrandSelector(
  country: string,
  initialBrand?: string,
): [Brand[], string, Dispatch<SetStateAction<string>>, boolean, boolean] {
  const [brand, setBrand] = useState<string>(initialBrand ?? '');
  const dispatch = useDispatch();
  const { brands, loading } = useSelector(
    (store: any) => ({
      brands: store.kpis.brand.data as Brand[],
      loading: store.kpis.brand.loading as boolean,
    }),
    shallowEqual,
  );
  const [disable, setDisable] = useState(!country);

  useEffect(() => {
    // request brands
    if (country) {
      setDisable(false);
      dispatch(listKpisBrandsAction(parseInt(country, 10)));
    } else {
      setDisable(true);
    }
  }, [dispatch, country]);

  useEffect(() => {
    if (!brands || !brands.find((b) => `${b.id_brand}` === brand)) {
      setBrand('');
    }
  }, [brands, brand]);

  return [brands, brand, setBrand, disable, loading];
}
