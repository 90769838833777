import { produce, Draft } from 'immer';
import {
  ListAssignmentsCountriesSuccededAction,
  ListAssignmentsBrandsSuccededAction,
} from '../actions/user-assignments/types';
import { SessionExpiredAction } from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';
import {
  BrandWithAssignable,
  CountryWithAssignable,
  KpiWithAssignable,
  ListAssignmentsKpisSuccededAction,
} from '../actions/user-assignments/types';

import {
  LIST_ASSIGNMENTS_COUNTRIES,
  LIST_ASSIGNMENTS_COUNTRIES_SUCCEEDED,
  LIST_ASSIGNMENTS_COUNTRIES_FAILED,
} from '../actions/user-assignments/countries';
import {
  LIST_ASSIGNMENTS_BRANDS,
  LIST_ASSIGNMENTS_BRANDS_SUCCEEDED,
  LIST_ASSIGNMENTS_BRANDS_FAILED,
} from '../actions/user-assignments/brands';
import {
  LIST_ASSIGNMENTS_KPIS,
  LIST_ASSIGNMENTS_KPIS_SUCCEEDED,
  LIST_ASSIGNMENTS_KPIS_FAILED,
} from '../actions/user-assignments/kpis';

interface KpiResource<T> {
  data: T[];
  loading: boolean;
}

interface KpiState {
  readonly countries: KpiResource<CountryWithAssignable>;
  readonly brands: KpiResource<BrandWithAssignable>;
  readonly kpis: KpiResource<KpiWithAssignable>;
}

const initial: KpiState = {
  countries: {
    loading: true,
    data: [],
  },
  brands: {
    loading: true,
    data: [],
  },
  kpis: {
    loading: true,
    data: [],
  },
};

const applyChanges = produce(
  (
    draftState: Draft<KpiState>,
    action:
      | ListAssignmentsCountriesSuccededAction
      | ListAssignmentsBrandsSuccededAction
      | SessionExpiredAction
      | { type?: string; payload?: any },
  ) => {
    switch (action.type) {
      case LIST_ASSIGNMENTS_COUNTRIES:
        draftState.countries.loading = true;
        break;
      case LIST_ASSIGNMENTS_COUNTRIES_SUCCEEDED:
        draftState.countries.data = action.payload as Array<
          CountryWithAssignable
        >;
        draftState.countries.loading = false;
        break;
      case LIST_ASSIGNMENTS_COUNTRIES_FAILED:
        draftState.countries.loading = false;
        break;
      case LIST_ASSIGNMENTS_BRANDS:
        draftState.brands.loading = true;
        break;
      case LIST_ASSIGNMENTS_BRANDS_SUCCEEDED:
        draftState.brands.data = action.payload as Array<BrandWithAssignable>;
        draftState.brands.loading = false;
        break;
      case LIST_ASSIGNMENTS_BRANDS_FAILED:
        draftState.brands.loading = false;
        break;
      case LIST_ASSIGNMENTS_KPIS:
        draftState.kpis.loading = true;
        break;
      case LIST_ASSIGNMENTS_KPIS_SUCCEEDED:
        draftState.kpis.data = action.payload as Array<KpiWithAssignable>;
        draftState.kpis.loading = false;
        break;
      case LIST_ASSIGNMENTS_KPIS_FAILED:
        draftState.kpis.loading = false;
        break;
      case SESSION_EXPIRED:
        return initial;
      default:
        break;
    }
  },
);

export default (
  state: KpiState = initial,
  action:
    | ListAssignmentsCountriesSuccededAction
    | ListAssignmentsBrandsSuccededAction
    | ListAssignmentsKpisSuccededAction
    | SessionExpiredAction
    | { type?: string },
): KpiState => {
  return applyChanges(state, action);
};
