import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  makeStyles,
  Snackbar,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { BootstrapInput } from '../../components/BootstrapInput';
import { PrimaryButton } from '../../components/buttons';
import { FormDialog } from '../../components/FormDialog';
import { HorizontalGrid as Grid } from '../../components/HorizontalGrid';
import api from '../../services/password-reset';
import { PasswordForgotSuccess } from './components/PasswordForgotSuccess';
import { isEmail } from '../../services/email-validation';

interface FormValues {
  email: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 35,
    maxWidth: 446,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 510,
    },
  },
  formControl: {
    width: '100%',
  },
  input: {
    marginBottom: 10,
  },
  label: {
    color: '#000000',
    transform: 'translate(0, 1.5px) scale(1)',
  },
  button: {
    margin: 'auto',
    flex: 'unset!important',
    width: 234,
  },
}));

export function PasswordForgot() {
  const location = useLocation<{ badLink?: any }>();
  const isBadLink = Boolean(location.state?.badLink);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      try {
        setIsFetching(true);
        await api.send({ email });
        setIsSuccess(true);
      } catch (error) {
        setOpen(true);
      } finally {
        setIsFetching(false);
      }
    },
    validate: (values) => {
      const errors: Partial<FormValues> = {};
      if (!values.email) {
        errors.email = 'Requerido';
      } else if (!isEmail(values.email)) {
        errors.email = 'No es un email válido';
      }
      return errors;
    },
  });

  const onClose = () => setOpen(false);

  const { touched, errors } = formik;
  const visibleErrors = {
    email: touched.email ? errors.email : null,
  };

  if (isSuccess) {
    return <PasswordForgotSuccess />;
  }

  return (
    <>
      <FormDialog
        open
        disableBackdropClick
        className="featured"
        onSubmit={formik.handleSubmit}
        PaperProps={{ className: classes.paper }}
      >
        <DialogTitle disableTypography>
          <div className="Signin-logo"></div>
          <div className="Signin-welcome">Recuperar Contraseña</div>
        </DialogTitle>
        {isBadLink ? (
          <DialogContent>
            <Alert severity="error">
              El vínculo para recuperar contraseña no es válido o ya se ha
              utilizado.
            </Alert>
          </DialogContent>
        ) : null}
        <DialogContent>
          <Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                required
                error={Boolean(visibleErrors.email)}
              >
                <InputLabel shrink htmlFor="email" className={classes.label}>
                  Correo Electrónico
                </InputLabel>
                <BootstrapInput
                  className={classes.input}
                  id="email"
                  data-testid="forgot-password-email"
                  type="email"
                  placeholder="ejemplo@stellantis.com"
                  {...formik.getFieldProps('email')}
                />
                {visibleErrors.email ? (
                  <FormHelperText error>{visibleErrors.email}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            id="btn-submit"
            data-testid="forgot-password-btn-local-account"
            loading={isFetching}
            type="submit"
            className={classes.button}
          >
            Recuperar
          </PrimaryButton>
        </DialogActions>
        <DialogContent>
          <Box textAlign="center">
            Volver a{' '}
            <Link
              component={RouterLink}
              to="/signin"
              style={{ fontWeight: 'bold' }}
            >
              Iniciar sesión
            </Link>
          </Box>
        </DialogContent>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert id="alert-forgot-password-error" severity="error">
            Problema al solicitar restablecer la contraseña. Intente más tarde.
          </Alert>
        </Snackbar>
      </FormDialog>
    </>
  );
}
