import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { ManualLoadSelection } from './ManualLoadSelection';
import { ManualLoadEdition, Selection } from './ManualLoadEdition';
import { SuccessFeedback } from '../../../../components/feedback/SuccessFeedback';
import { FailFeedback } from '../../../../components/feedback/FailFeedback';
import { useTimeout } from '../../../../hooks/useTimeout';
import api from '../../../../services/api';
import { Country } from '../../../../types/entities/country';
import { useDispatch, useSelector } from 'react-redux';
import { listBrandsAction } from '../../../../store/actions/brands';
import { KpiRegistry } from '../../../../types/entities/registry';

enum Steps {
  SELECTION = 'selection',
  EDITION = 'edition',
  LOADING = 'loading',
  EDITION_SUCCEEDED = 'edition-succeded',
  EDITION_FAILED = 'edition-failed',
}

interface ManualLoadKPIsProps {
  countries: Country[];
  onClose: () => void;
}

interface InputData {
  country?: number;
  brand?: number;
  month?: number;
  year?: number;
}

const buildData = (selection: Selection, kpis: KpiRegistry[]): any => {
  const { month, year, brand: id_brand, country: id_country } = selection;
  return {
    month,
    year,
    id_brand,
    id_country,
    kpis: kpis.map((kpi) => ({
      id_kpi: kpi.id_kpi.toString(),
      value: kpi.value,
    })),
  };
};

const ManualLoadKPIs = ({ countries, onClose }: ManualLoadKPIsProps) => {
  const [step, setStep] = useState<Steps>(Steps.SELECTION);
  const [selection, setSelection] = useState<InputData>({});
  const dispatch = useDispatch();
  const brands = useSelector((store: any) => store.brands.data);
  const [lastData, setLastData] = useState<KpiRegistry[]| undefined>();

  const [startTimeout, cancel] = useTimeout(onClose);

  const close = useCallback(() => {
    onClose();
  }, [onClose]);

  const persistKpisValues = async (kpis: KpiRegistry[]) => {
    setLastData(kpis);
    setStep(Steps.LOADING);

    try {
      const data = buildData(selection as Selection, kpis);
      await api.loadKpisByUser(data);
      setStep(Steps.EDITION_SUCCEEDED);
      startTimeout(2000);
    } catch (e) {
      setStep(Steps.EDITION_FAILED);
    }
  };

  useEffect(() => {
    return function cleanUp() {
      cancel();
    };
  }, [cancel]);

  useEffect(() => {
    dispatch(listBrandsAction());
  }, [dispatch]);

  return (
    <Dialog open={true} onClose={close}>
      {
        {
          [Steps.SELECTION]: (
            <ManualLoadSelection
              brands={brands}
              countries={countries}
              onSelect={(data) => {
                setSelection(data);
                setStep(Steps.EDITION);
              }}
              onCancel={close}
            />
          ),
          [Steps.EDITION]: (
            <ManualLoadEdition
              selection={selection as Selection}
              onConfirm={persistKpisValues}
              onCancel={close}
            />
          ),
          [Steps.LOADING]: (
            <DialogContent>
              <Box textAlign="center">
                <CircularProgress style={{ margin: 'auto' }} />
              </Box>
            </DialogContent>
          ),
          [Steps.EDITION_SUCCEEDED]: (
            <DialogContent>
              <SuccessFeedback
                title="¡Carga de KPI's exitosa!"
                subtitle="Ya puedes ir al listado y revisar los detalles"
              />
            </DialogContent>
          ),
          [Steps.EDITION_FAILED]: (
            <DialogContent>
              <FailFeedback
                title="Ups, ha ocurrido un problema inesperado"
                subtitle="Por favor intentalo de nuevo"
                onRetry={() => persistKpisValues(lastData as KpiRegistry[])}
                retry
              />
            </DialogContent>
          ),
        }[step]
      }
    </Dialog>
  );
};

export { ManualLoadKPIs };
