import { useEffect, useCallback, useRef, useState } from 'react';

/**
 * Hook that allow to run a function delayed by a timeout
 */
export function useTimeout(
  callback: () => void,
): [(timer: number) => void, () => void] {
  const [start, setStart] = useState<number | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    if (start) {
      timeoutIdRef.current = setTimeout(() => {
        setStart(null);
        callback();
      }, start);

      return cancel;
    }
  }, [start, callback, cancel]);


  useEffect(() => {
    return function cleanUp() {
      setStart(null);
    };
  }, []);

  return [setStart, cancel];
}
