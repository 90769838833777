import React, { useState, useEffect, useCallback } from 'react';
import { pick } from 'lodash';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TableContainer,
  Box,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ListLightIcon } from '../../../../components/icons/ListLightIcon';
import {
  KpiEditableTable,
  KpiRow,
  KpiEditableTableRow,
} from './KpiEditableTable';
import api from '../../../../services/api';
import {
  RegistryViewModel,
  KpiRegistry,
} from '../../../../types/entities/registry';

export interface Selection {
  country: number;
  brand: number;
  month: number;
  year: number;
}

interface ManualLoadEdition {
  selection: Selection;
  onConfirm: (data: KpiRegistry[]) => void;
  onCancel: () => void;
}

const transformKpis = (data: RegistryViewModel[]): KpiRow[] => {
  return data.map((row) => ({
    ...row,
    value: row.value ? row.value.toString() : '',
    observation: 'OK',
  }));
};

const removeUnnecessaryDataFrom = (
  kpis: KpiEditableTableRow[],
): KpiRegistry[] => {
  return kpis.map((row) => pick(row, ['id_kpi', 'name_kpi', 'value']));
};

const removeEmptyKpis = (kpis: KpiRegistry[]): KpiRegistry[] => {
  return kpis.filter((row) => Boolean(row.value));
};

const isKpisValid = (data: KpiEditableTableRow[]): boolean => {
  return !data.some((row) => row.status === 'error');
};

const isKpisEmpty = (data: KpiEditableTableRow[]): boolean => {
  return data.filter((row) => Boolean(row.value)).length === 0;
};

const ManualLoadEdition = ({
  selection,
  onConfirm,
  onCancel,
}: ManualLoadEdition) => {
  const [originalKpis, setOriginalKpis] = useState<KpiRow[]>([]);
  const [updatedKpis, setUpdatedKpis] = useState<KpiEditableTableRow[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [editRowsCounter, setEditRowsCounter] = useState(0);

  const onEdit = (kpis: KpiEditableTableRow[]) => {
    const isValid = isKpisValid(kpis);
    const isEmpty = isKpisEmpty(kpis);

    if (!isValid) {
      setErrorMsg('Debes corregir los errores para poder confirmar');
    } else if (isEmpty) {
      setErrorMsg('Al menos debe haber un KPI con valor');
    } else {
      setErrorMsg('');
    }

    setUpdatedKpis(kpis);
  };

  const onEditionEnabled = () => {
    setEditRowsCounter((prev: number) => prev + 1);
  };

  const onEditionDisabled = () => {
    setEditRowsCounter((prev: number) => prev - 1);
  };

  const onClick = useCallback(() => {
    onConfirm(removeEmptyKpis(removeUnnecessaryDataFrom(updatedKpis)));
  }, [onConfirm, updatedKpis]);

  useEffect(() => {
    async function fetchKpis() {
      try {
        const { data } = await api.listKpis(selection);
        setOriginalKpis(transformKpis(data));
        onEdit(transformKpis(data) as KpiEditableTableRow[]);
      } catch (e) {}
    }

    fetchKpis();
  }, [selection]);

  return (
    <>
      <DialogTitle disableTypography>
        <ListLightIcon color="primary" />
        Carga manual de KPI's
      </DialogTitle>
      <DialogContent>
        <TableContainer style={{ maxHeight: 400 }}>
          <KpiEditableTable
            data={originalKpis}
            onEdit={onEdit}
            onEditionEnabled={onEditionEnabled}
            onEditionDisabled={onEditionDisabled}
          />
        </TableContainer>
        {errorMsg && (
          <Box color="error.main" style={{ marginTop: 30 }}>
            {errorMsg}
          </Box>
        )}
        {editRowsCounter > 0 && (
          <Alert severity="info" style={{ marginTop: 30 }}>
            Se encuentra en modo edición. Para poder guardar los datos haga
            click fuera de la celda en edición o presione la tecla enter.
          </Alert>
        )}
      </DialogContent>
      <DialogActions className="big-top-space">
        <Button variant="contained" color="secondary" onClick={onCancel}>
          cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!updatedKpis || Boolean(errorMsg) || editRowsCounter > 0}
          onClick={onClick}
        >
          confirmar
        </Button>
      </DialogActions>
    </>
  );
};

export { ManualLoadEdition };
