import {
  Box,
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
  Button,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { KpisStatusDetail } from '../../../types/entities/kpis-status';

const StyledSubTableCell = withStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        '.MuiTableRow-root:last-child &': {
          borderBottom: 'none',
        },
        '&:first-child': {
          paddingLeft: 40,
        },
      },
      footer: {
        '.MuiTableRow-root:last-child &': {
          borderBottom: 'none',
        },
      },
    }),
  {
    name: 'StyledSubTableCell',
  },
)(TableCell);

export interface KpisStatusDetailsTableProps {
  details?: KpisStatusDetail[];
  onShowDetailsUser?: (id_user: number, expectedRows?: number) => void;
}

const KpisStatusDetailsTable = ({
  details,
  onShowDetailsUser,
}: KpisStatusDetailsTableProps) => {
  const subHeadersCount = 6;
  const showDetailsUser = useCallback(
    (id_user: number, expectedRows?: number) => {
      if (onShowDetailsUser) {
        onShowDetailsUser(id_user, expectedRows);
      }
    },
    [onShowDetailsUser],
  );
  return (
    <>
      <TableRow>
        <TableCell variant="head" size="small" colSpan={3}>
          Usuario
        </TableCell>
        <TableCell variant="head" size="small">
          KPI
        </TableCell>
        <TableCell variant="head" size="small">
          Última Carga
        </TableCell>
        <TableCell variant="head" size="small"></TableCell>
      </TableRow>
      {details?.length ? (
        <>
          {details.map((detail) => {
            const color =
              detail.kpis_loaded >= detail.kpis_assigned
                ? 'success'
                : detail.kpis_loaded === 0
                ? 'error'
                : 'warning';
            return (
              <TableRow key={`status_detail_${detail.id_user}`}>
                <StyledSubTableCell size="small" colSpan={3}>
                  {detail.id_user
                    ? `${detail.first_name} ${detail.last_name}`
                    : 'Sin Asignar'}
                </StyledSubTableCell>
                <StyledSubTableCell size="small">
                  <Box color={`${color}.main`} whiteSpace="nowrap">
                    {`${detail.kpis_loaded}/${detail.kpis_assigned} Cargados`}
                  </Box>
                </StyledSubTableCell>
                <StyledSubTableCell size="small">
                  {detail.created_at
                    ? format(new Date(detail.created_at), 'dd/MM/yyyy')
                    : null}
                </StyledSubTableCell>
                <StyledSubTableCell
                  size="small"
                  align="right"
                  style={{ height: 42 }}
                >
                  {detail.id_user && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        showDetailsUser(detail.id_user, detail.kpis_assigned)
                      }
                      size="small"
                      style={{ textTransform: 'none' }}
                    >
                      Ver Detalles
                    </Button>
                  )}
                </StyledSubTableCell>
              </TableRow>
            );
          })}
        </>
      ) : (
        <TableRow>
          <StyledSubTableCell colSpan={subHeadersCount} variant="footer">
            No hay datos
          </StyledSubTableCell>
        </TableRow>
      )}
    </>
  );
};

export default React.memo(KpisStatusDetailsTable);
