import axios, { AxiosResponse } from 'axios';
import { API_URL } from './constants';

const axiosApi = axios.create({
  baseURL: `${API_URL}/password-resets`,
});

export default {
  check: async (checkData: { token: string }): Promise<AxiosResponse> => {
    return axiosApi.post(`check`, checkData);
  },
  reset: async (resetData: {
    email: string;
    password: string;
    token: string;
  }): Promise<AxiosResponse> => {
    return axiosApi.post(`reset`, resetData);
  },
  send: async (sendData: { email: string }): Promise<AxiosResponse> => {
    return axiosApi.post(`send`, sendData);
  },
};
