import { produce, Draft } from 'immer';
import {
  SIGNIN_SUCCEEDED,
  SIGNIN_FAILED,
  USER_SIGNIN,
} from '../actions/auth/signin';
import { SIGNOUT_SUCCEEDED } from '../actions/auth/signout';
import { REQUEST_PROFILE_SUCCEEDED } from '../actions/auth/profile';
import {
  SigninResponseAction,
  SigninFailedAction,
  SignoutResponseAction,
  RequestProfileResponseAction,
  RequestProfileSuccededAction,
  SessionExpiredAction,
} from '../actions/auth/types';
import { SESSION_EXPIRED } from '../actions/auth/session';

export interface ProfileState {
  id: number | null;
  name: string | null;
  roles: number[];
}

export interface AuthState {
  readonly logged: boolean;
  readonly isFetching: boolean;
  readonly httpError: null | { status: number; message: string };
  readonly profile: ProfileState;
}

const initial: AuthState = {
  logged: false,
  isFetching: false,
  httpError: null,
  profile: {
    id: null,
    name: null,
    roles: [],
  },
};

const getName = function (firstName: string, lastName: string | null): string {
  const first = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
  const last: Array<string> = [' '];

  lastName?.split(' ').forEach((value: string) => {
    last.push(`${value.charAt(0).toUpperCase()}${value.slice(1)}`);
  });

  return `${first} ${last.join(' ')}`;
};

const applyChanges = produce(
  (
    draftState: Draft<AuthState>,
    action:
      | SigninResponseAction
      | SignoutResponseAction
      | RequestProfileResponseAction
      | SessionExpiredAction
      | { type?: string },
  ) => {
    switch (action.type) {
      case USER_SIGNIN:
        draftState.isFetching = true;
        break;
      case SIGNIN_SUCCEEDED:
        draftState.logged = true;
        draftState.isFetching = false;
        draftState.httpError = null;
        break;
      case SIGNIN_FAILED:
        draftState.logged = false;
        draftState.isFetching = false;
        draftState.httpError = (action as SigninFailedAction).payload;
        break;
      case REQUEST_PROFILE_SUCCEEDED:
        const {
          id,
          firstName,
          lastName,
          roles,
        } = (action as RequestProfileSuccededAction).payload;
        draftState.profile.name = getName(firstName, lastName);
        draftState.profile.id = id;
        draftState.profile.roles = roles;
        draftState.logged = true;
        break;
      case SIGNOUT_SUCCEEDED:
        return initial;
      case SESSION_EXPIRED:
        return initial;
    }
  },
);

export default (
  state: AuthState = initial,
  action:
    | SigninResponseAction
    | SignoutResponseAction
    | RequestProfileResponseAction
    | SessionExpiredAction
    | { type?: string } = { type: '' },
): AuthState => {
  return applyChanges(state, action);
};
