import React, { useCallback, useState } from 'react';
import {
  Button,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { HorizontalGrid as Grid } from '../HorizontalGrid';
import { useOnChangeEventToSet } from '../../hooks/useOnChangeEventToSet';

const useStyles = makeStyles({
  button: {
    width: '100%',
  },
});
export interface PopoverContentRadioProps {
  value?: string;
  options: { label: string; value: string }[];
  onApply: (value?: string) => void;
}

export default function PopoverContentRadio({
  value,
  options,
  onApply,
}: PopoverContentRadioProps) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState<string | null>(
    value ?? null,
  );

  const handleClean = useCallback(() => {
    setSelectedValue(null);
  }, []);

  const handleApply = useCallback(() => {
    if (selectedValue === null || selectedValue === undefined) {
      onApply(undefined);
    } else {
      onApply(selectedValue);
    }
  }, [onApply, selectedValue]);

  const handleChange = useOnChangeEventToSet(setSelectedValue);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup
          aria-label="filter by active state"
          value={selectedValue}
          onChange={handleChange}
          row
        >
          <Grid container spacing={2}>
            {options.map((option, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <FormControlLabel
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={handleClean}
          className={classes.button}
        >
          Limpiar
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleApply}
          className={classes.button}
        >
          Aplicar
        </Button>
      </Grid>
    </Grid>
  );
}
