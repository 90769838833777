import {
  BrandWithAssignable,
  ListAssignmentsBrandsAction,
  ListAssignmentsBrandsFailedAction,
  ListAssignmentsBrandsSuccededAction,
} from './types';

export const LIST_ASSIGNMENTS_BRANDS = 'LIST_ASSIGNMENTS_BRANDS';
export const LIST_ASSIGNMENTS_BRANDS_SUCCEEDED =
  'LIST_ASSIGNMENTS_BRANDS_SUCCEEDED';
export const LIST_ASSIGNMENTS_BRANDS_FAILED = 'LIST_ASSIGNMENTS_BRANDS_FAILED';

export const listBrandsAction = (): ListAssignmentsBrandsAction => ({
  type: LIST_ASSIGNMENTS_BRANDS,
});

export const notifyListAssignmentsBrandsSucceeded = (
  brands: BrandWithAssignable[],
): ListAssignmentsBrandsSuccededAction => ({
  type: LIST_ASSIGNMENTS_BRANDS_SUCCEEDED,
  payload: brands,
});

export const notifyListAssignmentsBrandsFailed = (
  status: number,
  message: string,
): ListAssignmentsBrandsFailedAction => ({
  type: LIST_ASSIGNMENTS_BRANDS_FAILED,
  payload: {
    status,
    message,
  },
});
