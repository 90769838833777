import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  Theme,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { ListLightIcon } from '../../../../components/icons/ListLightIcon';
import { HorizontalGrid } from '../../../../components/HorizontalGrid';
import { useDependentLoaderSelector } from '../../../../hooks/loaders/useDependentLoaderSelector';
import {
  CountryWithAssignable,
  BrandWithAssignable,
  KpiWithAssignable,
} from '../../../../store/actions/user-assignments/types';
import { listCountriesAction } from '../../../../store/actions/user-assignments/countries';
import { listBrandsAction } from '../../../../store/actions/user-assignments/brands';
import { listKpisAction } from '../../../../store/actions/user-assignments/kpis';
import { UserAssignedKpisModifyTable } from './UserAssignedKpisModifyTable';
import { useLoaderSelector } from '../../../../hooks/loaders/useLoaderSelector';
import { useDependentLoader } from '../../../../hooks/loaders/useDependentLoader';
import { UserAssignedKpi } from '../../../../types/entities/user';
import { useOnChangeEventToSet } from '../../../../hooks/useOnChangeEventToSet';
import {
  createIsValueInOptions,
  createFindInOptions,
  getFromUserAssignmentsKpisStore,
} from './utils';
import { UserAssignedKpisModifyProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiGrid-item:last-child': {
      paddingTop: theme.spacing(4),
    },
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
  },
  text: {
    fontWeight: 'bold',
    color: '#1D1D1B',
  },
  button: {
    flex: 1,
  },
}));

const isCountryValueInOptions = createIsValueInOptions('id_country');
const isBrandValueInOptions = createIsValueInOptions('id_brand');

const findCountryValueInOptions = createFindInOptions<CountryWithAssignable>(
  'id_country',
);
const findBrandValueInOptions = createFindInOptions<BrandWithAssignable>(
  'id_brand',
);

const getCountriesFromStore = getFromUserAssignmentsKpisStore<
  CountryWithAssignable[]
>('countries');
const getBrandsFromStore = getFromUserAssignmentsKpisStore<
  BrandWithAssignable[]
>('brands');
const getKpisFromStore = getFromUserAssignmentsKpisStore<KpiWithAssignable[]>(
  'kpis',
);

export function UserAssignedKpisModify({
  open,
  assignedKpis,
  dimensions,
  defaultCountryId,
  defaultBrandId,
  userId,
  onConfirm,
  onCancel,
}: UserAssignedKpisModifyProps) {
  const classes = useStyles();

  const {
    options: countries,
    value: countryId,
    setValue: setCountry,
    loading: loadingCountries,
  } = useLoaderSelector(
    getCountriesFromStore,
    listCountriesAction,
    isCountryValueInOptions,
    defaultCountryId ? `${defaultCountryId}` : undefined,
  );
  const country = useMemo(
    () => findCountryValueInOptions(countryId, countries),
    [countries, countryId],
  );

  const onCountryChange = useOnChangeEventToSet(setCountry);

  const {
    options: brands,
    value: brandId,
    setValue: setBrand,
    loading: loadingBrands,
    disable: disableBrands,
  } = useDependentLoaderSelector(
    countryId,
    getBrandsFromStore,
    listBrandsAction,
    isBrandValueInOptions,
    defaultBrandId ? `${defaultBrandId}` : undefined,
  );

  const brand = useMemo(() => findBrandValueInOptions(brandId, brands), [
    brands,
    brandId,
  ]);

  const onBrandChange = useOnChangeEventToSet(setBrand);

  const actionArguments = useMemo(
    () =>
      countryId && brandId ? { country: countryId, brand: brandId, userId } : null,
    [countryId, brandId, userId],
  );
  const listKpisActionBuilder = useCallback(
    ({ country, brand, userId }) =>
      listKpisAction(parseInt(country, 10), parseInt(brand, 10), userId),
    [],
  );

  const { options: kpis, loading: loadingKpis } = useDependentLoader(
    actionArguments,
    getKpisFromStore,
    listKpisActionBuilder,
  );

  const [selectedKpis, setSelectedKpis] = useState<number[]>([]);

  useEffect(
    function filterSelectedKpis() {
      if (countryId && brandId) {
        const filteredAssignedKpis: number[] = assignedKpis
          .filter(
            (a) =>
              `${a.id_country}` === countryId && `${a.id_brand}` === brandId,
          )
          .map((a) => a.id_kpi);
        setSelectedKpis(filteredAssignedKpis);
      }
    },
    [assignedKpis, countryId, brandId],
  );

  const handleCancel = onCancel;

  const handleConfirm = useCallback(() => {
    if (!country || !brand) {
      return;
    }
    const { id_country, name_country } = country;
    const { id_brand, name_brand } = brand;
    const kpisById = new Map(kpis.map((k) => [k.id_kpi, k]));
    const newAssignments: UserAssignedKpi[] = selectedKpis
      .map((k) => kpisById.get(k))
      .filter<KpiWithAssignable>((k): k is KpiWithAssignable => Boolean(k))
      .map<UserAssignedKpi>((k) => ({
        id_country,
        name_country,
        id_brand,
        name_brand,
        id_kpi: k.id_kpi,
        id_dimension: k.id_dimension,
        name_kpi: k.name_kpi,
      }));
    const newAssignedKpis = assignedKpis
      .filter((a) => a.id_country !== id_country || a.id_brand !== id_brand)
      .concat(newAssignments);
    onConfirm(newAssignedKpis);
  }, [kpis, assignedKpis, selectedKpis, onConfirm, country, brand]);

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle disableTypography>
        <ListLightIcon color="primary" />
        Asignación de KPI
      </DialogTitle>
      <DialogContent>
        <HorizontalGrid container spacing={3}>
          <HorizontalGrid item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              disabled={loadingCountries}
              required
            >
              <InputLabel htmlFor="select-country-kpi-template">
                País
              </InputLabel>
              <Select
                native
                inputProps={{
                  id: 'select-country-kpi-template',
                  name: 'country',
                }}
                value={countryId}
                onChange={onCountryChange}
                disabled={loadingCountries}
              >
                <option value="" aria-label="None" />
                {loadingCountries && countryId ? (
                  <option key={`kpi-country-default`} value={countryId}>
                    Cargando...
                  </option>
                ) : (
                  countries.map((value) => (
                    <option
                      key={`kpi-country-${value.id_country}`}
                      value={value.id_country}
                    >
                      {value.name_country}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          </HorizontalGrid>
          <HorizontalGrid item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              disabled={disableBrands || loadingBrands}
              required
            >
              <InputLabel htmlFor="select-brand-kpi-template">Marca</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'select-brand-kpi-template',
                  name: 'brand',
                }}
                value={brandId}
                onChange={onBrandChange}
                disabled={disableBrands || loadingBrands}
              >
                <option value="" aria-label="None" />
                {loadingBrands && brandId ? (
                  <option key={`kpi-brand-default`} value={brandId}>
                    Cargando...
                  </option>
                ) : (
                  brands.map((value) => (
                    <option
                      key={`kpi-brand-${value.id_brand}`}
                      value={`${value.id_brand}`}
                      disabled={!value.assignable}
                    >
                      {value.name_brand}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          </HorizontalGrid>
        </HorizontalGrid>
      </DialogContent>
      <DialogContent>
        <DialogContentText className={classes.text}>KPI</DialogContentText>
      </DialogContent>
      <DialogContent>
        {countryId && brandId ? (
          !loadingKpis ? (
            <UserAssignedKpisModifyTable
              allKpis={kpis}
              selectedKpis={selectedKpis}
              dimensions={dimensions}
              onChange={setSelectedKpis}
            />
          ) : (
            <Box textAlign="center">
              <CircularProgress style={{ margin: 'auto' }} />
            </Box>
          )
        ) : (
          <DialogContentText>
            Selecciona un país y marca para asignar KPIs
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="big-top-space">
        <Button
          id="btn-kpi-template-cancel"
          color="secondary"
          variant="contained"
          onClick={handleCancel}
          className={classes.button}
        >
          Cancelar
        </Button>
        <Button
          id="btn-kpi-template-generate"
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          className={classes.button}
          disabled={!country || !brand || loadingKpis}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
