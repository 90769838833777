import React from 'react';

import { Skeleton, SkeletonProps } from '@material-ui/lab';

const TextSkeleton = (props: SkeletonProps) => (
  <Skeleton
    style={{ flex: 1 }}
    animation="wave"
    height={35}
    component="div"
    {...props}
  />
);

export { TextSkeleton };
