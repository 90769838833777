import { all } from 'redux-saga/effects';
import authRootSaga from './auth';
import kpiRootSaga from './kpis';
import countriesSaga from './countries';
import brandsSaga from './brands';
import categoriesSaga from './categories';
import dimensionsSaga from './dimensions';
import userAssignmentsKpisSaga from './user-assignments-kpis';
import listUsersSaga from './users';

export default function* rootSaga() {
  yield all([
    authRootSaga(),
    kpiRootSaga(),
    countriesSaga(),
    brandsSaga(),
    categoriesSaga(),
    dimensionsSaga(),
    userAssignmentsKpisSaga(),
    listUsersSaga(),
  ]);
}
