import { Box } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Feedback } from '../../../components/feedback/Feedback';
import { AlertIcon } from '../../../components/icons/AlertIcon';
import { InformationIcon } from '../../../components/icons/InformationIcon';
import KpisStatusFilter, { KpisStatusFilterValues } from './KpisStatusFilter';
import KpisStatusGroup from './KpisStatusGroup';
import KpisStatusGroupLoading from './KpisStatusGroupLoading';
import { useDependentLoader } from '../../../hooks/loaders/useDependentLoader';
import { listKpisStatusAction } from '../../../store/actions/kpis/kpis-status';
import { KpisStatusGroup as KpisStatusGroupEntity } from '../../../types/entities/kpis-status';

function getKpisStatusFromStore(store: any) {
  return {
    options: store.kpis.kpisStatus.data as KpisStatusGroupEntity[],
    loading: store.kpis.kpisStatus.loading,
    error: store.kpis.kpisStatus.error,
  };
}

export interface KpisStatusProps {
  hasDetails: boolean;
}

enum KpisStatusStep {
  NOT_FILTERED,
  LOADING,
  ERROR,
  NO_DATA,
  DATA,
}

function getStep({
  loading,
  error,
  data,
  filtered,
}: {
  loading: boolean;
  error: any;
  data: any[];
  filtered: boolean;
}) {
  if (!filtered) {
    return KpisStatusStep.NOT_FILTERED;
  } else if (loading) {
    return KpisStatusStep.LOADING;
  } else if (error) {
    return KpisStatusStep.ERROR;
  } else if (data?.length) {
    return KpisStatusStep.DATA;
  } else {
    return KpisStatusStep.NO_DATA;
  }
}

export default function KpisStatus({ hasDetails }: KpisStatusProps) {
  const [alreadyFiltered, setAlreadyFiltered] = useState(false);

  const [filterValues, setFilterValues] = useState<
    KpisStatusFilterValues | undefined
  >();
  const { options: kpisStatus, loading, error } = useDependentLoader(
    filterValues,
    getKpisStatusFromStore,
    listKpisStatusAction,
  );

  if (!alreadyFiltered && loading) {
    setAlreadyFiltered(true);
  }
  const onFilterChange = setFilterValues;
  const idCountry = filterValues?.id_brand;
  const loadingArray = useMemo(
    () =>
      [...Array(idCountry ? 1 : 3)].map(
        () => Math.floor(Math.random() * 4) * 50 + 100,
      ),
    [idCountry],
  );

  const step = getStep({
    loading,
    error,
    data: kpisStatus,
    filtered: alreadyFiltered,
  });
  return (
    <>
      <Box mb={4}>
        <KpisStatusFilter onChange={onFilterChange} />
      </Box>
      {step === KpisStatusStep.LOADING &&
        loadingArray.map((v, i) => (
          <KpisStatusGroupLoading
            width={v}
            key={`${i}`}
            expectedRows={filterValues?.id_brand ? 3 : 15}
          />
        ))}
      {step === KpisStatusStep.NOT_FILTERED && (
        <Box mt={6} mb={10}>
          <Feedback
            startIcon={() => (
              <InformationIcon fontSize="large" color="primary" />
            )}
            title="¡Aplica los filtros!"
            subtitle="Modifica los filtros superiores y luego aplicalos para ver datos."
          />
        </Box>
      )}
      {step === KpisStatusStep.ERROR && (
        <Box mt={6} mb={10}>
          <Feedback
            startIcon={() => <AlertIcon fontSize="large" color="error" />}
            title="Ha ocurrido un error"
            subtitle={error!.message}
          />
        </Box>
      )}
      {step === KpisStatusStep.DATA &&
        kpisStatus.map((group) => (
          <KpisStatusGroup
            group={group}
            key={`group_${group.id_country}`}
            hasDetails={hasDetails}
          />
        ))}
      {step === KpisStatusStep.NO_DATA && (
        <Box mt={6} mb={10}>
          <Feedback
            startIcon={() => (
              <InformationIcon fontSize="large" color="primary" />
            )}
            title="No hay datos que mostrar"
            subtitle="Modifica los filtros superiores y luego aplicalos para ver datos."
          />
        </Box>
      )}
    </>
  );
}
