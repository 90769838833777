import React, { SVGProps } from 'react';
import {
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginBottom: 20,
    },
    title: {
      fontSize: 25,
      lineHeight: '30px',
      color: '#1D1D1B',
      marginBottom: 26,
    },
    body: {
      fontSize: 18,
      lineHeight: '22px',
      color: '#898C8D',
    },
  }),
);

export interface FeedbackDenseProps {
  title: string;
  startIcon: () => React.ReactElement<
    SVGProps<SVGSVGElement> & { title?: string }
  >;
  subtitle?: string;
}

export const FeedbackDense = ({
  title,
  startIcon,
  subtitle,
}: FeedbackDenseProps) => {
  const classes = useStyles();
  return (
    <>
      <Box textAlign="center" className={classes.icon}>
        {startIcon()}
      </Box>
      <Typography variant="h2" align="center" className={classes.title}>
        {title}
      </Typography>

      {subtitle && (
        <Typography variant="body2" align="center" className={classes.body}>
          {subtitle}
        </Typography>
      )}
    </>
  );
};
