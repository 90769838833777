import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RolesEnum } from '../types/enums/roles';
import { AuthState } from '../store/reducers/auth';

interface PrivateProps {
  redirectTo: string;
  checkRoles: boolean;
  allowedRoles?: Array<RolesEnum>;
}

type PrivateRouteProps = PrivateProps & RouteProps;

export default ({
  allowedRoles,
  redirectTo,
  children,
  checkRoles = false,
  ...rest
}: PrivateRouteProps) => {
  const {
    logged,
    profile: { roles },
  }: AuthState = useSelector((state: any) => state.auth, shallowEqual);

  /**
   * * If the user logged has at least one allowed role returns true
   */
  const isAllowed = useCallback(
    (roles: number[]) => {
      if (checkRoles) {
        const rolesSet = new Set(roles);
        return allowedRoles!.some((allowedRole) => rolesSet.has(allowedRole));
      }
      return true;
    },
    [allowedRoles, checkRoles],
  );

  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged && isAllowed(roles) ? (
          children
        ) : (
          <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
        )
      }
    ></Route>
  );
};
