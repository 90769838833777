import React from 'react';
import { Feedback } from './Feedback';
import { AlertIcon } from '../icons/AlertIcon';
import { Box } from '@material-ui/core';
import { PrimaryButton } from '../buttons';

interface FailFeedbackProps {
  title: string;
  subtitle?: string;
  retry?: boolean;
  onRetry?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const FailFeedback = ({
  title,
  subtitle,
  retry,
  onRetry,
}: FailFeedbackProps) => (
  <>
    <Feedback
      title={title}
      startIcon={() => <AlertIcon fontSize="large" color="error" />}
      subtitle={subtitle}
    />
    {retry && (
      <Box mt={5} mb={1} textAlign="center">
        <PrimaryButton onClick={onRetry}>Reintentar</PrimaryButton>
      </Box>
    )}
  </>
);
