import { takeLeading, takeLatest, call, put } from 'redux-saga/effects';
import {
  USER_SIGNIN,
  notifySigninSuccess,
  notifySigninFailure,
} from '../actions/auth/signin';
import { REQUEST_PROFILE, notifyProfileSuccess } from '../actions/auth/profile';

import {
  USER_SIGNOUT,
  notifySignoutFailure,
  notifySignoutSuccess,
  userSignout,
} from '../actions/auth/signout';
import { SigninRequestAction } from '../actions/auth/types';
import api from '../../services/api';
import { sessionExpired, SESSION_EXPIRED } from '../actions/auth/session';

export function* signinUserSaga({
  payload: { username, password },
}: SigninRequestAction) {
  try {
    yield call(api.signin, username, password);
    const { data } = yield call(api.me);
    yield put(notifyProfileSuccess(data));
    yield put(notifySigninSuccess());
  } catch (e) {
    yield put(
      e.response
        ? notifySigninFailure(e.response.status, e.response.data.message)
        : notifySigninFailure(0, 'Problema de conexión'),
    );
  }
}

export function* userProfileSaga() {
  try {
    const { data } = yield call(api.me);
    yield put(notifyProfileSuccess(data));
  } catch (e) {
    // For now just exit the user but maybe it's most suitable to first display a message of session expired
    if (e.response.status === 401) {
      yield put(sessionExpired());
    }
  }
}

export function* sessionExpiredSaga() {
  yield put(userSignout());
}

export function* signoutUserSaga() {
  try {
    yield call(api.signout);
    yield put(notifySignoutSuccess());
  } catch (e) {
    yield put(notifySignoutFailure());
  }
}

export default function* authRootSaga() {
  yield takeLeading(USER_SIGNIN, signinUserSaga);
  yield takeLeading(REQUEST_PROFILE, userProfileSaga);
  yield takeLatest(USER_SIGNOUT, signoutUserSaga);
  yield takeLatest(SESSION_EXPIRED, sessionExpiredSaga);
}
