import { Action, ListAction, FailedAction } from './types';
import { Dimension } from '../../types/entities/dimension';

export const LIST_DIMENSIONS = 'LIST_DIMENSIONS';
export const LIST_DIMENSIONS_SUCCEEDED = 'LIST_DIMENSIONS_SUCCEEDED';
export const LIST_DIMENSIONS_FAILED = 'LIST_DIMENSIONS_FAILED';

export type DimensionsAction = Action<'LIST_DIMENSIONS'>;
export type DimensionsSuccededAction = ListAction<
  'LIST_DIMENSIONS_SUCCEEDED',
  Dimension
>;
export type DimensionsFailedAction = FailedAction<'LIST_DIMENSIONS_FAILED'>;

export const listDimensionsAction = (): DimensionsAction => ({
  type: LIST_DIMENSIONS,
});

export const notifyListDimensionsSucceeded = (
  dimensions: Dimension[],
): DimensionsSuccededAction => ({
  type: LIST_DIMENSIONS_SUCCEEDED,
  payload: dimensions,
});

export const notifyListDimensionsFailed = (
  status: number,
  message: string,
): DimensionsFailedAction => ({
  type: LIST_DIMENSIONS_FAILED,
  payload: {
    status,
    message,
  },
});
