import React, { useCallback } from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';
import { UserListItem } from '../../../types/entities/user';
import Switch from '../../../components/Switch';
import { EditIcon } from '../../../components/icons/EditIcon';
import UsersTableHead from './UsersTableHead';
import { Feedback } from '../../../components/feedback/Feedback';
import { AlertIcon } from '../../../components/icons/AlertIcon';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: '100%',
    tableLayout: 'fixed',
    '& tbody:last-child tr:last-child td': {
      borderBottom: 'unset',
    },
    '& tfoot:last-child tr:last-child td': {
      borderBottom: 'unset',
    },
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
export interface UsersTableProps {
  data: UserListItem[];
  onSwitchClick?: (user: UserListItem) => void;
  onEdit: (id: number) => void;
}

export default function UsersTable({
  data,
  onSwitchClick,
  onEdit,
}: UsersTableProps) {
  const classes = useStyles();
  const headersLength = 9;

  const handleEdit = useCallback(
    (user: UserListItem) => {
      onEdit(user.id_user);
    },
    [onEdit],
  );

  return (
    <>
      <Table stickyHeader className={classes.table}>
        <UsersTableHead />
        {data?.length ? (
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.id_user}>
                <TableCell style={{ paddingLeft: 22 }}>
                  <Switch
                    checked={user.active}
                    readOnly
                    onClick={
                      onSwitchClick ? () => onSwitchClick(user) : undefined
                    }
                  />
                </TableCell>
                <TableCell className={classes.textOverflow}>
                  {user.first_name} {user.last_name}
                </TableCell>
                <TableCell className={classes.textOverflow}>
                  {user.country?.name_country}
                </TableCell>
                <TableCell className={classes.textOverflow}>
                  {user.category?.name}
                </TableCell>
                <TableCell
                  className={classes.textOverflow}
                  title={user.roles.map((r) => r.name).join('/')}
                >
                  {user.roles.map((r) => r.name).join('/')}
                </TableCell>
                <TableCell
                  className={classes.textOverflow}
                  title={user.brands.map((b) => b.name_brand).join('/')}
                >
                  {user.brands.map((b) => b.name_brand).join('/')}
                </TableCell>
                <TableCell
                  className={classes.textOverflow}
                  title={user.countries.map((b) => b.name_country).join('/')}
                >
                  {user.countries.map((b) => b.name_country).join('/')}
                </TableCell>
                <TableCell className={classes.textOverflow}>
                  <Box
                    color="primary.main"
                    fontWeight="bold"
                    className={classes.textOverflow}
                  >
                    {user.countKpis} Asignados
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => handleEdit(user)}
                    style={{ fontSize: 16 }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={headersLength}>
                <Box mt={2} mb={5}>
                  <Feedback
                    startIcon={() => (
                      <AlertIcon fontSize="large" color="primary" />
                    )}
                    title="No hay usuarios que coincidan con tu búsqueda."
                    subtitle="Haz click en los filtros y realiza una búsqueda diferente."
                  ></Feedback>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );
}
