import {
  Box,
  Button,
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import React, { useCallback, useState, useEffect } from 'react';
import api from '../../../services/api';
import { KpisStatusDetail } from '../../../types/entities/kpis-status';
import KpisStatusDetailsTable from './KpisStatusDetailsTable';
import KpisStatusDetailsUser from './KpisStatusDetailsUser';

export interface LoadedKpi {
  month: number;
  year: number;
  id_country: number;
  id_brand: number;
  name_country: string;
  name_brand: string;
  created_at: string;
  kpis_loaded: number;
  kpis_should_be_loaded: number;
}

export interface KpisStatusRowProps {
  row: LoadedKpi;
  hasDetails: boolean;
}

const StyledTableCell = withStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        '&:first-child': {
          paddingLeft: 22,
        },
      },
      body: {
        borderBottom: 'none',
      },
      footer: {
        borderBottom: 'none',
      },
    }),
  {
    name: 'StyledTableCell',
  },
)(TableCell);

async function loadDetails(params: {
  id_country: number;
  id_brand: number;
  year: number;
  month: number;
}) {
  return await api
    .listKpisStatusDetails(params)
    .then((response) => response.data);
}

export default function KpisStatusRow({ row, hasDetails }: KpisStatusRowProps) {
  const headersCount = hasDetails ? 6 : 5;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<KpisStatusDetail[] | undefined>();
  useEffect(() => {
    if (open) {
      setLoading(true);
      loadDetails(row)
        .then(setDetails)
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [row, open]);
  const status =
    row.kpis_loaded >= row.kpis_should_be_loaded
      ? 'Carga de KPI completa'
      : row.kpis_loaded === 0
      ? 'Sin carga'
      : 'Carga de KPI parcial';
  const color =
    row.kpis_loaded >= row.kpis_should_be_loaded
      ? 'success'
      : row.kpis_loaded === 0
      ? 'error'
      : 'warning';

  type DetailParams = {
    id_country: number;
    id_brand: number;
    year: number;
    month: number;
    id_user: number;
    expectedRows?: number;
  };
  const [detailParams, setDetailParams] = useState<DetailParams | undefined>();
  const showDetailsUser = useCallback(
    (id_user: number, expectedRows?: number) => {
      setDetailParams({ ...row, id_user, expectedRows });
    },
    [row],
  );
  return (
    <>
      <TableRow>
        <StyledTableCell>
          <Box color={`${color}.main`}>
            <Tooltip title={status}>
              <FiberManualRecordRoundedIcon color="inherit" />
            </Tooltip>
          </Box>
        </StyledTableCell>
        <StyledTableCell>{row.name_brand}</StyledTableCell>
        <StyledTableCell style={{ textTransform: 'uppercase' }}>
          {format(new Date(row.year, row.month - 1, 1), 'MMMM yyyy', {
            locale: es,
          })}
        </StyledTableCell>
        <StyledTableCell>
          <Box color={`${color}.main`} whiteSpace="nowrap">
            {`${row.kpis_loaded}/${row.kpis_should_be_loaded} Cargados`}
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          {row.created_at
            ? format(new Date(row.created_at), 'dd/MM/yyyy')
            : null}
        </StyledTableCell>
        {hasDetails && (
          <StyledTableCell align="right">
            <Button
              variant={open ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setOpen(!open)}
              size="small"
              style={{ textTransform: 'none' }}
              endIcon={
                open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {open ? 'Ocultar Detalles' : 'Ver Detalles'}
            </Button>
          </StyledTableCell>
        )}
      </TableRow>
      {hasDetails && open && (
        <>
          {!loading ? (
            <KpisStatusDetailsTable
              details={details}
              onShowDetailsUser={showDetailsUser}
            />
          ) : (
            <>
              <TableRow>
                <TableCell variant="head" size="small" colSpan={headersCount}>
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  align="center"
                  size="small"
                  colSpan={headersCount}
                >
                  <Skeleton />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  align="center"
                  size="small"
                  colSpan={headersCount}
                >
                  <Skeleton />
                </StyledTableCell>
              </TableRow>
            </>
          )}
          {detailParams && (
            <KpisStatusDetailsUser
              onClose={() => setDetailParams(undefined)}
              {...detailParams}
            />
          )}
        </>
      )}
    </>
  );
}
