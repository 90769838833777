import React from 'react';
import PropTypes from 'prop-types';
import { AlertIcon } from '../icons/AlertIcon';
import { ErrorCircleIcon } from '../icons/ErrorCircleIcon';
import { RightCircleIcon } from '../icons/RightCircleIcon';
import { Box } from '@material-ui/core';

interface StatusIndicatorProps {
  status: 'success' | 'warning' | 'error';
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  if (!status) {
    return null;
  }

  const componentMapping: Map<
    'success' | 'warning' | 'error',
    React.ReactElement
  > = new Map([
    [
      'success',
      <Box color={`${status}.main`}>
        <RightCircleIcon color="inherit" />
      </Box>,
    ],
    [
      'warning',
      <Box color={`${status}.main`}>
        <AlertIcon color="inherit" />
      </Box>,
    ],
    [
      'error',
      <Box color={`${status}.main`}>
        <ErrorCircleIcon color="inherit" />
      </Box>,
    ],
  ]);

  return componentMapping.has(status)
    ? (componentMapping.get(status) as React.ReactElement)
    : null;
};

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
};

export { StatusIndicator };
