import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LoadingButton, { LoadingButtonProps } from "./LoadingButton";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#313B72",
        color: "white",

        "&:hover": {
            backgroundColor: "#22294e",
        },
    },
    loading: {
        color: "white",
        width: "20px !important",
        height: "20px !important",
    },
});

const AlternativeButton: React.FunctionComponent<LoadingButtonProps> = (
    props: LoadingButtonProps
) => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <LoadingButton
            color="primary"
            variant="contained"
            className={clsx(classes.root, props.className)}
            loadingClass={classes.loading}
            {...rest}
        ></LoadingButton>
    );
};

export default AlternativeButton;
