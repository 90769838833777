import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import AlternativeButton from '../../components/buttons/AlternativeButton';
import { listCountriesAction } from '../../store/actions/kpis/countries';
import { GenerateTemplateModal } from './GenerateTemplateModal';
import { LoadKpiModal } from './LoadKpiModal';
import KpisStatus from './components/KpisStatus';
import { RolesEnum } from '../../types/enums/roles';
import { ManualLoadKPIs } from './components/ManualLoad/ManualLoadKPIs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 24,
    [theme.breakpoints.up('xl')]: {
      paddingTop: 28,
    },
    height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
    display: 'flex',
  },
  actionButtons: {
    flex: 1,
    '&  button:not(:last-child)': {
      marginRight: theme.spacing(),
    },
  },
  manualLoadBtn: {
    backgroundColor: '#001853',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#00103a',
    },
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  content: {
    minHeight: 0,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(9),
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [generateModal, setGenerateModal] = useState(false);
  const [loadKpiModal, setLoadKpiModal] = useState(false);
  const [manualLoadModal, setManualLoadModal] = useState(false);
  const dispatch = useDispatch();
  const { countries, roles } = useSelector(
    (store: any) => ({
      countries: store.kpis.country.data,
      roles: store.auth.profile.roles,
    }),
    shallowEqual,
  );

  const isAdmin = useMemo(() => {
    return roles.some((role: number) =>
      [RolesEnum.ADMIN_FCA, RolesEnum.ADMIN_DYU].includes(role),
    );
  }, [roles]);

  useEffect(() => {
    // request countries
    dispatch(listCountriesAction());
  }, [dispatch]);

  const openGenerateModal = useCallback(() => {
    setGenerateModal(true);
  }, []);

  const closeGenerateModal = useCallback(() => {
    setGenerateModal(false);
  }, []);

  const openLoadKpiModal = useCallback(() => {
    setLoadKpiModal(true);
  }, []);

  const closeLoadKpiModal = useCallback(() => {
    setLoadKpiModal(false);
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box display="flex" flexDirection="column" flex={1} width="100%">
        <Box className={classes.titleContainer}>
          <Grid container item alignItems="flex-end">
            <Grid item>
              <Typography variant="h1">Carga de KPI's</Typography>
            </Grid>
            {/* Action buttons */}
            <Grid item xs container spacing={1} justify="flex-end">
              <Grid item>
                <AlternativeButton
                  id="btn-kpi-template"
                  onClick={openGenerateModal}
                >
                  Generar excel
                </AlternativeButton>
              </Grid>
              {isAdmin && (
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.manualLoadBtn}
                    onClick={() => setManualLoadModal(true)}
                  >
                    Carga Manual
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openLoadKpiModal}
                >
                  Cargar Masiva
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.content}>
          <Paper style={{ maxHeight: '100%', display: 'flex' }}>
            <Box paddingX={3} paddingY={2} flex={1} width="100%">
              <Box maxHeight="100%" overflow="auto" maxWidth="100%">
                <KpisStatus hasDetails={isAdmin} />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      {/* download xlsx template */}
      <GenerateTemplateModal
        open={generateModal}
        countries={countries}
        onClose={closeGenerateModal}
        onCancel={closeGenerateModal}
      />
      <LoadKpiModal
        open={loadKpiModal}
        countries={countries}
        onCancel={closeLoadKpiModal}
        onClose={closeLoadKpiModal}
      />

      {manualLoadModal && (
        <ManualLoadKPIs
          countries={countries}
          onClose={() => setManualLoadModal(false)}
        />
      )}
    </Container>
  );
};
