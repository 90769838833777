import React from 'react';
import { Button, DialogActions, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    flex: 1,
  },
});

export interface ConfirmationDialogActionsProps {
  children: React.ReactNode;
  onClose: (value: boolean) => void;
}

function ConfirmationDialogActions({
  onClose,
  children,
}: ConfirmationDialogActionsProps) {
  const classes = useStyles();

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <>
      {children}
      <DialogActions className="big-top-space">
        <Button
          id="btn-confirm-dialog-cancel"
          color="secondary"
          variant="contained"
          onClick={handleCancel}
          className={classes.button}
        >
          Cancelar
        </Button>
        <Button
          id="btn-load-kpi-preview-ok"
          color="primary"
          variant="contained"
          onClick={handleOk}
          className={classes.button}
        >
          Confirmar
        </Button>
      </DialogActions>
    </>
  );
}

export default ConfirmationDialogActions;
