import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ListLightIcon } from '../../components/icons/ListLightIcon';
import { useSelector } from 'react-redux';
import { SuccessFeedback } from '../../components/feedback/SuccessFeedback';
import { FailFeedback } from '../../components/feedback/FailFeedback';
import { HorizontalGrid } from '../../components/HorizontalGrid';
import { useModalTimeout } from '../../hooks/useModalTimeout';
import api from '../../services/api';
import { useBrandSelector } from '../../hooks/useBrandSelector';
import { FormDialog } from '../../components/FormDialog';
import { Country } from '../../types/entities/country';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiGrid-item:last-child': {
      paddingTop: theme.spacing(4),
    },
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
  },
  button: {
    flex: 1,
  },
}));

interface KpiModalProps {
  open: boolean;
  countries: Country[];
  onClose: () => void;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
async function downloadExcel(id: number, brand: number, country: number) {
  const response = await api.getKpiTemplate(id, brand, country);
  const filename = response.headers['content-disposition'].match(
    /filename="(.+)"/,
  )[1];
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(response.data, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(response.data)
        : window.webkitURL.createObjectURL(response.data);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 0);
  }
}

export const GenerateTemplateModal = ({
  open,
  countries,
  onCancel,
  onClose,
}: KpiModalProps) => {
  const classes = useStyles();
  const [country, setCountry] = useState<string>('');
  const [onSuccess, setSuccessNotification] = useState(false);
  const [onFail, setFailNotification] = useState(false);
  const { id } = useSelector((state: any) => state.auth.profile);
  const [
    brands,
    brand,
    setBrand,
    disableBrands,
    loadingBrands,
  ] = useBrandSelector(country);

  const startTimeout = useModalTimeout(open, onClose);

  const generateExcel = useCallback(async () => {
    try {
      await downloadExcel(id, parseInt(brand, 10), parseInt(country, 10));
      setSuccessNotification(true);
    } catch (error) {
      setFailNotification(true);
    } finally {
      startTimeout(5000);
    }
  }, [id, brand, country, startTimeout]);

  const onBrandChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setBrand(e.target.value as string);
  };

  const onCountryChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(e.target.value as string);
  };

  const onModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    return function cleanUp() {
      if (!open) {
        setCountry('');
        setBrand('');
        setFailNotification(false);
        setSuccessNotification(false);
      }
    };
  }, [open, setBrand]);

  return (
    <FormDialog
      onClose={onModalClose}
      aria-labelledby="generate-template"
      open={open}
      onSubmit={generateExcel}
    >
      {!(onSuccess || onFail) && (
        <>
          <DialogTitle disableTypography>
            <ListLightIcon color="primary" />
            Generar Excel
          </DialogTitle>
          <DialogContent>
            <HorizontalGrid container spacing={3}>
              <HorizontalGrid item xs={12} md={6}>
                <FormControl className={classes.formControl} required>
                  <InputLabel htmlFor="select-country-kpi-template">
                    País
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      id: 'select-country-kpi-template',
                      name: 'country',
                    }}
                    value={country}
                    onChange={onCountryChange}
                  >
                    <option value="" aria-label="None" />
                    {countries.map((value) => (
                      <option
                        key={`kpi-country-${value.id_country}`}
                        value={value.id_country}
                      >
                        {value.name_country}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HorizontalGrid>
              <HorizontalGrid item xs={12} md={6}>
                <FormControl
                  className={classes.formControl}
                  disabled={disableBrands || loadingBrands}
                  required
                >
                  <InputLabel htmlFor="select-brand-kpi-template">
                    Marca
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      id: 'select-brand-kpi-template',
                      name: 'brand',
                    }}
                    value={brand}
                    onChange={onBrandChange}
                  >
                    <option value="" aria-label="None" />
                    {brands.map((value) => (
                      <option
                        key={`kpi-brand-${value.id_brand}`}
                        value={`${value.id_brand}`}
                      >
                        {value.name_brand}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HorizontalGrid>
            </HorizontalGrid>
          </DialogContent>
          <DialogActions className="big-top-space">
            <Button
              id="btn-kpi-template-cancel"
              color="secondary"
              variant="contained"
              onClick={onCancel}
              className={classes.button}
            >
              cancelar
            </Button>
            <Button
              id="btn-kpi-template-generate"
              color="primary"
              variant="contained"
              type="submit"
              disabled={!brand || !country}
              className={classes.button}
            >
              Generar Excel
            </Button>
          </DialogActions>
        </>
      )}
      {onSuccess && (
        <DialogContent>
          <SuccessFeedback
            title="¡Archivo excel generado con éxito!"
            subtitle="Revisa tus descargas"
          />
        </DialogContent>
      )}
      {onFail && (
        <DialogContent>
          <FailFeedback
            title="Ups, ha ocurrido un problema inesperado"
            subtitle="Por favor intentalo de nuevo"
            retry={true}
            onRetry={generateExcel}
          />
        </DialogContent>
      )}
    </FormDialog>
  );
};
