import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  ListKpisBrandsAction,
  LIST_KPIS_BRANDS,
  notifyListKpisBrandsSucceeded,
  notifyListKpisBrandsFailed,
} from '../actions/kpis/brands';
import {
  LIST_COUNTRIES,
  notifyListCountriesFailed,
  notifyListCountriesSucceeded,
} from '../actions/kpis/countries';
import api from '../../services/api';
import { ProfileState } from '../reducers/auth';
import { sessionExpired } from '../actions/auth/session';
import {
  notifyListKpisStatusSucceeded,
  notifyListKpisStatusFailed,
  LIST_KPIS_STATUS,
  ListKpisStatusAction,
} from '../actions/kpis/kpis-status';

export function* listCountriesSaga() {
  const { id }: ProfileState = yield select((state: any) => state.auth.profile);
  try {
    const { data } = yield call(api.listCountriesByUser, id!);
    yield put(notifyListCountriesSucceeded(data));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      notifyListCountriesFailed(e.response.status, e.response.data.message),
    );
  }
}

export function* listBrandsSaga({
  payload: { countryId },
}: ListKpisBrandsAction) {
  const { id }: ProfileState = yield select((state: any) => state.auth.profile);
  try {
    const { data } = yield call(api.listBrandsByUserAndCountry, id!, countryId);
    yield put(notifyListKpisBrandsSucceeded(data));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      notifyListKpisBrandsFailed(e.response.status, e.response.data.message),
    );
  }
}

export function* listKpisStatusSaga({ payload }: ListKpisStatusAction) {
  try {
    const { data } = yield call(api.listKpisStatus, payload);
    yield put(notifyListKpisStatusSucceeded(data));
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(sessionExpired());
    }
    yield put(
      e.response
        ? notifyListKpisStatusFailed(e.response.status, e.response.data.message)
        : notifyListKpisStatusFailed(0, 'Problema de conexión'),
    );
  }
}

export default function* authRootSaga() {
  yield takeLatest(LIST_COUNTRIES, listCountriesSaga);
  yield takeLatest(LIST_KPIS_BRANDS, listBrandsSaga);
  yield takeLatest(LIST_KPIS_STATUS, listKpisStatusSaga);
}
