import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { KPI_STATUS_DETAILS_USER_DEFAULT_ROWS } from '../../../services/constants';

export interface KpisStatusDetailsUserLoadingProps {
  expectedRows?: number;
}

const KpisStatusDetailsUserLoading = ({
  expectedRows = KPI_STATUS_DETAILS_USER_DEFAULT_ROWS,
}: KpisStatusDetailsUserLoadingProps) => {
  const rows = [...Array(expectedRows)].map((v, i) => i);
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((v) => (
          <TableRow key={v}>
            <TableCell>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(KpisStatusDetailsUserLoading);
