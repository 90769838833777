import { TableHead, TableRow, TableCell } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import api from '../../../services/api';
import UsersTableHeadCell, { Header } from './UsersTableHeadCell';
import { UsersListFiltersContext } from '../contexts/UsersListFiltersContext';
import { UsersListFilter } from '../../../types/entities/user';

export type HeaderWithKey = Header & {
  key?: keyof Omit<UsersListFilter, 'page'>;
};

export default function UsersTableHead() {
  const { filters, setCurrentFilters } = useContext(UsersListFiltersContext);

  const handleOnChange = useCallback(
    (value, key) => {
      setCurrentFilters({
        ...filters,
        [key]: value,
        page: 1,
      });
    },
    [setCurrentFilters, filters],
  );

  const headers: HeaderWithKey[] = [
    {
      key: 'active',
      label: 'Activo',
      type: 'radio',
      options: [
        { label: 'Activos', value: 'true' },
        { label: 'Inactivos', value: 'false' },
      ],
    },
    { key: 'name', label: 'Nombre', type: 'search' },
    {
      key: 'origin',
      label: 'Origen',
      type: 'checkbox',
      loader: async () => {
        const response = await api.listCountriesByUser();
        return response.data.map((d: any) => ({
          value: d.id_country,
          label: d.name_country,
        }));
      },
    },
    {
      key: 'category',
      label: 'Categoría',
      type: 'checkbox',
      loader: async () => {
        const response = await api.listCategories();
        return response.data.map((d: any) => ({
          value: d.id,
          label: d.name,
        }));
      },
    },
    { label: 'Rol' },
    {
      key: 'brand',
      label: 'Marca',
      type: 'checkbox',
      loader: async () => {
        const response = await api.listBrandsByUser();
        return response.data.map((d: any) => ({
          value: d.id_brand,
          label: d.name_brand,
        }));
      },
    },
    {
      key: 'country',
      label: 'Paises',
      type: 'checkbox',
      loader: async () => {
        const response = await api.listCountriesByUser();
        return response.data.map((d: any) => ({
          value: d.id_country,
          label: d.name_country,
        }));
      },
    },
    { label: 'KPI' },
    { label: '' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) =>
          header.key ? (
            <UsersTableHeadCell
              key={header.key}
              header={header}
              defaultValue={filters ? filters[header.key] : undefined}
              onChange={(value) => {
                handleOnChange(value, header.key);
              }}
            />
          ) : (
            <TableCell key={index}>{header.label}</TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}
