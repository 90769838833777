import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserModal from './UserModal';
import { useDispatch } from 'react-redux';
import { listCountriesAction } from '../../store/actions/countries';
import { listCategoriesAction } from '../../store/actions/categories';
import UsersList from './components/UsersList';
import { UsersListFiltersContext } from './contexts/UsersListFiltersContext';
import { useUsersListFilters } from './hooks/useUsersListFilters';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 24,
    [theme.breakpoints.up('xl')]: {
      paddingTop: 28,
    },
    height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
    display: 'flex',
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

const Users = () => {
  const classes = useStyles();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [enableEdition, setEnableEdition] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);
  const dispatch = useDispatch();

  const openModal = () => {
    setOpenUserModal(true);
  };

  const closeModal = () => {
    setOpenUserModal(false);
    setEnableEdition(false);
    setUserId(null);
    setEnableEdition(false);
  };

  useEffect(() => {
    dispatch(listCountriesAction());
    dispatch(listCategoriesAction());
  }, [dispatch]);

  const filters = useUsersListFilters();
  const onEditUser = (id: number) => {
    setUserId(id);
    setEnableEdition(true);
    openModal();
  };

  return (
    <Container className={classes.root} maxWidth="xl">
      <Box display="flex" flexDirection="column" flex={1} width="100%">
        <Box className={classes.titleContainer}>
          <Grid container item alignItems="flex-end">
            <Grid item>
              <Typography variant="h1">Usuarios</Typography>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Grid container justify="flex-end">
                <Button variant="contained" color="primary" onClick={openModal}>
                  + Nuevo usuario
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <UsersListFiltersContext.Provider value={filters}>
          <UsersList onEdit={onEditUser} />
          {openUserModal && (
            <UserModal
              open={openUserModal}
              onClose={closeModal}
              userId={userId}
              enableEdition={enableEdition}
            />
          )}
        </UsersListFiltersContext.Provider>
      </Box>
    </Container>
  );
};

export default Users;
