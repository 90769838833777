import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ListLightIcon } from '../icons/ListLightIcon';
import { MONTHS, YEARS } from '../../services/constants';
import { DropExcelBox } from '../DropExcelBox';
import { HorizontalGrid as Grid } from '../HorizontalGrid';
import { useBrandSelector } from '../../hooks/useBrandSelector';

export interface KpiExcel {
  id_kpi: string;
  name_kpi: string;
  value: string;
}

export interface LoadKpiInitData {
  brand: string;
  country: string;
  month: string;
  year: string;
  kpis: KpiExcel[];
}

export interface LoadKpiInitProps {
  initialData?: LoadKpiInitData;
  countries: any[];
  onSuccess: (data: LoadKpiInitData) => void;
  onCancel: () => void;
}

const parseExcel = (data: unknown[]): KpiExcel[] => {
  return data.map((value: any) => {
    return {
      id_kpi: String(value.id_kpi ?? ''),
      name_kpi: String(value.name_kpi ?? ''),
      value: String(value.value ?? ''),
    };
  });
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
  },
  button: {
    flex: 1,
  },
}));

export default function LoadKpiInit({
  initialData,
  countries,
  onSuccess,
  onCancel,
}: LoadKpiInitProps) {
  const classes = useStyles();
  const [country, setCountry] = useState<string>(initialData?.country ?? '');
  const [
    brands,
    brand,
    setBrand,
    disableBrands,
    loadingBrands,
  ] = useBrandSelector(country, initialData?.country ?? '');
  const [month, setMonth] = useState<string>(initialData?.month ?? '');
  const [year, setYear] = useState<string>(initialData?.year ?? '');
  const [fileData, setFileData] = useState<KpiExcel[]>(initialData?.kpis ?? []);
  return (
    <>
      <DialogTitle disableTypography>
        <ListLightIcon color="primary" />
        Cargar KPI's
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item xs={12} md={6}>
            <FormControl className={classes.formControl} required>
              <InputLabel htmlFor="select-country-load-kpi">País</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'select-country-load-kpi',
                  name: 'country',
                }}
                value={country}
                onChange={(e) => setCountry(e.target.value as string)}
              >
                <option value="" aria-label="None" />
                {countries.map((value) => (
                  <option
                    key={`kpi-country-${value.id_country}`}
                    value={value.id_country}
                  >
                    {value.name_country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              required
              disabled={disableBrands || loadingBrands}
            >
              <InputLabel htmlFor="select-brand-load-kpi">Marca</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'select-brand-load-kpi',
                  name: 'brand',
                }}
                value={brand}
                onChange={(e) => setBrand(e.target.value as string)}
              >
                <option value="" aria-label="None" />
                {brands.map((value) => (
                  <option
                    key={`kpi-brand-${value.id_brand}`}
                    value={`${value.id_brand}`}
                  >
                    {value.name_brand}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6}>
            <FormControl className={classes.formControl} required>
              <InputLabel htmlFor="input-month-load-kpi">Mes</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'input-month-load-kpi',
                  name: 'month',
                }}
                value={month}
                onChange={(e) => setMonth(e.target.value as string)}
              >
                <option value="" aria-label="None" />
                {MONTHS.map((value) => (
                  <option key={`kpi-month-${value.id}`} value={value.id}>
                    {value.value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6}>
            <FormControl className={classes.formControl} required>
              <InputLabel htmlFor="input-year-load-kpi">Año</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'input-year-load-kpi',
                  name: 'year',
                }}
                value={year}
                onChange={(e) => setYear(e.target.value as string)}
              >
                <option value="" aria-label="None" />
                {YEARS.map((value) => (
                  <option key={`kpi-year-${value.id}`} value={value.id}>
                    {value.value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <DropExcelBox
          message="Selecciona un archivo"
          onDrop={(data) => setFileData(parseExcel(data))}
          onRemove={() => {
            setFileData([]);
          }}
        />
      </DialogContent>
      <DialogActions className="big-top-space">
        <Button
          id="btn-load-kpi-cancel"
          color="secondary"
          variant="contained"
          onClick={() => onCancel()}
          className={classes.button}
        >
          cerrar
        </Button>
        <Button
          id="btn-load-kpi-next"
          color="primary"
          variant="contained"
          onClick={() =>
            onSuccess({
              brand,
              country,
              month,
              year,
              kpis: fileData,
            })
          }
          disabled={!brand || !country || !month || !year || !fileData.length}
          className={classes.button}
        >
          revisar
        </Button>
      </DialogActions>
    </>
  );
}
