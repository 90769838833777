import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Box,
  IconButton,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '../components/AppBar';
import { userSignout } from '../store/actions/auth/signout';
import Kpis from './Kpis/Kpis';
import { Dashboards } from './Dashboards/Dashboards';
import Users from './Users/Users';
import PrivateRoute from '../components/PrivateRoute';
import { HomeIcon } from '../components/icons/HomeIcon';
import { ListIcon } from '../components/icons/ListIcon';
import { UserIcon } from '../components/icons/UserIcon';
import { ProfileState } from '../store/reducers/auth';
import { RolesEnum } from '../types/enums/roles';
import { ScreensEnum } from '../types/enums/screens';
import { FailFeedback } from '../components/feedback/FailFeedback';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url('/assets/wave_background@2x.png')",
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'unset',
    backgroundPosition: 'bottom',
    backgroundSize: '100%',
    overflow: 'auto',
    height: '100vh',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    overflowX: 'hidden',
    backgroundColor: 'transparent',
    borderRight: 'none',
  },
  drawerPaperInner: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: 163,
    borderBottomRightRadius: 163,
    minHeight: '75vh',
  },
  lateralMenuIcon: {
    color: 'white',
    fontSize: 25,
    [theme.breakpoints.up('xl')]: {
      fontSize: 35,
    },
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 120,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      height: 200,
      paddingTop: theme.spacing(5),
    },
  },
  content: {
    position: 'relative',
    minHeight: '100vh',
  },
  list: {
    paddingLeft: theme.spacing(1),
  },
  navItem: {
    color: 'white',
    height: 35,
    marginBottom: 14,
    [theme.breakpoints.up('xl')]: {
      height: 56,
    },
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    '&.active': {
      borderLeft: 'none',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,

      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  listItemIcon: {
    color: 'white',
    marginRight: 10,
    minWidth: 'unset',
  },
  listItemSvg: {
    fontSize: 17,
    [theme.breakpoints.up('xl')]: {
      fontSize: 28,
    },
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}));

export default (props: any) => {
  const { window } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();
  const { name, roles }: ProfileState = useSelector((state: any) => {
    return state.auth.profile;
  }, shallowEqual);

  const onLogout = () => {
    dispatch(userSignout());
  };

  const canDisplay = useCallback(
    (screen: ScreensEnum) => {
      switch (screen) {
        case ScreensEnum.DASHBOARDS:
          let allowedRoles = new Set([
            RolesEnum.ADMIN_FCA,
            RolesEnum.ADMIN_DYU,
            RolesEnum.VIEWER,
          ]);
          return roles.some((role) => allowedRoles.has(role));
        case ScreensEnum.KPIS: {
          let allowedRoles = new Set([
            RolesEnum.ADMIN_FCA,
            RolesEnum.ADMIN_DYU,
            RolesEnum.EDITOR,
          ]);
          return roles.some((role) => allowedRoles.has(role));
        }
        case ScreensEnum.USERS: {
          let allowedRoles = new Set([
            RolesEnum.ADMIN_FCA,
            RolesEnum.ADMIN_DYU,
          ]);

          return roles.some((role) => allowedRoles.has(role));
        }
        default:
          return false;
      }
    },
    [roles],
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
  useEffect(() => {
    if (mobileOpen && matches) {
      setMobileOpen(false);
    }
  }, [matches, mobileOpen]);

  const closeOnMobile = useCallback(() => {
    if (!matches) {
      setMobileOpen(false);
    }
  }, [matches]);

  const [showLabels, setShowLabels] = useState(true);
  const handleDrawerToggle = () => {
    setShowLabels(true);
    setMobileOpen(!mobileOpen);
  };
  const onLateralMenuClick = useCallback(() => {
    if (matches) {
      setShowLabels(!showLabels);
    } else {
      setMobileOpen(!mobileOpen);
    }
  }, [matches, showLabels, mobileOpen]);
  let drawerWidth = showLabels ? 160 : 100;
  if (matchesXl) {
    drawerWidth = showLabels ? 230 : 132;
  }
  const leftSideStyle = useMemo(() => {
    return matches
      ? {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        }
      : undefined;
  }, [matches, drawerWidth]);
  const appBarStyle = {
    ...(leftSideStyle ?? {}),
    boxShadow: '0px 2px 10px #0000001A',
  };
  const centerIfNoLabelsStyle = useMemo(
    () => (!showLabels ? { margin: 'auto' } : undefined),
    [showLabels],
  );
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        userName={name as string}
        onLogout={onLogout}
        onMenuClick={handleDrawerToggle}
        elevation={0}
        style={appBarStyle}
      />
      <nav aria-label="sidenav">
        <Drawer
          title="lateral menu"
          container={matches ? undefined : container}
          variant={matches ? 'permanent' : 'temporary'}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{
            elevation: 0,
            style: {
              width: drawerWidth,
            },
          }}
          ModalProps={
            matches
              ? undefined
              : {
                  keepMounted: true, // Better open performance on mobile.
                }
          }
        >
          <Box className={classes.drawerPaperInner}>
            <Box display="flex">
              <IconButton
                onClick={onLateralMenuClick}
                style={centerIfNoLabelsStyle}
              >
                <MenuIcon className={classes.lateralMenuIcon} />
              </IconButton>
            </Box>
            <Box className={classes.imageBox}>
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                alt="Logo Stellantis"
                src="/assets/logo_white.svg"
              />
            </Box>
            <List className={classes.list}>
              {canDisplay(ScreensEnum.DASHBOARDS) && (
                <ListItem
                  button
                  className={classes.navItem}
                  component={NavLink}
                  id="nav-link-dashboards"
                  to={'/dashboards'}
                  activeClassName="active"
                  onClick={closeOnMobile}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={centerIfNoLabelsStyle}
                  >
                    <HomeIcon className={classes.listItemSvg} />
                  </ListItemIcon>
                  {showLabels && <ListItemText primary={'Dashboard'} />}
                </ListItem>
              )}
              {canDisplay(ScreensEnum.KPIS) && (
                <ListItem
                  button
                  className={classes.navItem}
                  component={NavLink}
                  id="nav-link-kpis"
                  to={'/kpis'}
                  activeClassName="active"
                  onClick={closeOnMobile}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={centerIfNoLabelsStyle}
                  >
                    <ListIcon className={classes.listItemSvg} />
                  </ListItemIcon>
                  {showLabels && <ListItemText primary={`Carga de KPI's`} />}
                </ListItem>
              )}
              {canDisplay(ScreensEnum.USERS) && (
                <ListItem
                  button
                  className={classes.navItem}
                  component={NavLink}
                  id="nav-link-kpis"
                  to={'/users'}
                  activeClassName="active"
                  onClick={closeOnMobile}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={centerIfNoLabelsStyle}
                  >
                    <UserIcon className={classes.listItemSvg} />
                  </ListItemIcon>
                  {showLabels && <ListItemText primary={`Usuarios`} />}
                </ListItem>
              )}
            </List>
          </Box>
        </Drawer>
      </nav>
      <main className={classes.content} style={leftSideStyle}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path={'/'}>
            <Redirect to="/dashboards" />
          </Route>
          <PrivateRoute
            path={'/kpis'}
            redirectTo="/403.html"
            checkRoles
            allowedRoles={[
              RolesEnum.ADMIN_FCA,
              RolesEnum.ADMIN_DYU,
              RolesEnum.EDITOR,
            ]}
          >
            <Kpis />
          </PrivateRoute>
          <PrivateRoute
            path={'/dashboards'}
            redirectTo="/kpis"
            checkRoles
            allowedRoles={[
              RolesEnum.ADMIN_DYU,
              RolesEnum.ADMIN_FCA,
              RolesEnum.VIEWER,
            ]}
          >
            <Dashboards />
          </PrivateRoute>
          <PrivateRoute
            path={'/users'}
            redirectTo="/403.html"
            checkRoles
            allowedRoles={[RolesEnum.ADMIN_DYU, RolesEnum.ADMIN_FCA]}
          >
            <Users />
          </PrivateRoute>
          <Route exact path="/403.html">
            <div className={classes.centerContainer}>
              <div>
                <FailFeedback
                  title="No tienes permisos"
                  subtitle="contacta a tu administrador para que te asigne los permisos necesarios"
                />
              </div>
            </div>
          </Route>
        </Switch>
      </main>
    </div>
  );
};
